<template>
	<ConfirmDialog></ConfirmDialog>
	<div v-if="loaded" class="flex flex-col w-full">
		<div class="flex flex-col self-center items-center rounded-lg w-full">
			<div class="bg-white px-6 w-full">
				<div class="mb-4">
					<label for="email" class="block mb-2 font-medium">Email:</label>
					<input type="email" id="email" v-model="email"
						class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
						placeholder="Enter user's email" />
				</div>
				<div class="mb-4">
					<label for="credits" class="block mb-2 font-medium">Credits:</label>
					<select id="credits" v-model="selectedCredits"
						class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500">
						<option value="1">1 Credit</option>
						<option value="3">3 Credits</option>
						<option value="5">5 Credits</option>
						<option value="10">10 Credits</option>
						<option value="30">30 Credits</option>
						<option value="50">50 Credits</option>
						<option value="100">100 Credits</option>
					</select>
				</div>
				<div class="mt-8 flex justify-center">
					<MainButton rounded @click="grantCredits">
						Grant Credits
					</MainButton>
				</div>

				<div v-if="emailExists === false" class="mt-4 text-red-500">
					Email does not exist.
				</div>
				<div v-else class="mt-4 text-green-500">
					Email exist. {{ currentCredits }} credits remaining.
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import api from "@/api/api";
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';
import { defineComponent } from "vue";

export default defineComponent({
	name: 'GrantCredits',
	data() {
		return {
			email: '',
			selectedCredits: '1',
			emailExists: false,
			currentCredits: 0,
			loaded: false,
		};
	},
	components: {
		ConfirmDialog,
	},
	watch: {
		email: async function (newEmail) {
			console.log(`Email changed to ${newEmail}`);

			const credits = await api.getUserCredits(newEmail).then((res) => res.data);
			console.log('credits', credits)
			if (credits.success === true) {
				this.emailExists = true;
				this.currentCredits = credits.credits;
			} else {
				this.emailExists = false;
				this.currentCredits = 0;
			}
		},
		selectedCredits: function (newCredits) {
			console.log(`Selected credits changed to ${newCredits}`);
		},
	},
	setup() {
		const confirm = useConfirm();

		return { confirm };
	},
	async mounted() {
		// Perform any necessary data loading or initialization
		this.loaded = true;
	},
	methods: {
		async grantCredits() {
			const credits = parseInt(this.selectedCredits);
			console.log(`Granting ${credits} credits to ${this.email}`);
			api.grantCredits(this.email, parseInt(this.selectedCredits));
		},
	},
});
</script>

<style scoped></style>
