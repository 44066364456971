<template>
    <div class="pp-container">
        <h1 class="header-text">{{ $t('tos_pp.privacy_policy') }}</h1>
        <iframe src="/PRIVACY_POLICY_20240214.html" class="pp-iframe"></iframe>
    </div>
</template>

<script>
import api from '@/api/api';
import { marked } from "marked";

export default {
    name: 'PrivacyPolicy',
    data() {
        return {
            text: ''
        }
    },
    methods: {
        async getPrivacyPolicy() {
            try {
                await api.getPrivacyPolicy()
                    .then(response => {
                        this.text = response.data.text;
                    });
            } catch (error) {
                console.error(error);
            }
        },
        markdownToHtml(body) {
            return marked.parse(body);
        },
    },
    mounted() {
        this.getPrivacyPolicy();
    }
}
</script>

<style scoped>
.pp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    height: 100%;
}

.pp-iframe {
    flex-grow: 1;
    width: 100%;
    max-width: 800px;
}
</style>
