<template>
	<div class="page-container" @touchstart="touchStart" @touchmove="touchMove" @touchend="touchEnd"
		@mousedown="touchStart" @mousemove="touchMove" @mouseup="touchEnd" @click="_portraitContainerClick($event)">
		<div v-for="(page, index) in nonEmptyPages" :key="index" class="page" :style="getSwipeStyle(index)">
			<StoryPage3dBookSide :side="page.front" :isCurrentUserStoryOwner="isCurrentUserStoryOwner"
				:choiceClicked="_choiceClicked" :handleRightClick="handleRightClick" :handleLeftClick="handleLeftClick"
				:onImageChangeClick="_onImageChangeClick" />
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import StoryPage3dBookSide from './StoryPage3dBookSide.vue';
import { useConfirm } from 'primevue/useconfirm';
import { useRouter } from 'vue-router';

export default defineComponent({
	name: 'BookPortrait',
	components: {
		StoryPage3dBookSide
	},
	props: {
		currentPage: {
			type: Number,
			default: -1,
		},
		choiceClicked: {
			type: Function,
			default: () => { },
		},
		isCurrentUserStoryOwner: {
			type: Boolean,
			default: false,
		},
		pages: {
			type: Array as () => any[],
			required: true
		},
		handleLeftClick: {
			type: Function,
			default: () => { },
		},
		handleRightClick: {
			type: Function,
			default: () => { },
		},
		shareClicked: {
			type: Function,
			default: () => { },
		},
		onImageChangeClick: {
			type: Function,
			default: () => { },
		},
	},
	watch: {
		pages() {
			this.updatePages();
		}
	},
	setup() {
		const confirm = useConfirm();
		const router = useRouter();

		const onCreateClicked = () => {
			confirm.require({
				header: 'New story',
				message: 'Are you sure you want to create a new wonder story?',
				acceptLabel: 'Create Story',
				acceptClass: 'p-button-rounded',
				rejectLabel: 'Cancel',
				accept: () => {
					router.push({ name: 'StoryTypePicker' })
				}
			});
		}

		return { onCreateClicked };
	},
	data() {
		return {
			nonEmptyPages: [] as any[],
			isSwiping: false,
			touchStartX: 0,
			touchStartY: 0,
			swipeDistance: 0,
			pageRotationCache: {} as any,
			preventScrolling: false
		}
	},
	mounted() {
		this.updatePages();

		//add mouse-up event listener to window
		window.addEventListener('mouseup', this.touchEnd);
	},
	computed: {
		swipedIndex(): number {
			return this.nonEmptyPages.length - this.currentPage - 1;
		}
	},
	methods: {
		_onImageChangeClick() {
			this.onImageChangeClick();
		},
		_portraitContainerClick(event: any) {
			const share = document.getElementById('outro-share-button')
			if (share && this.currentPage === this.pages.length - 2) {
				const rect = share.getBoundingClientRect();

				if (event.clientX >= rect.left && event.clientX <= rect.right &&
					event.clientY >= rect.top && event.clientY <= rect.bottom) {
					return this.shareClicked();
				}
			}

			const newStory = document.getElementById('outro-new-story-button')
			if (newStory && this.currentPage === this.pages.length - 2) {
				const rect = newStory.getBoundingClientRect();

				if (event.clientX >= rect.left && event.clientX <= rect.right &&
					event.clientY >= rect.top && event.clientY <= rect.bottom) {
					return this.onCreateClicked();
				}
			}

			let xIndex = event.clientX;
			const width = window.innerWidth;
			if (xIndex < width / 2) {
				this.handleLeftClick();
			} else {
				this.handleRightClick();
			}
		},
		_choiceClicked(choiceId: any, choiceText: any) {
			console.log('choice clicked', choiceId, choiceText);
			this.choiceClicked(choiceId, choiceText);
		},
		getPageRotation(index: number) {
			if (this.pageRotationCache[index]) {
				return this.pageRotationCache[index]
			} else {
				//rotation between -1.75 and 1.75
				const maxRotation = 1;
				const randomRotation = Math.random() * (maxRotation - (-maxRotation)) + (-maxRotation)
				this.pageRotationCache[index] = randomRotation
				return randomRotation
			}
		},
		getSwipeStyle(index: number) {
			if (this.isSwiping) {
				if (this.swipeDistance > 0 && index === this.swipedIndex + 1) {
					return {
						transform: `translateX(calc(-102% + ${this.swipeDistance}px))`,
						transition: 'none'
					}
				} else if (this.swipeDistance < 0 && index === this.swipedIndex) {
					return {
						transform: `translateX(${this.swipeDistance}px)`,
						transition: 'none'
					}
				}
			}

			if (index > this.swipedIndex) {
				return {
					transform: 'translateX(-102%)',
					transition: 'transform 0.3s'
				}
			} else {
				const randomRotation = this.getPageRotation(index)
				return {
					transform: 'translateX(0) rotate(' + randomRotation + 'deg)',
					transition: 'transform 0.3s'
				}
			}
		},
		updatePages() {
			this.nonEmptyPages = this.pages.filter((page) => page.front.pageType !== 'empty').reverse()
		},
		touchStart(event: TouchEvent | MouseEvent) {
			this.touchStartX = (event instanceof TouchEvent) ? event.touches[0].clientX : event.clientX;
			this.touchStartY = (event instanceof TouchEvent) ? event.touches[0].clientY : event.clientY;
			this.swipeDistance = 0;
			this.isSwiping = true;
		},
		touchMove(event: TouchEvent | MouseEvent) {
			const touchEndX = (event instanceof TouchEvent) ? event.touches[0].clientX : event.clientX;
			const touchEndY = (event instanceof TouchEvent) ? event.touches[0].clientY : event.clientY;
			const diffX = touchEndX - this.touchStartX;
			const diffY = touchEndY - this.touchStartY;
			if (Math.abs(diffX) < Math.abs(diffY)) {
				this.swipeDistance = 0;
			} else {
				this.swipeDistance = diffX;
			}

			if (Math.abs(diffX) > Math.abs(diffY)) {
				document.body.style.overflowY = 'hidden';
			} else {
				document.body.style.overflowY = 'auto';
			}
		},
		touchEnd(event: TouchEvent | MouseEvent) {
			if (!this.isSwiping)
				return;
			document.body.style.overflowY = 'auto';
			this.isSwiping = false
			this.swipeDistance = 0
			const touchEndX = (event instanceof TouchEvent) ? event.changedTouches[0].clientX : event.clientX;
			const touchEndY = (event instanceof TouchEvent) ? event.changedTouches[0].clientY : event.clientY;
			const diffX = touchEndX - this.touchStartX;
			const diffY = touchEndY - this.touchStartY;
			if (Math.abs(diffX) < Math.abs(diffY)) {
				return;
			}
			if (diffX < -50) {
				console.log('swiped left')
				this.handleRightClick();
			} else if (diffX > 50) {
				console.log('swiped right')
				this.handleLeftClick();
			}
			console.log('swipeIndex', this.swipedIndex)
			console.log('touch end', event)
		}
	}

})
</script>

<style scoped>
.page-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 20px;
	overflow: hidden;
}

.page {
	justify-content: center;
	position: absolute;
	background: white;
	margin-top: 20px;
	width: calc(100% - 40px);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	min-height: calc(100% - 40px);
	border-radius: 25px;
	top: 0;
	display: flex;
	flex-direction: column;
	padding: 20px;
	transition: transform 0.3s;
	user-select: none;
}


.img-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.page img {
	object-fit: contain;
	max-height: 40vh;
	border-radius: 25px;
	pointer-events: none;
}

.page p {
	font-size: 20px;
	line-height: 1.6;
}
</style>