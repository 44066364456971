<template>
  <div class="story-loading">

    <div v-if="didLoad">
      <div class="confetti">
        <Vue3Lottie :animationData="ConfettiJSON" :scale="1" :loop="false" />
      </div>
      <h1 class="message-text">Your story is ready</h1>
      <h1 class="message-text">The magic starts now!</h1>
    </div>
    <div v-if="!didLoad">
      <!-- <h1 class="message-text">Creating your story...</h1> -->
      <p class="message-steps">{{ message }}</p>
      <div class="animation-parent-container">
        <div class="animation-container">
          <Vue3Lottie :animationData="ThemesJSON" />
        </div>
      </div>
      <div class="message-subtext">{{ $t('please_wait') }}</div>
    </div>
  </div>
</template>

<style>
.animation-container {
  width: 50%;
  height: 50%;
  left: 0;
  right: 0;
}

.animation-parent-container {
  width: 100%;
  height: 40%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.confetti {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}

.message-text {
  font-size: xx-large;
  font-weight: 200;
  text-align: center;
  white-space: pre-line;
  line-height: 60px;
}

.message-subtext {
  font-size: x-large;
  font-weight: 200;
  text-align: center;
  white-space: pre-line;
  margin-bottom: 40px;
}

.message-steps {
  font-size: x-large;
  font-weight: 200;
  text-align: center;
  white-space: pre-line;
  line-height: 40px;
}

.story-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding-top: 100px;
}

.progress-bar {
  width: 50%;
  align-content: center;
}
</style>

<script>

import { Vue3Lottie } from 'vue3-lottie'
import ThemesJSON from '../assets/themes.json'
import ConfettiJSON from '../assets/confetti.json'

export default {
  components: {
    Vue3Lottie
  },
  data: function () {
    return {
      message: "",
      ConfettiJSON,
      ThemesJSON
    };
  },
  props: {
    didLoad: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    messages() {
      return [
        this.$t('dreaming_up_story'),
        this.$t('tucking_in_characters'),
        this.$t('sprinkling_fairy_dust'),
        this.$t('checking_under_bed'),
        this.$t('fluffing_clouds'),
        this.$t('gathering_moonbeams'),
        this.$t('teaching_dragons'),
        this.$t('whispering_owls'),
        this.$t('mixing_potion'),
        this.$t('setting_stage'),
        this.$t('convincing_characters'),
        this.$t('dusting_magic_carpets'),
        this.$t('asking_night_sky'),
        this.$t('negotiating_sandman'),
        this.$t('soothing_goblins'),
        this.$t('arranging_pillows'),
        this.$t('lulling_dragons'),
        this.$t('rearranging_stars'),
        this.$t('whispering_moon'),
        this.$t('preparing_cocoa'),
        this.$t('crafting_dreams'),
        this.$t('night_breeze_pages'),
        this.$t('training_fireflies'),
        this.$t('instructing_fairies'),
        this.$t('polishing_slippers'),
        this.$t('perfecting_pillow_fort'),
        this.$t('sending_invitations'),
        this.$t('warming_crickets'),
        this.$t('calibrating_dream_catcher'),
        this.$t('teddy_bear_roll_call'),
        this.$t('aligning_stars'),
        this.$t('baking_gingerbread'),
        this.$t('bedtime_prayer'),
        this.$t('setting_lullaby_mood'),
        this.$t('choreographing_fireflies')
      ]
    }
  },

  methods: {
    updateMessage: function () {
      this.message = this.messages[Math.floor(Math.random() * this.messages.length)];
    },
  },

  mounted: function () {
    this.updateMessage();
    setInterval(this.updateMessage, 10000);
  }
};
</script>