<template>
    <div class="flex flex-col items-center px-10 mt-4 main-column">
        <div class="max-w-screen-xl w-full font-medium pt-20 text-3xl">{{ $t('nav_bar.popular_stories') }}
        </div>
        <div v-if="totalRecords != 0" class="max-w-screen-xl w-full font-thin pt-1 text-lg">Stories: {{ totalRecords }}
        </div>

        <h2 class="pl-10 pt-15 header-padding" v-if="isLoading">{{ $t('loading') }}</h2>
        <div class="flex flex-row justify-center flex-wrap my-10 gap-8 max-w-screen-xl grid-container">
            <StoryCard v-for="story in stories" :key="story.id" :story="story" />
        </div>
    </div>
    <Paginator :rows="pageLimit" :totalRecords="totalRecords" :page-link-size="pageLimit"
        @page="onPageChange($event)" />
    <div class="h-20 spacing"></div>
    <MainFooter />
</template>

<script>
import BookCardTilt from "./StoryCardTilt.vue";
import api from "@/api/api.ts";
import MainFooter from '@/components/Footer';
import Paginator from 'primevue/paginator';

export default {
    name: "AllStories",
    components: {
        StoryCard: BookCardTilt,
        MainFooter,
        Paginator
    },
    data() {
        return {
            stories: [],
            isLoading: false,
            currentPage: 0,
            pageLimit: 12,
            totalRecords: 0
        };
    },
    watch: {
        currentPage: function (newPage) {
            this.$router.push({ query: { page: newPage + 1 } });
        }
    },
    mounted() {
        this.currentPage = this.$route.query.page ? parseInt(this.$route.query.page) - 1 : 0;
        this.loadStories();
    },
    methods: {
        loadStories() {
            this.isLoading = true;
            this.stories = [];
            window.scrollTo({ top: 0, behavior: 'smooth' });
            api.getAllStories(this.currentPage, this.pageLimit)
                .then((response) => {
                    this.stories = response.data.stories;
                    this.totalRecords = response.data.totalRecords;
                })
                .catch((error) => {
                    console.log(error)
                }).finally(() => {
                    this.isLoading = false;
                });
        },
        onPageChange(event) {
            this.currentPage = event.page;
            this.loadStories();
            this.$router.push({ query: { page: this.currentPage + 1 } });
        }
    },
};
</script>