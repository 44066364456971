<template>
    <div class="h-full w-full cursor-pointer"
        :style="{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }">
        <div v-if="isCurrentUserStoryOwner" class="cursor-pointer inline-block m-4" @click="onImageChangeClick($event)">
            <MainButton rounded class="" size="small" severity="contrast" icon="pi pi-refresh" label="Change Image"
                @click="onImageChangeClick($event)" />
        </div>
    </div>
</template>

<script>
export default {
    name: "StoryImagePage",
    props: {
        imageUrl: {
            type: String,
            default: null
        },
        isCurrentUserStoryOwner: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onImageChangeClick(event) {
            this.$emit('onImageChangeClick');
            event.stopPropagation();
        },
    }
}
</script>
