import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StoryPage3dBookSide = _resolveComponent("StoryPage3dBookSide")!

  return (_openBlock(), _createElementBlock("div", {
    class: "page-container",
    onTouchstart: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.touchStart && _ctx.touchStart(...args))),
    onTouchmove: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.touchMove && _ctx.touchMove(...args))),
    onTouchend: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.touchEnd && _ctx.touchEnd(...args))),
    onMousedown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.touchStart && _ctx.touchStart(...args))),
    onMousemove: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.touchMove && _ctx.touchMove(...args))),
    onMouseup: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.touchEnd && _ctx.touchEnd(...args))),
    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx._portraitContainerClick($event)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nonEmptyPages, (page, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "page",
        style: _normalizeStyle(_ctx.getSwipeStyle(index))
      }, [
        _createVNode(_component_StoryPage3dBookSide, {
          side: page.front,
          isCurrentUserStoryOwner: _ctx.isCurrentUserStoryOwner,
          choiceClicked: _ctx._choiceClicked,
          handleRightClick: _ctx.handleRightClick,
          handleLeftClick: _ctx.handleLeftClick,
          onImageChangeClick: _ctx._onImageChangeClick
        }, null, 8, ["side", "isCurrentUserStoryOwner", "choiceClicked", "handleRightClick", "handleLeftClick", "onImageChangeClick"])
      ], 4))
    }), 128))
  ], 32))
}