<template>
	<div class="word-container" ref="container">
		<div v-for="(word, index) in words" :key="index">
			<span v-if="word !== '\n\n'" class="word" :style="{ fontSize: fontSize + 'px' }">
				{{ isNewParagraph(word) ? '' : word }}
			</span>
			<div class="spacing" v-else></div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'AnimatedText',
	props: {
		text: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			words: [],
			fontSize: 30
		} as {
			words: string[];
			fontSize: number;
		};
	},
	methods: {
		isNewParagraph(currentWord: string) {
			return currentWord == '\n\n';
		},
		updateWords(newVal: string) {
			const newValSplit = newVal.split(' ');
			this.words = newValSplit.map((word) => {
				const suffix = !this.isNewParagraph(word) ? ' ' : '';
				const sanitizedWord = word.replace('\\"', '"');
				return sanitizedWord + suffix;
			});
		},
		checkOverflow: function (container: HTMLElement) {
			const observer = new ResizeObserver((entries) => {
				try {
					if (entries[0].target.scrollHeight > entries[0].target.clientHeight) {
						this.fontSize--;
					}
				} catch (e) {
					console.log('checkOverflow error', e);
					//unregister the observer
					observer.disconnect();
				}
			});

			observer.observe(container);
		}
	},
	mounted() {
		this.updateWords(this.text);
		const container = this.$refs.container as HTMLElement;
		this.checkOverflow(container);


		const windowWidth = window.innerWidth;
		const windowHeight = window.innerHeight;
		const biggerDimension = Math.max(windowWidth, windowHeight);
		console.log('biggerDimension', biggerDimension);

		const startFontSize = Math.max(Math.floor(biggerDimension / 50), this.fontSize);
		this.fontSize = startFontSize;

		// on window resize, reset the font size
		window.addEventListener('resize', () => {
			const fontSizeBefore = this.fontSize;

			if (this.fontSize < startFontSize) {
				this.fontSize = startFontSize;

				this.$nextTick(() => {
					// check for overflow again
					const container = this.$refs.container as HTMLElement;
					if (container && container.scrollHeight > container.clientHeight) {
						this.fontSize = fontSizeBefore;
					}
				});
			}
		});

		// check for overflow again
		const doCheck = () => {
			try {
				this.$nextTick(() => {
					const container = this.$refs.container as HTMLElement;
					if (container && container.scrollHeight > container.clientHeight) {
						if (this.fontSize < 5)
							return;
						this.fontSize--;
						if (this.fontSize > 1)
							doCheck();
					}
				});
			} catch (e) {
				console.log('doCheck error', e);
			}
		}
		doCheck();

	},
	watch: {
		text(newVal: string) {
			this.updateWords(newVal);

			this.$nextTick(() => {
				this.checkOverflow(this.$refs.container as HTMLElement);
			});
		},
	},
});

</script>

<style scoped>
.spacing {
	width: 100vw;
	height: 2rem;
}

.word-container {
	height: 90%;
	width: 100%;
	top: 0;
	left: 0;
	padding: 10%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-content: flex-start;
	justify-content: center;
	align-items: center;
}

.word-container div {
	line-height: 0.1vh;
}

.word {
	line-height: 1.6;
	letter-spacing: 1px;
	opacity: 0;
	animation: fadeIn 0.5s ease-in-out forwards;
	white-space: pre-wrap;
	font-family: 'Sniglet';
	color: #212427;
}


@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
</style>