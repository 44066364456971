<template>
    <div class="flex flex-col items-center justify-center bg-white my-10 mx-6">
        <div v-for="(prop, index) in props" :key="index"
            class="flex flex-col md:flex-row items-center justify-center w-full max-w-7xl p-1"
            :class="{ 'md:flex-row-reverse': index % 2 === 1 }">
            <div class="md:w-1/2 md:px-8 text-center md:text-left" :class="{ 'md:text-right': index % 2 === 0 }">
                <h2 class="text-2xl font-raleway font-bold mb-6">{{ $t(prop.title) }}</h2>
                <div class="text-gray-800 text-lg text-center md:text-justify leading-relaxed mb-8">{{ $t(prop.text) }}
                </div>
            </div>
            <div class=" md:px-8 flex justify-center items-center">
                <div class="w-full h-300px flex justify-center items-center my-3">
                    <img :src="prop.image" :alt="$t(prop.title)"
                        class="rounded-2xl max-w-full max-h-full object-contain">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ValueProps',
    data() {
        return {
            props: [
                {
                    title: 'landing.prop1.title',
                    text: 'landing.prop1.text',
                    image: 'https://static.wonderstories.ai/parent_child_reading.webp',
                },
                {
                    title: 'landing.prop2.title',
                    text: 'landing.prop2.text',
                    image: 'https://static.wonderstories.ai/child_reaching_for_book.webp',
                },
                {
                    title: 'landing.prop3.title',
                    text: 'landing.prop3.text',
                    image: 'https://static.wonderstories.ai/drawing.webp',
                },
                {
                    title: 'landing.prop4.title',
                    text: 'landing.prop4.text',
                    image: 'https://static.wonderstories.ai/grandparents.webp',
                },
            ],
        }
    },
}
</script>

<style>
.h-300px {
    height: 300px;
}
</style>
