<template>
	<div class="container mx-auto py-8">
		<h2 class="text-2xl font-bold mb-4">Import Stories</h2>
		<div class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
			<p>The imported stories will be added to your account.</p>
			<p>Stories that already exist (based on the unique id), it will be skipped.</p>
		</div>
		<div class="mb-4">
			<label for="importField" class="block text-sm font-medium text-gray-700">Paste JSON here:</label>
			<textarea id="importField" v-model="importData" rows="10"
				class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"></textarea>
		</div>
		<button @click="importStories" class="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">
			Import Stories
		</button>
		<h3 class="text-xl font-bold mt-8 mb-4">Preview Stories</h3>
		<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
			<div v-for="story in previewStories" :key="story.id"
				class="bg-white shadow rounded-lg p-4 flex items-center">
				<div class="flex-shrink-0 mr-4">
					<img :src="story.coverUrl" :alt="story.name" class="w-24 h-24 rounded-lg">
				</div>
				<div class="flex-grow">
					<h3 class="text-lg font-semibold">{{ story.name }}</h3>
					<p class="text-gray-500">{{ story.description }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import api from "@/api/api";
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'AdminImportStories',
	data() {
		return {
			importData: '',
			previewStories: [] as any[]
		};
	},
	methods: {
		async importStories() {
			try {
				const parsedStories = JSON.parse(this.importData);
				console.log('Parsed stories:', parsedStories);
				// Perform validation on parsed stories if needed
				// Add stories to the current admin's account (e.g., API call)
				// Clear the import field and preview after successful import

				const result = await api.adminImportStories(parsedStories);

				console.log('Import result:', result);

				this.importData = '';
				this.previewStories = [];
				const numAlreadyExists = result.data.result.numAlreadyExists;
				const numImported = result.data.result.numImported;
				alert('Stories imported successfully! ' + numAlreadyExists + ' already exists, ' + numImported + ' imported.');
			} catch (error) {
				console.error('Error importing stories:', error);
				alert('Invalid JSON format. Please check the input and try again.');
			}
		}
	},
	watch: {
		importData(newValue) {
			try {
				this.previewStories = JSON.parse(newValue);
			} catch (error) {
				this.previewStories = [];
			}
		}
	}
});
</script>
