import StoryDisplay from '../components/Story.vue';
import StoryCreation from '../components/story-creation/StoryCreation.vue';
import StoryTypePicker from '../components/story-creation/StoryTypePicker.vue';
import StoryModePicker from '../components/story-creation/StoryModePicker.vue';
import PastStories from '../components/your-stories/PastStories.vue';
import AllStories from '../components/AllStories.vue';
import UserProfile from '../components/user-profile/UserProfile.vue';
import TestPurchase from '../components/payments/TestPurchases.vue';
import PurchaseSuccess from '../components/payments/PurchaseSuccess.vue';
import PurchaseCancel from '../components/payments/PurchaseCancel.vue';
import BlogHome from '../components/BlogHome.vue';
import BlogPost from '../components/BlogPost.vue';
import Home from '../components/Home-Page.vue';
import NotFound from '../components/NotFound.vue';
import PrivacyPolicy from '../components/PrivacyPolicy.vue';
import TermsService from '../components/TermsService.vue';
import SignIn from '../components/SignIn.vue';
import SignUp from '../components/SignUp.vue';
import TOSAgreement from '../components/TOSAgreement.vue';
import SubscriptionPlans from '../components/SubscriptionPlans.vue';
import { createRouter, createWebHistory } from 'vue-router';
import '../style.css'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: Home
  },
  {
    path: '/story/:slugPath/:slugName',
    name: 'StoryDisplay',
    component: StoryDisplay,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/create',
    name: 'StoryTypePicker',
    component: StoryTypePicker,
  },
  {
    path: '/create/:type',
    name: 'StoryModePicker',
    component: StoryModePicker,
  },
  {
    path: '/create/:type/manual',
    name: 'StoryCreation',
    component: StoryCreation,
  },
  {
    path: '/bookshelf',
    name: 'PastStories',
    component: PastStories,
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfile,
  },
  {
    path: '/checkout/success/:id',
    name: 'PurchaseSuccess',
    component: PurchaseSuccess
  },
  {
    path: '/checkout/cancel/:id',
    name: 'PurchaseCancel',
    component: PurchaseCancel
  },
  {
    path: '/test-purchase',
    name: 'TestPurchase',
    component: TestPurchase,
  },
  {
    path: '/library',
    name: 'AllStories',
    component: AllStories,
  },
  {
    path: '/blog',
    name: 'BlogHome',
    component: BlogHome
  },
  {
    path: '/blog/:slug',
    name: 'BlogPost',
    component: BlogPost,
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: '/login',
    name: 'SignIn',
    component: SignIn,
    props: true
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    props: true
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms-of-service/agreement',
    name: 'TOSAgreement',
    component: TOSAgreement,
  },
  {
    path: '/terms-of-service',
    name: 'TermsService',
    component: TermsService,
  },
  {
    path: '/plans',
    name: 'SubscriptionPlans',
    component: SubscriptionPlans
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;