<template>
	<div class="fixed bottom-2 left-2 z-50 bg-red-500 p-2 rounded shadow" v-if="showDebug">
		<h3 class="text-sm font-semibold text-white mb-1">Dev Settings</h3>
		<div class="flex flex-col">
			<!--
			<div class="mb-1">
				<label for="model-select" class="mr-1 text-white text-xs">Image Model:</label>
				<select id="model-select" v-model="selectedModel" class="px-1 py-1 text-xs rounded">
					<option value="dalle3">DALL-E 3</option>
					<option value="midjourney">Midjourney</option>
				</select>
			</div>
			-->
			<div class="mb-1">
				<label for="model-generator" class="mr-1 text-white text-xs">StoryGen Model:</label>
				<select id="model-generator" v-model="storyGeneratorVersion" class="px-1 py-1 text-xs rounded">
					<option value="1">V1 (gpt4 functions)</option>
					<option value="2">V2 (gpt4 multistep)</option>
				</select>
			</div>
		</div>

		<button @click="disableDebug" class="w-full text-xs text-white bg-red-700 px-2 py-2 rounded">Close</button>
	</div>
</template>

<script>
import { saveDebugSetting, getDebugSetting } from './debug';

export default {
	name: 'DebugComponent',
	data() {
		return {
			selectedModel: 'dalle3',
			storyGeneratorVersion: "1",
			showDebug: false,
		};
	},
	methods: {
		disableDebug() {
			this.showDebug = false;
			saveDebugSetting('showDebug', this.showDebug);
		},
	},
	mounted() {
		this.selectedModel = getDebugSetting('imageModel', 'dalle3');
		this.showDebug = getDebugSetting('showDebug', false);
		this.storyGeneratorVersion = getDebugSetting('storyGeneratorVersion', '1');
		console.log('selectedModel', this.selectedModel);

		const enableMagicValue = 'aaaaaaaaaaaaa';
		let magicValue = '';
		// globally listen for the magic value to enable debug mode
		document.addEventListener('keydown', (e) => {
			if (this.showDebug)
				return;
			magicValue += e.key;
			if (magicValue.includes(enableMagicValue)) {
				this.showDebug = !this.showDebug;
				saveDebugSetting('showDebug', this.showDebug);
				magicValue = '';
			}

			if (magicValue.length > enableMagicValue.length || !enableMagicValue.startsWith(magicValue))
				magicValue = '';
		});
	},
	watch: {
		selectedModel: {
			handler: function (val, oldVal) {
				if (!oldVal)
					return;
				saveDebugSetting('imageModel', val);
			},
			immediate: true,
		},
		storyGeneratorVersion: {
			handler: function (val, oldVal) {
				if (!oldVal)
					return;
				saveDebugSetting('storyGeneratorVersion', val);
			},
			immediate: true,
		},
	},
};
</script>
