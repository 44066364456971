<template>
	<div class="confetti">
		<Vue3Lottie :animationData="ConfettiJSON" :scale="1" :loop="false" />
	</div>
	<div class="purchase-success">
		<div class="thanks">
			<Vue3Lottie :animationData="ThanksJSON" :scale="0.5" :loop="false" :speed='2' />
		</div>
		<h3 class="message">You can now start creating your own personalized wonder stories!</h3>
		<MainButton rounded @click="createStory">Create Story</MainButton>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Vue3Lottie } from 'vue3-lottie'
import ConfettiJSON from '@/assets/confetti.json';
import ThanksJSON from '@/assets/thanks.json';

export default defineComponent({
	name: 'PurchaseSuccess',
	components: {
		Vue3Lottie
	},
	data: function () {
		return {
			ConfettiJSON,
			ThanksJSON,
		};
	},
	methods: {
		createStory() {
			this.$router.push({ name: 'StoryTypePicker' });
		}
	}
});
</script>

<style scoped>
.message {
	text-align: center;
}

.purchase-success {
	display: flex;
	margin-top: 80px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.thanks {
	width: 100%;
}
</style>
