<template>
    <ConfirmDialog></ConfirmDialog>
    <div class="tos-container">
        <h1 class="header-text">{{ $t('tos_pp.page_title') }}</h1>
        <p class="centered-text">{{ $t('tos_pp.message_1') }}</p>
        <p class="centered-text">{{ $t('tos_pp.message_2') }}</p>
        <div class="spacer" />
        <MainButton text :label="$t('tos_pp.read_tos')" rounded @click="onTOSClick" />
        <div class="spacer" />
        <MainButton text :label="$t('tos_pp.read_pp')" rounded @click="onPPClick" />
        <div class="spacer" />

        <div class="button-row">
            <MainButton outlined severity="danger" :label="$t('tos_pp.reject')" rounded @click="onRejectClick" />
            <MainButton :label="$t('tos_pp.accept')" rounded @click="onAcceptClick" :loading="loading" />
        </div>
    </div>
</template>

<script>
import api from '@/api/api';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';
import { useI18n } from "vue-i18n";

import { useRouter } from 'vue-router';
export default {
    name: "TOSAgreement",
    components: {
        ConfirmDialog
    },
    data() {
        return {
            loading: false,
        };
    },
    setup() {
        const toast = useToast();
        const confirm = useConfirm();
        const router = useRouter()
        const { t } = useI18n();

        const showSuccessAlert = (message) => {
            toast.add({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
        };

        const showErrorAlert = (message) => {
            toast.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
        };

        const confirmRejection = () => {
            confirm.require({
                message: t('tos_pp.reject_text'),
                header: t('tos_pp.reject_header'),
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined p-button-rounded',
                rejectLabel: t('tos_pp.cancel'),
                acceptLabel: t('tos_pp.reject'),
                acceptClass: 'p-button-danger p-button-rounded',
                accept: () => {
                    router.push({ name: "HomePage" });
                },
            });
        };

        return { showSuccessAlert, showErrorAlert, confirmRejection };
    },
    methods: {
        onTOSClick() {
            window.open("/terms-of-service", "_blank")
        },
        onPPClick() {
            window.open("/privacy-policy", "_blank")
        },
        onRejectClick() {
            this.confirmRejection();
        },
        async onAcceptClick() {
            this.loading = true;
            await api.acceptTerms().then(() => {
                this.showSuccessAlert(this.$t('tos_pp.tos_accepted'));
                this.$router.push({ path: this.$route.query.destinationUrl ?? '/' });
            }).catch((error) => {
                this.showErrorAlert(error.response.data.error.message);
                console.error(error);
            }).finally(() => {
                this.loading = false;
            });
        }
    }
};
</script>

<style scoped>
.tos-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 2rem 0px 2rem;
    width: 100%;
}

.button-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 300px;
}

.header-text {
    text-align: center;
}

.centered-text {
    text-align: center;
    width: 100%;
    max-width: 800px;
}

.spacer {
    height: 20px;
}
</style>
