export default {
    assisted_mode: 'Modo Assistido',
    assisted_mode_description: 'Ajude a moldar a história descrevendo os heróis, vilões e elementos-chave',
    magic_mode: 'Modo Mágico',
    magic_mode_description: 'Deixe a magia acontecer e obtenha uma história única e aleatória',
    start_reading: 'Iniciar Leitura',
    craft_story: 'Criar História',
    create_new_story: 'Criar Nova História',
    describe_hero: 'Descreva o herói/heroína (Opcional)',
    hero_description_example: 'Ex: "uma menina de 8 anos com cabelos pretos cacheados e pele negra"',
    hero_description: 'Descrição do Herói/Heroína',
    describe_villain: 'Descreva o vilão (Opcional)',
    villain_description_example: 'Ex: "uma velha bruxa que odeia animais"',
    villain_description: 'Descrição do Vilão',
    describe_story_elements: 'Descreva o local e elementos-chave da história (Opcional)',
    story_elements_example: 'Ex: "Floresta Mágica, Coruja Sábia, Panda Engraçado, Inverno"',
    story_elements: 'Local e elementos da história',
    describe_lessons: 'Descreva a moral da história (Opcional)',
    lessons_example: 'i.e. "Respeitar os mais velhos, escovar os dentes, comer vegetais"',
    lessons_placeholder: 'Lições de vida',
    home: 'Início',
    past_stories: 'Histórias Anteriores',
    setting: 'Ajustes',
    new_story: 'Nova História',
    read_story: 'Ler História',
    previous: 'Anterior',
    next: 'Próxima',
    exit: 'Sair',
    finish: 'Terminar',
    profile: 'Ajustes',
    settings: 'Ajustes',
    dreaming_up_story: 'Criando sua história...\nCuidado com os monstros das cócegas!',
    tucking_in_characters: 'Ajeitando os personagens na cama...\nOps, cuidado com as molas da cama!',
    sprinkling_fairy_dust: 'Espalhando pó de fada nas páginas...\nNão espirre!',
    checking_under_bed: 'Verificando debaixo da cama por monstros...\nTudo limpo para a hora da história!',
    fluffing_clouds: 'Enchendo as nuvens em Terra dos Sonhos...\nZzz...',
    gathering_moonbeams: 'Coletando raios de lua para um abajur noturno...\nQuase na hora da história de dormir!',
    teaching_dragons: 'Ensinando dragões a não cuspir fogo no livro...\nSegurança em primeiro lugar!',
    whispering_owls: 'Sussurrando segredos para as corujas noturnas...\nElas são ótimas ouvintes.',
    mixing_potion: 'Misturando uma poção de doces sonhos e aventuras...\nMisturando, não agitando!',
    setting_stage: 'Preparando o palco para um conto ao luar...\nAs estrelas estão se posicionando!',
    convincing_characters: 'Convencendo os personagens do livro que também é hora de dormir...\nNão é tarefa fácil!',
    dusting_magic_carpets: 'Desempoeirando tapetes mágicos...\nApertem os cintos para uma jornada mágica!',
    asking_night_sky: 'Pedindo ao céu noturno por mais uma estrela...\nPara um brilho extra na sua história.',
    negotiating_sandman: 'Negociando extensões de horário de dormir com o Homem da Areia...\nSó mais alguns minutos!',
    soothing_goblins: 'Acalmando goblins mal-humorados...\nEles sempre querem ficar acordados até tarde!',
    arranging_pillows: 'Arrumando almofadas para uma configuração confortável de história...\nNível de fofura: Especialista!',
    lulling_dragons: 'Fazendo os dragões do livro tirarem uma soneca...\nSilêncio, por favor!',
    rearranging_stars: 'Rearranjando as estrelas para um céu noturno perfeito...\nQuase brilhante o suficiente!',
    whispering_moon: 'Sussurrando uma canção de ninar para a lua...\nEstá ficando mais sonolenta a cada minuto!',
    preparing_cocoa: 'Preparando uma xícara de chocolate quente sonhador para nossos personagens...\nMarshmallows extras!',
    crafting_dreams: 'Transformando sonhos em palavras...\nA mágica está acontecendo!',
    night_breeze_pages: 'Pedindo à brisa noturna para virar as páginas...\nCom delicadeza agora!',
    training_fireflies: 'Treinando os vaga-lumes para acender no momento certo...\nE, ação!',
    instructing_fairies: 'Instruindo as fadas na etiqueta adequada de bater de asas...\nÉ tudo sobre graça!',
    polishing_slippers: 'Polindo os sapatinhos de cristal para uma aparição da Cinderela...\nHora do brilho!',
    perfecting_pillow_fort: 'Aperfeiçoando a arte do forte de almofadas...\nIntegridade estrutural é fundamental!',
    sending_invitations: 'Enviando convites para os personagens da história...\nRSVP: Terra dos Sonhos!',
    warming_crickets: 'Aquecendo a orquestra de grilos...\nA trilha sonora de hoje é chirp-tástica!',
    calibrating_dream_catcher: 'Calibrando o apanhador de sonhos...\nCapturando pesadelos, liberando sonhos!',
    teddy_bear_roll_call: 'Realizando uma chamada final dos ursinhos de pelúcia...\nTodos presentes e fofinhos!',
    aligning_stars: 'Alinhando as estrelas para escrever \'Doces Sonhos\'...\nSó um pouco para a esquerda...',
    baking_gingerbread: 'Assando biscoitos de gengibre para a aconchegante casa da história...\nCheira a aventuras doces!',
    bedtime_prayer: 'Sussurrando uma oração de boa noite aos anjos da guarda...\nVigilantes a noite toda!',
    setting_lullaby_mood: 'Criando o clima com uma suave canção de ninar...\nMelodia da noite!',
    choreographing_fireflies: 'Coreografando uma dança dos vaga-lumes...\nA apresentação de hoje está brilhante!',
    enchanting_bedtime_stories_title: 'Histórias Encantadoras, \n Personalizadas para o Seu Filho',
    generate_stories_description: 'Histórias feitas sob medida na hora com lindas ilustrações',
    begin_adventure_button: 'Comece Sua Aventura',
    customized_and_captivating_title: 'Personalizado e Fascinante',
    customized_story_description: 'Transforme seu filho no herói de sua própria história',
    valuable_lessons_point: 'Incorpore lições valiosas com temas, como a \'importância de escovar os dentes\'',
    endless_wonder_title: 'Mágica Infinita Todas as Noites',
    fresh_story_night_description: 'Descubra uma história nova e envolvente todas as noites',
    lacking_inspiration: 'Faltou inspiração? Deixe nosso \'modo mágico\' criar uma história única',
    stunning_visuals_title: 'Visuais Impressionantes',
    chapter_life_illustrations_description: 'Dê vida a cada capítulo com ilustrações lindas e profissionais',
    delight_in_illustrations_description: 'Veja a alegria do seu filho ao se verem ilustrados na história',
    please_wait: 'Sua história mágica pode demorar até cinco minutos para ser criada.\nSe sair da página, a história aparecerá na sua lista de histórias.',
    loading: 'Carregando...',
    create_new_story_title: 'Nova história',
    create_story_message: 'Tem certeza de que deseja criar uma nova história mágica?',
    ok_button_label: 'Criar História',
    cancel_button_label: 'Mais tarde',
    no_story_yet: 'Você ainda não tem histórias.',
    nav_bar: {
        home: 'Início',
        your_stories: 'Suas Histórias',
        profile: 'Perfil',
        popular_stories: 'Histórias Populares',
    },
    landing: {
        prop1: {
            title: 'Só sua',
            text: 'Transforme seus filhos nos protagonistas de suas próprias aventuras emocionantes. Com nossa plataforma criativa, você pode designar heróis e vilões, escolher temas centrais e tecer narrativas envolventes que instilam lições valiosas. Transforme a narração em uma jornada personalizada para os pequenos.',
        },
        prop2: {
            title: 'Eles decidem o que acontece',
            text: 'Empodere as crianças para fazer parte da história. Cada capítulo oferece escolhas que moldam a aventura, permitindo que os jovens leitores decidam as ações do herói e criem finais únicos, tornando cada experiência de leitura emocionantemente pessoal.',
        },
        prop3: {
            title: 'Ilustrações que despertam a imaginação',
            text: 'Mergulhe em um mundo onde cada capítulo ganha vida com ilustrações lindamente detalhadas. Espere gráficos de tirar o fôlego que irão encantar e surpreender leitores de todas as idades, transformando cada história em uma jornada visualmente impressionante.',
        },
        prop4: {
            title: 'Aventuras sem fim',
            text: 'Experimente uma história diferente a cada noite com apenas um clique, graças ao nosso acesso imediato a uma coleção de histórias pré-fabricadas. Aproveite a tranquilidade sabendo que cada narrativa é familiar, garantindo uma experiência de narração segura e agradável para toda a família.',
        },
    },
    tos_pp: {
        privacy_policy: 'Política de Privacidade',
        terms_service: 'Termos de Serviço',
        tos_accepted: 'Termos de Serviço aceitos!',
        reject_text: 'Tem certeza de que deseja rejeitar nossos Termos de Serviço e Política de Privacidade? Você não poderá usar o wonderstories.ai até aceitá-los.',
        reject_header: 'Confirmação',
        reject: 'Rejeitar',
        cancel: 'Cancelar',
        page_title: 'Termos de Serviço e Política de Privacidade',
        message_1: 'Para usar este aplicativo e desbloquear a capacidade de criar e compartilhar histórias, por favor, leia e concorde com nossos Termos de Serviço.',
        message_2: 'Sua concordância é essencial para uma experiência segura e agradável para todos os usuários.',
        read_tos: 'Ler Termos de Serviço',
        read_pp: 'Ler Política de Privacidade',
        accept: 'Aceitar e Continuar',
    }
};
