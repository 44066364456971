<template>
    <div class="home-container">
        <div class="home-header">
        </div>
        <div class="w-full cursor-pointer" v-if="showPromoAlert" @click="goToSignUp">
            <div class="w-full bg-[#fde187] flex items-center justify-flex-end fixed z-10 shadow-lg py-4 md:py-2">
                <div class="w-full flex flex-col md:flex-row items-center gap-2 justify-center flex-wrap ">
                    <div class="text-lg text-black text-center font-bold ">🚨 LIMITED TIME OFFER:
                    </div>
                    <div class="text-xl text-black text-center font-bold"> {{ bannerText }}
                    </div>
                    <MainButton rounded outlined class="bg-white text-black ml-2 hidden md:block" size="small"
                        label="Sign Up" @click="goToSignUp" />
                </div>
                <MainButton text icon="pi pi-times" class="text-black mr-2 hidden md:flex" size="small"
                    @click="closeBanner" />
            </div>
            <div class="h-[70px]" />
        </div>
        <Hero @createStoryClick="toggleWaitlistDialog" />
        <Statistics />
        <ValueProps />
        <StoryGallery @storyClick="toggleWaitlistDialog" />
        <PricingOptions @buyTier="toggleWaitlistDialog" />
        <ParentTestimonials />
        <FrequentQuestions />
        <MainFooter />
        <DialogModal v-model:visible="showWaitlistDialog" modal header="Get Early Access!" class="waitlist-title">
            <span class="waitlist-message">Be the first in line! Join our waitlist for an exclusive beta invite or to be
                notified at launch!"</span>
            <div class="email-container">
                <label for="email" class="font-semibold w-6rem">Email</label>
                <InputText v-model="waitlistEmail" id="email" placeholder="your@email.com" class="email-input"
                    autocomplete="off" />
            </div>
            <div class="waitlist-buttons">
                <MainButton rounded type="button" label="Join Waitlist" @click="joinWaitlist" />
            </div>
        </DialogModal>
    </div>
</template>

<script lang="ts">
import ParentTestimonials from './Testimonials.vue'
import FrequentQuestions from './FrequentQuestions.vue'
import MainFooter from '../Footer.vue'
import StoryGallery from './StoryGallery.vue';
import Statistics from './Statistics.vue';
import PricingOptions from './PricingOptions.vue';
import Hero from './Hero.vue';
import ValueProps from './ValueProps.vue';
import { useSeoMeta } from '@unhead/vue'
import api from '@/api/api'
import { useToast } from 'primevue/usetoast';
import { defineComponent } from 'vue';
import { useUser } from 'vue-clerk';

export default defineComponent({
    name: 'HomePage',
    components: {
        ParentTestimonials,
        FrequentQuestions,
        MainFooter,
        StoryGallery,
        PricingOptions,
        Hero,
        ValueProps,
        Statistics
    },
    data() {
        return {
            showWaitlistDialog: false,
            waitlistEmail: null,
            showPromoAlert: false,
            bannerText: '',
        }
    },
    mounted() {
        const { isSignedIn } = useUser();
        const showPromoKey = localStorage.getItem('showPromoAlert') === null ? true : localStorage.getItem('showPromoAlert') === 'true';
        this.showPromoAlert = showPromoKey && process.env.VUE_APP_CREDIT_STORIES_PROMO_ON_SIGNUP !== '0' && !isSignedIn.value;
        this.bannerText = `Sign up for free and get ${process.env.VUE_APP_CREDIT_STORIES_PROMO_ON_SIGNUP} story credits! 🚨`;
    },
    setup() {
        const toast = useToast();

        const showSuccessAlert = () => {
            toast.add({ severity: 'success', summary: 'Success', detail: 'Thank you for joining the waitlist!', life: 3000 });
        };

        const showErrorAlert = (message: any) => {
            toast.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
        };

        useSeoMeta({
            title: '',
            description: '',
        });

        return { showSuccessAlert, showErrorAlert, useSeoMeta };
    },
    methods: {
        closeBanner() {
            this.showPromoAlert = false;
            localStorage.setItem('showPromoAlert', 'false');
        },
        goToSignUp() {
            return this.$router.push({ name: 'SignIn' })
        },
        toggleWaitlistDialog() {
            return this.$router.push({ name: 'StoryTypePicker' })
        },
        async joinWaitlist() {

            console.log('joinWaitlist', this.waitlistEmail);
            if (!this.waitlistEmail || !this.validateEmail(this.waitlistEmail))
                return this.showErrorAlert('Please enter a valid email address.');
            try {
                await api.joinWaitlist(this.waitlistEmail)
                this.showWaitlistDialog = false
                this.showSuccessAlert();
            } catch (error) {
                console.error(error)
            }
        },
        validateEmail(email: string) {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        }
    }
});
</script>

<style scoped>
.home-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: white;
}

.home-header {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 41px;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
}

.email-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.waitlist-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6rem;
}

.email-input {
    display: flex;
    width: 100%;
}

.waitlist-message {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    font-weight: 500;
}

.waitlist-title {
    font-size: 1.5rem;
    font-weight: 500;
    width: 40px;
}
</style>