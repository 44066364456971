<template>
    <div class="w-full items-center flex flex-col bg-white">
        <PricingOptions />
        <FrequentQuestions />
        <Footer />
    </div>
</template>

<script>
import api from '@/api/api';
import { useUser } from 'vue-clerk';
import PricingOptions from './landing-page/PricingOptions.vue';
import FrequentQuestions from './landing-page/FrequentQuestions.vue';
import Footer from './Footer.vue';

export default {
    name: "SubscriptionPlans",
    components: {
        PricingOptions,
        FrequentQuestions,
        Footer
    },
    mounted() {
        const { isSignedIn } = useUser()
        const planId = this.$route.query.planId;
        if (isSignedIn.value && planId) {
            this.buyTier(planId);
        }
    },
    methods: {
        async buyTier(planId) {
            const checkout = (await api.createCheckout(planId)).data;
            const openResult = window.open(checkout.checkoutUrl, '_blank');
            if (!openResult) {
                window.location.href = checkout.checkoutUrl;
            }
        },
    },
};
</script>

<style scoped>
.container {
    margin-top: 60px;
}
</style>