import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41aa8de0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "story-page" }
const _hoisted_2 = { class: "button-container" }
const _hoisted_3 = {
  key: 0,
  class: "right-buttons"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "story-column"
}
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { class: "flex flex-col items-center justify-center mt-20" }
const _hoisted_9 = { class: "flex flex-col items-center" }
const _hoisted_10 = ["src", "alt"]
const _hoisted_11 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_MainButton = _resolveComponent("MainButton")!
  const _component_MenuList = _resolveComponent("MenuList")!
  const _component_StoryDetails = _resolveComponent("StoryDetails")!
  const _component_StoryPage = _resolveComponent("StoryPage")!
  const _component_StoryLoading = _resolveComponent("StoryLoading")!
  const _component_Vue3Lottie = _resolveComponent("Vue3Lottie")!
  const _component_GalleriaWidget = _resolveComponent("GalleriaWidget")!
  const _component_ReportModal = _resolveComponent("ReportModal")!
  const _component_ShareModal = _resolveComponent("ShareModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ConfirmDialog),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_MainButton, {
          outlined: "",
          class: "exit-button",
          icon: "pi pi-times",
          rounded: "",
          onClick: _ctx.onExitClick
        }, null, 8, ["onClick"]),
        (_ctx.story != null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_MainButton, {
                rounded: "",
                outlined: "",
                class: "exit-button",
                icon: "pi pi-ellipsis-v",
                onClick: _ctx.toggleMenu,
                "aria-haspopup": "true",
                "aria-controls": "overlay_menu"
              }, null, 8, ["onClick"]),
              _createVNode(_component_MenuList, {
                ref: "menu",
                id: "overlay_menu",
                model: _ctx.menuItems,
                popup: true
              }, null, 8, ["model"])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showDetails && _ctx.story)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_StoryDetails, {
              story: _ctx.story,
              onShare: _ctx.onShareClick,
              onRead: _ctx.onReadClick
            }, null, 8, ["story", "onShare", "onRead"])
          ]))
        : (_ctx.story && !_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_StoryPage, {
                story: _ctx.story,
                nextPage: _ctx.pages[_ctx.pageIndex + 1],
                page: _ctx.pages[_ctx.pageIndex],
                pages: _ctx.pages,
                pageCount: _ctx.pages.length,
                currentPage: _ctx.pageIndex,
                onGoBack: _ctx.handleLeftClick,
                onGoForward: _ctx.handleRightClick,
                onChoiceClicked: _ctx.onChoiceClick,
                onOnShareClick: _ctx.onShareClick,
                onOnImageChangeClick: _ctx.onImageChangeClick
              }, null, 8, ["story", "nextPage", "page", "pages", "pageCount", "currentPage", "onGoBack", "onGoForward", "onChoiceClicked", "onOnShareClick", "onOnImageChangeClick"])
            ]))
          : (_ctx.story?.status == 'inProgress' && _ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_StoryLoading)
              ]))
            : (_ctx.isLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_Vue3Lottie, {
                      animationData: _ctx.WizardJSON,
                      height: 200
                    }, null, 8, ["animationData"]),
                    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('loading')), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
      _createVNode(_component_GalleriaWidget, {
        activeIndex: _ctx.galleriaActiveIndex,
        "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.galleriaActiveIndex) = $event)),
        visible: _ctx.showGalleria,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showGalleria) = $event)),
        value: _ctx.galleriaImages,
        numVisible: 4,
        responsiveOptions: _ctx.responsiveOptions,
        containerStyle: "width: 100%; max-width: 540px",
        fullScreen: true
      }, {
        item: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("img", {
              src: slotProps.item.itemImageSrc,
              alt: slotProps.item.alt,
              style: {"width":"100%"}
            }, null, 8, _hoisted_10),
            _createVNode(_component_MainButton, {
              class: "m-4",
              rounded: "",
              label: "Select Image",
              onClick: _ctx._imageSelected
            }, null, 8, ["onClick"])
          ])
        ]),
        thumbnail: _withCtx((slotProps) => [
          _createElementVNode("img", {
            src: slotProps.item.thumbnailImageSrc,
            alt: slotProps.item.alt,
            class: _normalizeClass(['h-[80px] object-fill aspect-square mx-2', { 'border-2 border-solid border-white': slotProps.item.index == _ctx.galleriaActiveIndex }])
          }, null, 10, _hoisted_11)
        ]),
        _: 1
      }, 8, ["activeIndex", "visible", "value", "responsiveOptions"])
    ]),
    (_ctx.story)
      ? (_openBlock(), _createBlock(_component_ReportModal, {
          key: 0,
          visible: _ctx.showReport,
          storyId: _ctx.story.id,
          onClose: _ctx.toggleReportVisibility
        }, null, 8, ["visible", "storyId", "onClose"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ShareModal, {
      visible: _ctx.showShare,
      onClose: _ctx.toggleShare,
      urlText: _ctx.url
    }, null, 8, ["visible", "onClose", "urlText"])
  ], 64))
}