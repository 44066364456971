<template>
    <div class="footer-footer">
        <footer class="footer-footer1">
            <div class="footer-container30">
                <span class="footer-logo1">{{ this.appName }}</span>
                <nav class="footer-nav1">
                    <a class="footer-nav-item" href="/">Home</a>
                    <a class="footer-nav-item" href="/all-stories">Popular Stories</a>
                    <a class="footer-nav-item" href="/blog">Blog</a>
                    <a class="footer-nav-item" href="/terms-of-service">Terms of Service</a>
                    <a class="footer-nav-item" href="/privacy-policy">Privacy Policy</a>
                </nav>
            </div>
            <div class="footer-separator"></div>
            <div class="footer-container31">
                <span class="footer-text88">
                    © 2024 Trainlyai LLC, All Rights Reserved.
                </span>
                <div class="footer-icon-group1">
                    <svg viewBox="0 0 448 512" class="footer-icon10" @click="goToTikTok">
                        <path
                            d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z">
                        </path>
                    </svg>
                    <svg viewBox="0 0 877.7142857142857 1024" class="footer-icon12" @click="goToInstagram">
                        <path
                            d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z">
                        </path>
                    </svg>
                    <svg viewBox="0 0 602.2582857142856 1024" class="footer-icon14" @click="goToFacebook">
                        <path
                            d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z">
                        </path>
                    </svg>
                </div>
            </div>
        </footer>
    </div>
</template>


<style scoped>
.footer-footer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dl-color-gray-black);
}

.footer-footer1 {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fourunits);
    justify-content: space-between;
}


.footer-container30 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.footer-logo1 {
    color: var(--dl-color-gray-white);
    font-size: 2em;
    font-weight: bold;
}

.footer-nav1 {
    flex: 0 0 auto;
    display: flex;
    margin-top: 0px;
    align-items: center;
    flex-direction: row;
    gap: var(--dl-space-space-unit);
}

.footer-nav-item {
    color: var(--dl-color-secondary-gray500);
    cursor: pointer;
}

.footer-separator {
    flex: 0 0 auto;
    width: 100%;
    height: 0px;
    display: flex;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    margin-left: 0px;
    border-color: var(--dl-color-gray-800);
    border-style: solid;
    border-width: 1px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: row;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

.footer-container31 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.footer-text88 {
    color: var(--dl-color-secondary-gray500);
}

.footer-icon-group1 {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.footer-icon10 {
    fill: var(--dl-color-secondary-gray500);
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-twounits);
    cursor: pointer;
}

.footer-icon12 {
    fill: var(--dl-color-secondary-gray500);
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-twounits);
    cursor: pointer;
}

.footer-icon14 {
    fill: var(--dl-color-secondary-gray500);
    width: 24px;
    height: 24px;
    cursor: pointer;
}

@media(max-width: 767px) {

    .footer-footer1 {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }

    .footer-separator {
        margin-top: var(--dl-space-space-oneandhalfunits);
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }

    .footer-container31 {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

    .footer-text88 {
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }

}

@media(max-width: 479px) {


    .footer-footer1 {
        padding: var(--dl-space-space-unit);
    }

    .footer-separator {
        margin-top: var(--dl-space-space-oneandhalfunits);
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }

    .footer-container31 {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

    .footer-text88 {
        text-align: center;
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
}
</style>

<script>
export default {
    name: 'MainFooter',
    data() {
        return {
            appName: process.env.VUE_APP_NAME,
        };
    },
    methods: {
        goToInstagram() {
            window.open('https://www.instagram.com/wonderstories.ai/', '_blank');
        },
        goToFacebook() {
            window.open('https://www.facebook.com/profile.php?id=61556156672028', '_blank');
        },
        goToTikTok() {
            window.open('https://www.tiktok.com/@wonderstories.ai', '_blank');
        },
        goToTwitter() {
            window.open('https://twitter.com/wonderstoriesai', '_blank');
        },
    },
}
</script>