import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "bg-purple-100 py-4 w-full" }
const _hoisted_2 = { class: "px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto" }
const _hoisted_3 = { class: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8" }
const _hoisted_4 = { class: "text-4xl font-bold text-primary" }
const _hoisted_5 = { class: "mt-2 text-xl font-bold text-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stats, (stat) => {
          return (_openBlock(), _createElementBlock("div", {
            key: stat.title,
            class: "text-center"
          }, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(stat.stat), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(stat.title), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}