export default {
    assisted_mode: 'Assisted Mode',
    assisted_mode_description: 'Help shape the story by describing the heroes, villains and themes',
    magic_mode: 'Wonder Mode',
    magic_mode_description: 'Let the wonder happen and get a random unique story',
    start_reading: 'Start Reading',
    craft_story: 'Craft Story',
    create_new_story: 'Create New Story',
    describe_story: 'Summary *',
    hero_description_example: 'e.g "A 8 year old girl named Jamie with dark skin tone, medium coily hair and green eyes"',
    describe_hero: 'Main Character(s) Description *',
    story_description_example: 'e.g "Jamie goes to the zoo for the first time and make friends with all the animals"',
    hero_description: 'Hero description',
    describe_villain: 'Villain or Obstacle (Optional)',
    villain_description_example: 'e.g. "An escaped elephant that is causing trouble at the zoo"',
    villain_description: 'Villain description',
    describe_story_elements: 'Summary, General Settings, Key Elements (Optional)',
    story_elements_example: 'e.g. "Jamie goes to the zoo for the first time and make friends with all the animals, Summer, Ice Cream"',
    story_elements: 'Story elements and setting',
    describe_lessons: 'Lessons (Optional)',
    lessons_example: 'e.g. "Don\'t be afraid of making new friends"',
    lessons_placeholder: 'Life lessons',
    home: 'Home',
    past_stories: 'Your Stories',
    setting: 'Setting',
    new_story: 'New Story',
    read_story: 'Read Story',
    previous: 'Previous',
    next: 'Next',
    exit: 'Exit',
    finish: 'Finish',
    settings: 'Settings',
    dreaming_up_story: 'Dreaming up your story...\nBeware of the tickle monsters!',
    tucking_in_characters: 'Tucking in the characters...\nOops, watch out for the bed springs!',
    sprinkling_fairy_dust: 'Sprinkling fairy dust on the pages...\nDon\'t sneeze!',
    checking_under_bed: 'Checking under the bed for monsters...\nAll clear for story time!',
    fluffing_clouds: 'Fluffing up the clouds in Dreamland...\nZzz...',
    gathering_moonbeams: 'Gathering moonbeams for a nightlight...\nIt\'s almost bedtime story time!',
    teaching_dragons: 'Teaching dragons not to breathe fire on the book...\nSafety first!',
    whispering_owls: 'Whispering secrets to the night owls...\nThey\'re great listeners.',
    mixing_potion: 'Mixing a potion of sweet dreams and adventures...\nStirring, not shaking!',
    setting_stage: 'Setting the stage for a moonlit tale...\nStars are getting in places!',
    convincing_characters: 'Convincing the storybook characters it\'s past their bedtime too...\nNot an easy task!',
    dusting_magic_carpets: 'Dusting off magic carpets...\nFasten your seatbelts for a magical journey!',
    asking_night_sky: 'Asking the night sky for just one more star...\nFor extra twinkle in your story.',
    negotiating_sandman: 'Negotiating bedtime extensions with the Sandman...\nJust a few more minutes!',
    soothing_goblins: 'Soothing grumpy goblins...\nThey always want to stay up late!',
    arranging_pillows: 'Arranging pillows for a comfy story setup...\nFluffiness level: Expert!',
    lulling_dragons: 'Lulling the book\'s dragons into a nap...\nQuiet, please!',
    rearranging_stars: 'Rearranging the stars for the perfect night sky...\nAlmost sparkling enough!',
    whispering_moon: 'Whispering a lullaby to the moon...\nIt\'s getting sleepier by the minute!',
    preparing_cocoa: 'Preparing a cup of dreamy hot cocoa for our characters...\nExtra marshmallows!',
    crafting_dreams: 'Crafting dreams into words...\nThe magic is happening!',
    night_breeze_pages: 'Asking the night breeze to turn the pages...\nGently now!',
    training_fireflies: 'Training the fireflies to light up at just the right moment...\nAnd, action!',
    instructing_fairies: 'Instructing the fairies in proper wing-flutter etiquette...\nIt\'s all about grace!',
    polishing_slippers: 'Polishing the glass slippers for a Cinderella cameo...\nSparkle time!',
    perfecting_pillow_fort: 'Perfecting the art of the pillow fort...\nStructural integrity is key!',
    sending_invitations: 'Sending invitations to the story\'s characters...\nRSVP: Dreamland!',
    warming_crickets: 'Warming up the orchestra of crickets...\nTonight\'s soundtrack is chirp-tastic!',
    calibrating_dream_catcher: 'Calibrating the dream catcher...\nCatching nightmares, releasing dreams!',
    teddy_bear_roll_call: 'Conducting a final teddy bear roll call...\nEveryone present and cuddly!',
    aligning_stars: 'Aligning the stars to spell \'Sweet Dreams\'...\nJust a little to the left...',
    baking_gingerbread: 'Baking gingerbread for the story\'s cozy cottage...\nSmells like sweet adventures!',
    bedtime_prayer: 'Whispering a bedtime prayer to the guardian angels...\nKeeping watch all night!',
    setting_lullaby_mood: 'Setting the mood with a soft lullaby...\nMelody of the night!',
    choreographing_fireflies: 'Choreographing a dance of the fireflies...\nTonight\'s performance is glowing!',
    enchanting_bedtime_stories_title: 'Enchanting Bedtime Stories, \n Personalized for Your Child',
    generate_stories_description: 'Instantly generate tailor-made stories with vibrant illustrations',
    begin_adventure_button: 'Begin Your Adventure',
    customized_and_captivating_title: 'Customized and Captivating',
    customized_story_description: 'Transform your child into the hero of their own tale',
    valuable_lessons_point: 'Incorporate valuable lessons using themes, like the \'importance of brushing teeth\'',
    endless_wonder_title: 'Endless Wonder Every Evening',
    fresh_story_night_description: 'Discover a fresh, engaging story every night',
    lacking_inspiration: 'Lacking inspiration? Let our \'wonder mode\' conjure up a one-of-a-kind story',
    stunning_visuals_title: 'Stunning Visuals',
    chapter_life_illustrations_description: 'Bring each chapter to life with exquisite, professional illustrations',
    delight_in_illustrations_description: 'Watch their delight as they see themselves illustrated in the story',
    please_wait: 'Your new wonder story may take up to 5 minutes to be generated.\nIf you close this page, you can find it later on My Bookshelf',
    loading: 'Loading...',
    create_new_story_title: 'New story',
    create_story_message: 'Are you sure you want to create a new wonder story?',
    ok_button_label: 'Create Story',
    cancel_button_label: 'Later',
    no_story_yet: 'You don\'t have any stories yet.',
    nav_bar: {
        home: 'Home',
        your_stories: 'My Bookshelf',
        profile: 'Profile',
        popular_stories: 'The Library',
    },
    story_gallery: {
        title: 'Explore Popular Wonder Stories',
        description: "Explore a few of the incredible children's books created by kids, parents and loved ones across the globe!",
    },
    landing: {
        prop1: {
            title: 'Customized to their Curiosity',
            text: 'Customize age-range, lessons, themes, and personality traits and more to ensure every child sees themselves in the stories they read and create.',
        },
        prop2: {
            title: 'Create Illustrated Books in Seconds',
            text: 'Dive into a world where every chapter comes to life with beautifully detailed illustrations. Expect eye-popping graphics that will delight and surprise readers of all ages, turning each story into a visually stunning journey.',
        },
        prop3: {
            title: 'Build a Library of Your Own Creations',
            text: 'Your library becomes an enchanted treasure chest of personalized tales you created. Re-read and re-live the joy of your tailored children’s books again and again. Ignite the joy of discovery with every read, as WonderStories brings to life a world where every page is tailored to your child\'s imagination and growth.',
        },
        prop4: {
            title: 'Share your Stories with the World!',
            text: 'Share your incredible children’s books with your friends, family or maybe even a new parent! All of your stories are easily shareable across text message, email and all leading social media platforms. Let your tribe take part in the joy you create!',
        },
    },
    tos_pp: {
        privacy_policy: 'Privacy Policy',
        terms_service: 'Terms of Service',
        tos_accepted: 'Terms of Service accepted!',
        reject_text: 'Are you sure you want to reject our Terms Of Services and Privacy Policy? You won\'t be able to use wonderstories.ai until you accept them.',
        reject_header: 'Confirmation',
        reject: 'Reject',
        cancel: 'Cancel',
        page_title: 'Terms of Service and Privacy Policy',
        message_1: 'To use this app and unlock the ability to create and share wonderstories, please read and agree to our Terms of Service (TOS).',
        message_2: 'Your agreement is essential for a safe and enjoyable experience for all users.',
        read_tos: 'Read Terms of Service',
        read_pp: 'Read Privacy Policy',
        accept: 'Accept and Continue',
    }
};