<template>
    <h1 class="header-text header-padding ">All Articles</h1>
    <div class="blog-home">
        <div class="grid">
            <div v-for="post in blogPosts" :key="post.id" class="article-card" @click="goToBlogPost(post.slug)">
                <img clas="card-img" :src="post.imageUrl" alt="Rarible and Dibbs">
                <div class="card-content">
                    <div class="card-title">{{ post.headline }}</div>
                    <div class="card-description">{{ post.description }}</div>
                    <div class="card-date">{{ new Date(post.publishedAt).toLocaleDateString() + ' • ' + post.readTime }}
                    </div>
                    <div class="card-author">{{ post.author.name }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="spacing"></div>
    <MainFooter />
</template>

<script>
import api from "@/api/api.ts";
import MainFooter from '@/components/Footer'

export default {
    name: "BlogHome",
    components: {
        MainFooter
    },
    data() {
        return {
            blogPosts: [],
        };
    },
    mounted() {
        this.loadPosts();
    },
    methods: {
        async loadPosts() {
            this.isLoading = true;
            this.blogPosts = [];
            api.getBlogPosts(this)
                .then((response) => {
                    this.isLoading = false;
                    console.log(response.data);
                    this.blogPosts = response.data.posts;
                })
                .catch((error) => {
                    console.log(error)
                    this.isLoading = false;
                });
        },
        goToBlogPost(slug) {
            this.$router.push({ name: "BlogPost", params: { slug } });
        },
    },
};
</script>

<style>
.spacing {
    height: 80px;
}

.card-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
}

.article-card {
    width: auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    font-family: 'Arial', sans-serif;
    max-width: 400px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.card-img {
    width: 100%;
    height: auto;
}

.card-content {
    padding: 20px;
}

.card-title {
    font-size: 24px;
    font-weight: bold;
    margin: 8px 0;
    text-align: left;
}

.card-description {
    font-size: 18px;
    color: #666;
    margin: 8px 0;
    text-align: left;
}

.card-author {
    margin: 10px 0 0 0;
    font-size: 16px;
    color: #999;
    text-align: left;
    font-weight: bold;
}

.card-date {
    margin: 20px 0 0 0;
    font-size: 16px;
    color: #999;
    text-align: left;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
    margin: auto;
}
</style>
