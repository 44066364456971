<template>
  <ConfirmDialog></ConfirmDialog>
  <div v-if="loaded">
    <div class="flex flex-col mt-20 w-full">
      <div class="flex flex-col self-center items-center mx-5 px-5 rounded-lg w-full">
        <div class="text-3xl mb-8">Manage your plan and subscription.</div>

        <TabView class="w-4/5 rounded-lg">
          <TabPanel header="Plan">
            <div v-if="currentPlan" class="bg-white p-6 max-w-md mx-auto mb-8">
              <div class="flex flex-col justify-between items-start ">
                <div class="flex items-center">
                  <div class="flex flex-col gap-2">
                    <div class="text-xl font-bold text-black">Plan: {{ currentPlan.name }}</div>
                    <div class="text-lg text-black">Price: ${{ currentPlan.price }}</div>
                    <div class="text-lg text-black">Renews on: {{ currentPlan.renewsOn }}</div>
                    <div class="text-lg text-black" v-if="credits">Stories remaining: {{ credits }}</div>
                  </div>
                </div>
              </div>
              <div class="mt-8 flex justify-center space-x-10">
                <MainButton rounded outlined @click="changePlan()">
                  Change Plan
                </MainButton>
                <MainButton rounded outlined @click="managePlan()">
                  Manage Subscription
                </MainButton>
              </div>
            </div>
            <div v-else-if="loaded" class="bg-white shadow-md rounded-lg p-6 max-w-md mx-auto items-center">
              <div class="flex flex-col">
                <div class="text-base font-bold mb-4">You don't have an active plan. </div>
                <div class="text-base font-bold mb-4">Select one to start creating
                  stories!</div>
                <MainButton rounded @click="goToPlans" class="w-auto">
                  Browse Plans
                </MainButton>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Account">
            <div class="flex flex-col self-center items-center mx-5 px-5 mt-5">
              <UserProfile />
            </div>
            <div class="w-full text-center my-20">
              <MainButton rounded @click="confirmSignOut">Sign Out</MainButton>
            </div>
          </TabPanel>

          <TabPanel header="Admin" v-if="isAdmin">
            <Admin />
          </TabPanel>
        </TabView>


      </div>
    </div>
  </div>
</template>


<script setup>
import { UserProfile, useUser, useClerk } from 'vue-clerk';
import { useRouter } from 'vue-router';

const { t } = useI18n();
const confirm = useConfirm();
const clerk = useClerk();
const router = useRouter();

const isAdmin = clerk?.user?.publicMetadata?.isAdmin === true;

const confirmSignOut = (accept) => {
  confirm.require({
    message: 'Are you sure you want to sign out?',
    header: `Sign Out`,
    rejectClass: 'p-button-secondary p-button-outlined p-button-rounded',
    rejectLabel: t('tos_pp.cancel'),
    acceptLabel: 'Confirm',
    acceptClass: 'p-button-rounded p-button-danger',
    accept: async () => {
      await clerk.signOut();
      router.push({ name: 'HomePage' });
    },
  });
};
</script>

<script>
import api from "@/api/api";
import { useConfirm } from 'primevue/useconfirm';
import { useI18n } from "vue-i18n";
import ConfirmDialog from 'primevue/confirmdialog';
import Admin from './Admin.vue';

export default {
  name: 'UserProfile',
  data() {
    return {
      plans: [],
      currentPlan: null,
      loaded: false,
      credits: null,
      stripeKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    }
  },
  components: {
    ConfirmDialog,
    Admin,
  },
  setup() {
    const { t } = useI18n();
    const confirm = useConfirm();

    const confirmSignOut = (accept) => {
      confirm.require({
        message: 'Are you sure you want to sign out?',
        header: `Sign Out`,
        rejectClass: 'p-button-secondary p-button-outlined p-button-rounded',
        rejectLabel: t('tos_pp.cancel'),
        acceptLabel: 'Confirm',
        acceptClass: 'p-button-rounded',
        accept: accept,
      });
    };

    return { confirmSignOut };
  },
  async mounted() {
    const { isSignedIn } = useUser()
    if (!isSignedIn.value) {
      return this.$router.push({ name: 'SignIn' })
    } else {
      api.getProducts().then((response) => {
        this.plans = response.data.plans;
        this.currentPlan = this.plans.find(plan => plan.subscribed);
        this.credits = response.data.credits;
      }).finally(() => {
        this.loaded = true;
      })
    }
  },
  methods: {
    async managePlan() {
      const customerPortalUrl = await api.createCustomerPortal();
      console.log('customerPortalUrl', customerPortalUrl);
      const openResult = window.open(customerPortalUrl.data.portal, '_blank');
      if (!openResult) {
        window.location.href = customerPortalUrl.data.portal;
      }
    },
    async changePlan() {
      this.managePlan();
      //todo: link directly to the plan-update page
    },
    goToPlans() {
      this.$router.push({ name: 'SubscriptionPlans' });
    },
    signOut() {
      this.confirmSignOut(() => {
        this.$router.push({ name: 'HomePage' });
      });
    },
  },
}
</script>

<style scoped>
.p-tabview .p-tabview-panels {
  background-color: red;
}
</style>
