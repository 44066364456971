<template>
    <div class="hero-wrapper w-full bg-white">
        <div class="stars-left"></div>
        <div class="stars-right"></div>
        <div class="mx-auto hero-inner">
            <div class="py-8">
                <div class="px-4 text-center">
                    <h1 class="text-4xl font-bold mb-4">A New Chapter in Storytelling</h1>
                    <p class="text-xl text-gray-600 mb-8">Personalized Illustrated Children's Books In Seconds</p>
                    <div class="mx-auto w-full rounded-lg justify-around flex">
                        <VideoPlayer class="video-player vjs-big-play-centered video"
                            src="https://static.wonderstories.ai/intro_video.mp4" playsinline controls :volume="0.6"
                            :height="360" poster="https://static.wonderstories.ai/intro_thumb.webp" />
                    </div>
                    <div class="flex justify-center space-x-4 mt-10">
                        <MainButton rounded outlined size="large" @click="onSampleClick">
                            Read Sample
                        </MainButton>
                        <div class="glow-on-hover">
                            <MainButton rounded size="large" @click="onCreateStoryClick">
                                Create Story
                            </MainButton>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BookJSON from '@/assets/book.json'
import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

export default {
    name: 'LandingHero',
    components: {
        VideoPlayer
    },
    data() {
        return {
            BookJSON,
            imageLoaded: false
        }
    },
    methods: {
        onCreateStoryClick() {
            this.$emit("createStoryClick")
        }, onSampleClick() { this.$router.push({ name: 'AllStories' }) }
    },
    mounted() {
        const img = new Image(); img.src = require('../../assets/hero-image.jpeg');
        img.onload = () => {
            this.imageLoaded = true;
        };
    }
}
</script>

<style>
.video {
    background-color: white;
    width: 50%;
    aspect-ratio: 16 / 9;
}

@media (max-width: 768px) {
    .video {
        width: 100%;
    }
}

.vjs-poster {
    background-color: white;
}

.glow-on-hover {
    /* width: 220px;
    height: 50px; */
    border: none;
    outline: none;
    color: #fff;
    background: transparent;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 50px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 50px;
}

.glow-on-hover:active {
    /* color: #000 */
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    /* background: #111; */
    left: 0;
    top: 0;
    border-radius: 50px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.hero-wrapper {
    position: relative;
    z-index: 0;
}

.stars-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-image: url('../../assets/stars_left.svg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    z-index: 1;
}

.stars-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-image: url('../../assets/stars_right.svg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    z-index: 1;
}

.hero-inner {
    position: relative;
    z-index: 2;
}

.hero-image {
    width: 100%;
    padding-bottom: 62.5%;
    background-image: url('../../assets/hero-image.jpeg');
    background-size: cover;
    background-position: center;
    opacity: 0;
}

.hero-image.loaded {
    opacity: 1;
}

@media (min-width: 768px) {
    .hero-image {
        max-width: 680px;
        padding-bottom: 428px;
    }
}
</style>
