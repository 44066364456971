<template>
  <!-- <confirm-dialogue ref="confirmDialogue"></confirm-dialogue> -->
  <div>
    <story-loading v-if="isLoading" />
    <div class="container" v-if="!isLoading">
      <h1 class="header-text">{{ $t('create_new_story') }}</h1>
      <div class="margin-bottom-s"></div>

      <div class="input-container">
        <label for="hero">The reader age is...</label>
        <DropDown v-model="selectedAgeRange" :options="ageRanges" optionLabel="name" placeholder="Select reader age"
          class="margin-bottom-s" />
      </div>

      <div class="margin-bottom-s"></div>


      <div class="input-container">
        <label for="hero">{{ $t('describe_hero') }}</label>
        <InputText id="hero" v-model="heroesText" aria-describedby="hero-help" />
        <small id="hero-help">{{ $t('hero_description_example') }}</small>
      </div>

      <div class="margin-bottom-m"></div>

      <div class="input-container">
        <label for="villain">{{ $t('describe_villain') }}</label>
        <InputText id="villain" v-model="villainsText" aria-describedby="villain-help" />
        <small id="villain-help">{{ $t('villain_description_example') }}</small>
      </div>

      <div class="margin-bottom-m"></div>

      <div class="input-container">
        <label for="elements">{{ $t('describe_story_elements') }}</label>
        <InputText id="elements" v-model="keywordsText" aria-describedby="elements-help" />
        <small id="elements-help">{{ $t('story_elements_example') }}</small>
      </div>

      <div class="margin-bottom-m"></div>

      <div class="input-container">
        <label for="lessons">{{ $t('describe_lessons') }}</label>
        <InputText id="lessons" v-model="lessonsText" aria-describedby="lessons-help" />
        <small id="lessons-help">{{ $t('lessons_example') }}</small>
      </div>

      <div class="margin-bottom-l"></div>

      <MainButton :disabled="!selectedAgeRange" rounded @click="onCreateClicked()">{{
      $t('create_new_story') }}</MainButton>
    </div>
  </div>
  <ConfirmDialog />
</template>

<style scoped>
.margin-bottom-s {
  margin-bottom: 20px;
}

.margin-bottom-m {
  margin-bottom: 30px;
}

.margin-bottom-l {
  margin-bottom: 40px;
}

.container {
  display: flex;
  flex-direction: column;

  align-items: center;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
}

@media (max-width: 768px) {
  .input-container {
    width: 80%;
  }
}
</style>


<script>
import StoryLoading from "../StoryLoading.vue";
import ConfirmDialog from 'primevue/confirmdialog';

export default {
  name: "StoryCreation",
  components: {
    StoryLoading,
    ConfirmDialog
  },
  data() {
    return {
      ageRanges: [
        { name: '0 - 2 years', code: '0' },
        { name: '3 - 6 years', code: '3' },
        { name: '7 - 10 years', code: '7' },
        { name: '10+ years', code: '11' },
      ],
    };
  },
};

</script>

<script setup>
import { useConfirm } from "primevue/useconfirm";
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { createStory } from "./StoryCreationFunctions";

const router = useRouter()
const route = useRoute()
const confirm = useConfirm();

const heroesText = ref('');
const villainsText = ref('');
const keywordsText = ref('');
const lessonsText = ref('');
const selectedAgeRange = ref(null);
let isLoading = ref(false);

const onCreateClicked = () => {
  confirm.require({
    header: 'New story',
    message: 'Are you sure you want to create a new wonder story?',
    acceptLabel: 'Create Story',
    acceptClass: 'p-button-rounded',
    rejectLabel: 'Cancel',
    accept: () => _createStory()
  });
}

async function _createStory() {
  const storyType = route.params.type;
  const newStory = await createStory({
    heroes: heroesText.value,
    villains: villainsText.value,
    keywords: keywordsText.value,
    lessons: lessonsText.value,
    ageRange: selectedAgeRange.value.code,
    type: route.params.type,
  });
  if (newStory.success === true) {
    console.log('story', newStory);
    router.push({
      name: 'StoryDisplay',
      params: {
        slugPath: 'id',
        slugName: newStory.story.id,
      }
    });
  } else {
    const errorCode = newStory.error.code;
    if (errorCode === 'insufficient_credits') {
      router.push({ name: 'SubscriptionPlans' });
    } else {
      console.error('Error creating story', newStory.error);
    }
  }
}

</script>