import { createI18n } from "vue-i18n";
import en from '@/translations/en.js';
import pt from '@/translations/pt.js';

const i18n = createI18n({
  locale: window.navigator.language,
  fallbackLocale: 'en-US',
  messages: {
    en : en,
    'en-US' : en,
    pt: pt,
    'pt-BR': pt,
  },
  globalInjection: true,
  legacy:  false,
});

export default i18n;