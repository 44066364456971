<template>
	<div class="bg-purple-100 py-4 w-full">
		<div class="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
			<div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
				<div v-for="stat in stats" :key="stat.title" class="text-center">
					<div class="text-4xl font-bold text-primary">{{ stat.stat }}</div>
					<div class="mt-2 text-xl font-bold text-black">{{ stat.title }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'StatisticsComponent',
	data: () => {
		return {
			stats: [
				{
					stat: '500+',
					title: 'Happy Clients',
					description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.'
				},
				{
					stat: '1000+',
					title: 'Stories Created',
					description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.'
				},
				{
					stat: '100+',
					title: 'Languages Supported',
					description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.'
				},
				{
					stat: '24/7',
					title: 'Support',
					description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.'
				}
			] as {
				stat: string,
				title: string,
				description: string
			}[]
		}
	}
});
</script>
