<template>
    <DialogModal @update:visible="handleClose" :visible="visible" modal header="Share this wonder story!" class="title">
        <span class="message">Copy the URL below and share it with friends and family!</span>
        <div class="url-container">
            <InputText filled class="url-input" :value='urlText' readonly="readonly" />
            <MainButton rounded type="button" label="Copy Link" @click="copyUrl" />
        </div>
    </DialogModal>
</template>

<script>
import { useToast } from 'primevue/usetoast';

export default {
    name: "ShareModal",
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        urlText: {
            type: String,
            default: '',
        },
    },
    methods: {
        copyUrl() {
            this.showSuccessAlert('URL copied to clipboard!');
            navigator.clipboard.writeText(this.urlText);
        },
        handleClose() {
            this.$emit("close", false);
        },
    },
    setup() {
        const toast = useToast();

        const showSuccessAlert = (message) => {
            toast.add({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
        };

        return { showSuccessAlert };
    },
}
</script>

<style scoped>
.url-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.url-input {
    display: flex;
    width: 100%;
}

.message {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    font-weight: 500;
}

.title {
    font-size: 1.5rem;
    font-weight: 500;
}
</style>
