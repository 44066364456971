<template>
    <DialogModal @update:visible="handleClose" :visible="visible" modal header="Feedback" class="title">
        <span class="message">Do you have any thoughts you would like to share?</span>
        <div class="url-container">
            <TextArea filled autoResize rows="5" cols="40" class="url-input" v-model='reportText'></TextArea>
            <MainButton rounded type="button" label="Send Feedback" @click="sendReport" />
        </div>
    </DialogModal>
</template>

<script>
import api from '@/api/api';
import { useToast } from 'primevue/usetoast';

export default {
    name: "FeedbackModal",
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            reportText: '',
        }
    },
    methods: {
        async sendReport() {
            if (this.reportText === '') {
                return this.showErrorAlert('Please enter a feedback');
            }
            if (this.reportText.length > 1000) {
                return this.showErrorAlert('Feedback message is too long');
            }
            await api.sendFeedback(this.reportText).then(() => {
                this.reportText = '';
                this.showSuccessAlert('Feedback sent! Thank you.');
                this.$emit("close", false);
            }).catch(() => {
                this.showErrorAlert('Error sending report');
            });
        },
        handleClose() {
            this.$emit("close", false);
        },
    },
    setup() {
        const toast = useToast();


        const showSuccessAlert = (message) => {
            toast.add({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
        };

        const showErrorAlert = (message) => {
            toast.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
        };

        return { showSuccessAlert, showErrorAlert };
    },
}
</script>

<style scoped>
.url-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.url-input {
    display: flex;
    width: 100%;
}

.message {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    font-weight: 500;
}

.title {
    font-size: 1.5rem;
    font-weight: 500;
}
</style>