<template>
    <BookPortrait v-if="isPortrait" :pages="_pages" :isCurrentUserStoryOwner="story.isCurrentUserStoryOwner"
        :choiceClicked="choiceClicked" :handleLeftClick="handleLeftClick" :handleRightClick="handleRightClick"
        :currentPage="currentPage" :shareClicked="_onShareClick" :onImageChangeClick="_onImageChangeClick" />
    <div class="book-container" v-else>
        <div class="book" ref="book">
            <div class="cover-left">
                <h1>{{ story.name }}</h1>
                <h2>{{ story.description }}</h2>
            </div>
            <div class="cover-right"></div>
            <template v-if="_pages">
                <div v-for="( page, index ) in  _pages " :key="index" class="page image-container"
                    :style="{ transform: pageTransform(index) }">
                    <div class="page-front" @click="handleRightClick">
                        <StoryPage3dBookSide :side="page.front" :choiceClicked="choiceClicked" :storyId="story.id"
                            :isCurrentUserStoryOwner="story.isCurrentUserStoryOwner" :isPrivate="isPrivate"
                            :onShareClick="_onShareClick" :onImageChangeClick="_onImageChangeClick" />
                        <div class="page-number">
                            {{ (index * 2) + 1 }}
                        </div>
                    </div>
                    <div class="page-back" @click="handleLeftClick">
                        <StoryPage3dBookSide :side="page.back" :choiceClicked="choiceClicked" :storyId="story.id"
                            :isCurrentUserStoryOwner="story.isCurrentUserStoryOwner" :isPrivate="isPrivate"
                            :onShareClick="_onShareClick" :onImageChangeClick="_onImageChangeClick" />
                        <div class="page-number">
                            {{ (index * 2) + 2 }}
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import BookJSON from '../../assets/book.json'
import { StoryPageType, StoryPageChoiceType } from "@/components/StoryPageType";
import { defineComponent } from 'vue';
import StoryPage3dBookSide from './StoryPage3dBookSide.vue';
import VanillaTilt from 'vanilla-tilt'
import BookPortrait from './BookPortrait.vue';

interface Page {
    imageUrl: string;
    text: string;
    type: StoryPageType;
    choices: any[];
}

/* eslint-disable no-unused-vars */
export enum BookPageSideTypes {
    image = 'image',
    text = 'text',
    choices = 'choices',
    empty = 'empty',
    outro = 'outro',
    portrait_image = 'portrait_image',
    portrait_text = 'portrait_text',
    logo = 'logo',
    cover = 'cover',
    details = 'details',
    end = 'end',
}

type BookPageSideImage = {
    pageType: BookPageSideTypes.image;
    imageUrl: string;
}
type BookPageSideText = {
    pageType: BookPageSideTypes.text;
    text: string;
}
type BookPageSideChoices = {
    pageType: BookPageSideTypes.choices;
    choices: any[];
}
type BookPageSideCover = {
    pageType: BookPageSideTypes.cover;
    title: string;
    imageUrl: string;
}
type BookPageSideDetails = {
    pageType: BookPageSideTypes.details;
    summary: string;
    author: string | null;
}

type BookPageSideEmpty = {
    pageType: BookPageSideTypes.empty;
}

type BookPageSideOutro = {
    pageType: BookPageSideTypes.outro;
}

type BookPageSideEnd = {
    pageType: BookPageSideTypes.end;
}

type BookPageSideTextPortrait = {
    pageType: BookPageSideTypes.portrait_text;
    text: string;
}

type BookPageSideImagePortrait = {
    pageType: BookPageSideTypes.portrait_image;
    imageUrl: string;
}

type BookPageSideLogo = {
    pageType: BookPageSideTypes.logo;
}

export type BookPageSide = BookPageSideImage | BookPageSideText | BookPageSideChoices | BookPageSideEmpty |
    BookPageSideOutro | BookPageSideImagePortrait | BookPageSideTextPortrait | BookPageSideLogo |
    BookPageSideCover | BookPageSideDetails | BookPageSideEnd;

export type BookPage = {
    front: BookPageSide;
    back: BookPageSide;
}

export default defineComponent({
    name: 'StoryPage3dBook',
    components: {
        StoryPage3dBookSide,
        BookPortrait,
    },
    props: {
        pages: {
            type: Array as () => Page[],
            default: new Array(),
        },
        page: {
            type: Object,
            default: null,
        },
        nextPage: {
            type: Object,
            default: null,
        },
        pageCount: {
            type: Number,
            default: null,
        },
        currentPage: {
            type: Number,
            default: -1,
        },
        story: {
            type: Object,
            default: null,
        },
        isPrivate: {
            type: Boolean,
            default: false,
        },
        showChoices: {
            type: Boolean,
            default: true,
        }
    },
    mounted() {
        const element = this.$refs.book as HTMLDivElement;
        VanillaTilt.init(element, {
            max: 10,
            speed: 10000,
            transition: true,
            perspective: 8000,
            startX: 5,
            startY: 5,
            "reset-to-start": true,
        })
    },
    computed: {
        isPortrait() {
            return window.innerWidth < window.innerHeight || window.innerWidth < 850;
        },
        _pages(): BookPage[] {
            let output;
            if (this.isPortrait) {
                output = this.pagesPortrait();
            } else {
                output = this.pagesLandscape();
            }
            while (output.length < 2) {
                output.push({
                    front: {
                        pageType: BookPageSideTypes.empty,
                    },
                    back: {
                        pageType: BookPageSideTypes.empty,
                    },
                });
            }
            return output;
        }
    },
    data() {
        return {
            BookJSON,
            StoryPageType,
            StoryPageChoiceType,
            customChoiceInput: null,
            pageText: '',
        }
    },
    emits: ["goBack", "goForward", "choiceClicked", "onShareClick", "onImageChangeClick"],
    watch: {
        currentPage: {
            immediate: true,
            handler(newValue: number, oldValue: number) {
                console.log('currentPage', newValue, oldValue);
            }
        }
    },
    methods: {
        _onShareClick() {
            this.$emit('onShareClick');
        },
        _onImageChangeClick() {
            this.$emit('onImageChangeClick');
        },
        pagesLandscape() {
            const output: BookPage[] = [];
            const firstPageFront: BookPageSideEmpty = {
                pageType: BookPageSideTypes.empty,
            };
            const firstPageBack: BookPageSideCover = {
                pageType: BookPageSideTypes.cover,
                title: this.story.name,
                imageUrl: this.story.coverImageUrl ?? this.pages[0].imageUrl,
            };
            output.push({
                front: firstPageFront,
                back: firstPageBack,
            });

            const firstPageFront2: BookPageSideDetails = {
                pageType: BookPageSideTypes.details,
                summary: this.story.description,
                author: null,
            };
            const firstPageBack2: BookPageSideEmpty = {
                pageType: BookPageSideTypes.empty,
            };
            output.push({
                front: firstPageFront2,
                back: firstPageBack2,
            });

            for (let i = 0; i < this.pages.length; i++) {
                const type = this.pages[i].type;
                switch (type) {
                    case StoryPageType.text:
                        {
                            const frontSide: BookPageSideImage = {
                                pageType: BookPageSideTypes.image,
                                imageUrl: this.pages[i].imageUrl,
                            };
                            const backSide: BookPageSideEmpty = {
                                pageType: BookPageSideTypes.empty,
                            };
                            const backSidePrevious: BookPageSideText = {
                                pageType: BookPageSideTypes.text,
                                text: this.pages[i].text,
                            };
                            output.push({
                                front: frontSide,
                                back: backSide,
                            });
                            output[output.length - 2].back = backSidePrevious;
                        }
                        break;
                    case StoryPageType.choices:
                        if (this.showChoices) {
                            const frontSide: BookPageSideChoices = {
                                pageType: BookPageSideTypes.choices,
                                choices: this.pages[i].choices,
                            };
                            const backSide: BookPageSideEmpty = {
                                pageType: BookPageSideTypes.empty,
                            };
                            output.push({
                                front: frontSide,
                                back: backSide,
                            });

                            const backSidePrevious: BookPageSideImage = {
                                pageType: BookPageSideTypes.image,
                                imageUrl: this.pages[i].imageUrl,
                            };
                            output[output.length - 2].back = backSidePrevious;
                        }
                        break;
                    default:
                        console.log('i', i);
                        console.log('type', this.pages[i].type);
                }
            }
            if (this.story.status === 'finished') {
                const lastPageFront: BookPageSideOutro = {
                    pageType: BookPageSideTypes.outro,
                };
                const lastPageBack: BookPageSideEmpty = {
                    pageType: BookPageSideTypes.empty,
                };
                output.push({
                    front: lastPageFront,
                    back: lastPageBack,
                });

                const backSidePrevious: BookPageSideEnd = {
                    pageType: BookPageSideTypes.end,
                };

                output[output.length - 2].back = backSidePrevious;
            }
            console.log('output', output);
            return output;
        },
        pagesPortrait() {
            console.log('pagesPortrait!!');
            const output: BookPage[] = [];
            const firstPageFront: BookPageSideEmpty = {
                pageType: BookPageSideTypes.empty,
            };
            const firstPageBack: BookPageSideLogo = {
                pageType: BookPageSideTypes.logo,
            };
            output.push({
                front: firstPageFront,
                back: firstPageBack,
            });
            for (let i = 0; i < this.pages.length; i++) {
                const type = this.pages[i].type;
                switch (type) {
                    case StoryPageType.text:
                        {
                            const frontSide: BookPageSideTextPortrait = {
                                pageType: BookPageSideTypes.portrait_text,
                                text: this.pages[i].text,
                            };
                            const backSide: BookPageSideLogo = {
                                pageType: BookPageSideTypes.logo,
                            };
                            output.push({
                                front: frontSide,
                                back: backSide,
                            });
                            const frontImageSide: BookPageSideImagePortrait = {
                                pageType: BookPageSideTypes.portrait_image,
                                imageUrl: this.pages[i].imageUrl,
                            };
                            const backImageSide: BookPageSideLogo = {
                                pageType: BookPageSideTypes.logo,
                            };
                            output.push({
                                front: frontImageSide,
                                back: backImageSide,
                            });
                        }
                        break;
                    case StoryPageType.choices:
                        if (this.showChoices) {
                            const frontSide: BookPageSideChoices = {
                                pageType: BookPageSideTypes.choices,
                                choices: this.pages[i].choices,
                            };
                            const backSide: BookPageSideLogo = {
                                pageType: BookPageSideTypes.logo,
                            };
                            output.push({
                                front: frontSide,
                                back: backSide,
                            });
                        }
                        break;
                    default:
                        console.log('i', i);
                        console.log('type', this.pages[i].type);
                }
            }
            if (this.story.status === 'finished') {
                const lastPageFront: BookPageSideOutro = {
                    pageType: BookPageSideTypes.outro,
                };
                const lastPageBack: BookPageSideEmpty = {
                    pageType: BookPageSideTypes.empty,
                };
                output.push({
                    front: lastPageFront,
                    back: lastPageBack,
                });
            }
            console.log('output', output);
            return output;
        },
        pageTransform(index: number) {
            const total = this._pages.length + 1;
            const spreadAngle = 24; // Maximum spread angle for unturned pages
            //const baseAngle = -1; // Minimum spread angle for unturned pages

            const turnedSpreadAngle = this.isPortrait ? 10 : 24; // Spread angle range for turned pages
            const turnedBaseAngle = this.isPortrait ? -105 : -155; // Base angle for turned pages

            const isTurned = index < this.currentPage + 1;

            if (!isTurned) {
                // Calculate spread for unturned pages
                const angleIncrement = (index / (total)) * spreadAngle;
                return `rotateY(${-spreadAngle + angleIncrement}deg) translateZ(0px)`;
            } else {
                // Calculate spread for turned pages
                const angleIncrement = (index / (total - 1)) * turnedSpreadAngle;
                return `rotateY(${turnedBaseAngle - turnedSpreadAngle + angleIncrement}deg) translateZ(0px)`;
            }
        },
        handleLeftClick() {
            this.$emit("goBack");
        },
        handleRightClick() {
            //if current page is not the last page
            if (this.currentPage < this._pages.length - 2)
                this.$emit("goForward");
        },
        choiceClicked(choiceId: string, choiceText: string) {
            console.log('choice clicked (StoryPage3dBook.vue)', choiceId, choiceText);
            this.$emit("choiceClicked", choiceId, choiceText);
        },
    }
})
</script>

<style scoped>
.page-number {
    position: absolute;
    bottom: 20px;
    right: 10px;
    left: 10px;
    text-align: center;
    font-size: 1.4vw;
    color: #333;
    font-weight: bold;
}

.page-front {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
}

.page-back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;

    transform: rotateY(180deg);
}

.page-front {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
}

.page-back {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
}


.book-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.transition-back {
    transition: transform 0s !important;
}

.transition-back * {
    transition: transform 0s !important;
}

.book {
    width: 80%;
    max-width: 1400px;
    aspect-ratio: 2 / 1;
    perspective: 8000px;
    transform-style: preserve-3d;
    overflow: visible;


    transition: transform 1s;
    transform: rotateZ(-2deg) rotateX(15deg);

    user-select: none;
}

.cover-left {
    color: grey;
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    border-radius: 10px 0px 0px 10px;
}

.cover-right {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    border-radius: 0px 10px 10px 0px;
}

.cover-left,
.cover-right {
    background-color: grey;
    border: 1px solid black;
}

.book-portrait .cover-left {
    transform-origin: right center;
    transform: rotateY(63deg);
}

.book-portrait {}

.book-container-portrait {
    margin-left: -50%;
    width: 160%;
}

.book:hover {
    transform: rotateZ(-0deg) rotateX(5deg);
}

.book>.page {
    position: absolute;
    width: calc(50% - 10px);
    height: calc(100% - 20px);
    transform-style: preserve-3d;
    transition: transform 1s;
    border-radius: 0px 10px 10px 0px;
    top: 10px;
    left: 50%;
    transform-origin: left center;
    background-color: #f1f1f1;
    box-shadow: inset 0 0 30px 5px #d8cccc, -2px 2px 15px 0 rgba(41, 31, 22, 0.81);
}
</style>
