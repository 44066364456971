<template>
    <div class="bg-white py-16 md:w-3/4">
        <div class="mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center">
            <div class="lg:text-center">
                <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">FAQ</h2>
                <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Common
                    questions</p>
                <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">Here are some of the most common questions
                    that we get.</p>
            </div>

            <AccordionWidget :multiple="true" class="lg:w-5/6 w-full flex flex-col max-w-[800px]">
                <AccordionTab :header="item.question" v-for="item in faqs" :key="item.question">
                    <p class="m-0">
                        {{ item.answer }}
                    </p>
                </AccordionTab>
            </AccordionWidget>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'FrequentQuestions',
    data() {
        return {
            faqs: [
                {
                    "question": "How Does WonderStories Work?",
                    "answer": "WonderStories allows customers to create personalized, unique, fully-illustrated children’s stories in seconds by leveraging our proprietary technology combined with industry leading generative A.I. platforms like OpenAI, Dall-E, and more."
                },
                {
                    "question": "Does Wonder Stories use A.I.?",
                    "answer": "Yes, WonderStories proprietary technology stack incorporates various A.I. technologies and our own in-house developed products to seamlessly create illustrated story books in a matter of seconds. The platform truly blends the most cutting edge technology to enhance the innate human ability of storytelling."
                },
                {
                    "question": "Is WonderStories Available on All Devices?",
                    "answer": "Yes! Wonder Stories can be accessed from any device with an internet connection. Simply log in with your account credentials to continue creating and reading stories across any of your connected devices. We are also working on developing a mobile app, stay tuned for more updates!"
                },
                {
                    "question": "Is WonderStories Appropriate For All Ages?",
                    "answer": "Yes, Wonder Stories is designed for children of all ages. Parents can customize the complexity and themes of the stories to suit their child's age and attention span. Our technology will proofread all stories and illustrations to ensure all content is child-friendly."
                },
                {
                    "question": "How Long Does It Take to Create a Story?",
                    "answer": "Depending on the level of customization and desired story length, stories can take anywhere from 15 seconds to 3 minutes to generate."
                }
            ]

        }
    }
})
</script>
