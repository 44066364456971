<template>
    <div
        class="justify-center items-center lg:px-20 gap-[30px] h-lvh w-full md:my-auto lg:mt-10 mt-1 flex max-w-screen-xl max-md:landscape:mt-32">
        <img class="w-1/2 rounded-xl shadow hidden lg:block" :src="story?.coverUrl ?? ''" />
        <div class="bg-white p-8 rounded-xl flex-col justify-start items-center inline-flex">
            <div class="flex-col justify-start items-center gap-10 flex">
                <div class="text-center text-black text-3xl font-medium">{{ story.name }}</div>
                <div class="text-black text-xl font-medium">{{ story.description }}</div>
                <div class="gap-6 flex justify-between w-full">
                    <MainButton rounded outlined class="w-2/5" label="Share" @click="onShareClick" />
                    <MainButton rounded class="w-full" label="Read Now" @click="onReadClick" />
                </div>
            </div>
            <div class="w-full items-start gap-5 flex mt-10 justify-between flex-col md:flex-row">
                <div v-for="stat in stats" :key="stat.name" class="bg-[#F4F1FF] w-full p-3 rounded-lg">
                    <div class="text-center text-primary text-base font-bold ">
                        {{ stat.name }}</div>
                    <div class="text-center text-black text-lg font-normal">
                        {{ stat.value }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StoryDetails',
    props: {
        story: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            stats: []
        }
    },
    mounted() {
        this.createStats();
    },
    methods: {
        onShareClick() {
            this.$emit('share');
        },
        onReadClick() {
            this.$emit('read');
        },
        createStats() {
            let ageRange = '';
            switch (this.story.inputAgeRange) {
                case '0':
                    ageRange = '0 - 2 years';
                    break;
                case '3':
                    ageRange = '3 - 6 years';
                    break;
                case '7':
                    ageRange = '7 - 10 years';
                    break;
                case '11':
                    ageRange = '10+ years';
                    break;
                default:
                    ageRange = '10+ years';
            }
            this.stats = [
                {
                    name: 'Age Range',
                    value: ageRange
                },
                {
                    name: 'Chapters',
                    value: this.story.chapters.length
                },
                {
                    name: 'Created At',
                    value: new Date(this.story.createdAt).toLocaleDateString()
                }
            ]

        }
    }
}
</script>

<style scoped>
/* Your component's CSS styles go here */
</style>