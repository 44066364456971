<template>
	<div>
		<TabView class="w-4/5 rounded-lg">
			<TabPanel header="Give Credits">
				<AdminGrantCredits />
			</TabPanel>
			<TabPanel header="Import Stories">
				<AdminImportStories />
			</TabPanel>
			<TabPanel header="Export Stories">
				<AdminExportStories />
			</TabPanel>
		</TabView>

	</div>
</template>

<script lang="ts">
import AdminGrantCredits from './AdminGrantCredits.vue';
import AdminExportStories from './AdminExportStories.vue';
import AdminImportStories from './AdminImportStories.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	name: 'AdminComponent',
	components: {
		AdminGrantCredits,
		AdminExportStories,
		AdminImportStories,
	},
});

</script>
