import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42bacad4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "word-container",
  ref: "container"
}
const _hoisted_2 = {
  key: 1,
  class: "spacing"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.words, (word, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (word !== '\n\n')
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "word",
              style: _normalizeStyle({ fontSize: _ctx.fontSize + 'px' })
            }, _toDisplayString(_ctx.isNewParagraph(word) ? '' : word), 5))
          : (_openBlock(), _createElementBlock("div", _hoisted_2))
      ]))
    }), 128))
  ], 512))
}