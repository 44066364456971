<template>
    <div class="h-full flex items-center justify-center">
        <div class="self-center flex flex-col items-center justify-center gap-8">
            <div class="w-full flex flex-col items-center gap-[calc((1vw+1vh)*0.4)]">
                <div class="text-lg text-black text-center">Share this story with your
                    family and
                    friends</div>
                <div id="outro-share-button" class="cursor-pointer" @click="onShareClick">
                    <MainButton rounded outlined icon="pi pi-share-alt" label="Share" @click="onShareClick" />
                </div>
            </div>
            <div class="w-full flex flex-col items-center gap-[calc((1vw+1vh)*0.4)]">
                <div class="text-lg text-black text-center">Let's keep the wonder going</div>
                <div id="outro-new-story-button" class="cursor-pointer pt-[calc((1vw+1vh)*0.5)]"
                    @click="onNewStoryClick">
                    <MainButton rounded icon="pi pi-plus" :label="$t('create_new_story')" @click="onNewStoryClick" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "StoryOutro",
    props: {
        isPrivate: {
            type: Boolean,
            default: false
        },
        storyId: {
            type: String,
            default: ''
        }
    },
    methods: {
        onShareClick() {
            this.$emit('onShareClick');
        },
        onNewStoryClick() {
            console.log('onNewStoryClick');
            this.$router.push({ name: 'StoryTypePicker' });
        }
    }
}
</script>
