<template>
    <div class="flex flex-col items-center px-10 mt-4 main-column">
        <div class="max-w-screen-xl w-full font-medium pt-20 text-3xl">{{ $t('nav_bar.your_stories') }}
        </div>
        <div v-if="totalRecords != 0" class="max-w-screen-xl w-full font-thin pt-1 text-lg">Stories Created: {{
            totalRecords }} {{ creditsUsed ? ` | Stories Remaining: ${creditsUsed}` : '' }}
        </div>
        <div class="flex flex-col items-center justify-center" v-if="isLoading">
            <Vue3Lottie :animationData="WizardJSON" :height="200" />
            <h2>{{ $t('loading') }}</h2>
        </div>
        <div class="pl-10 pt-5" v-if="stories.length == 0 && !isLoading">
            <h3>{{ $t('no_story_yet') }}</h3>
            <MainButton rounded icon="pi pi-plus" :label="$t('create_new_story')" @click="onNewStoryClick" />
        </div>
        <div class="flex flex-row justify-center flex-wrap my-10 gap-8 max-w-screen-xl grid-container">
            <StoryCard v-for="story in stories" :key="story.id" :story="story" />
        </div>
    </div>
    <Paginator v-model:first="currentPage" :rows="pageLimit" :totalRecords="totalRecords" :page-link-size="pageLimit"
        @page="onPageChange($event)" />
</template>

<script>

import api from "@/api/api.ts";
import WizardJSON from '../../assets/wizard.json'
import { useUser } from 'vue-clerk'
import Paginator from 'primevue/paginator';
import BookCardTilt from "@/components/StoryCardTilt.vue";

export default {
    name: "PastStories",
    components: {
        StoryCard: BookCardTilt,
        Paginator
    },
    data() {
        return {
            stories: [],
            isLoading: true,
            WizardJSON,
            currentPage: 0,
            pageLimit: 12,
            totalRecords: 0,
            creditsUsed: localStorage.getItem('credits') ? `${localStorage.getItem('credits')}` : null
        };
    },
    watch: {
        currentPage: function (newPage) {
            this.$router.push({ query: { page: newPage + 1 } });
        }
    },
    mounted() {
        const { isSignedIn } = useUser()
        if (!isSignedIn.value) {
            return this.$router.push({ name: 'SignIn' })
        }
        this.currentPage = this.$route.query.page ? parseInt(this.$route.query.page) - 1 : 0;
        this.loadStories();
    },
    methods: {
        loadStories() {
            this.isLoading = true;
            this.stories = [];

            window.scrollTo({ top: 0, behavior: 'smooth' });

            api.getPastStories(this.currentPage, this.pageLimit)
                .then((response) => {
                    this.stories = response.data.stories;
                    this.totalRecords = response.data.totalRecords;
                })
                .catch((error) => {
                    console.log(error)

                }).finally(() => {
                    this.isLoading = false;
                });
        },
        toggleVisibility(story) {
            api.patchStoryVisibility(story.id, !story.public)
                .then((response) => {
                    console.log(response.data);
                    this.loadStories();
                })
                .catch((error) => {
                    alert('Error: ' + error.response.data.error.message);
                    console.log(error);
                });
        },
        onPageChange(event) {
            this.currentPage = event.page;
            this.loadStories();
            this.$router.push({ query: { page: this.currentPage + 1 } });
        },
        onNewStoryClick() {
            this.$router.push({ name: 'StoryTypePicker' });
        }
    },
};
</script>