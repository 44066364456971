import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "parallax-image-container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.src,
      alt: "3D Image",
      style: _normalizeStyle({ borderRadius: _ctx.borderRadius })
    }, null, 12, _hoisted_2),
    _createElementVNode("div", {
      class: "parallax-layer-container",
      ref: "layerContainer",
      style: _normalizeStyle({ borderRadius: _ctx.borderRadius })
    }, null, 4)
  ]))
}