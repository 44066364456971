<template>
    <div class="w-full h-auto flex items-center justify-center" v-if="stories.length > 0">
        <div class="w-full flex flex-col items-center gap-2 py-4 px-12 bg-[#F1EEFB]">
            <h1 class="text-center font-raleway leading-relaxed heading2">
                {{ $t('story_gallery.title') }}
            </h1>
            <span class="text-center leading-relaxed text-lg">
                {{ $t('story_gallery.description') }}
            </span>
            <div class="flex lg:flex-row justify-center gap-10 my-8 flex-col">
                <BookCardTilt v-for="story in stories" :key="story.id" :story="story"
                    @toggleVisibility="toggleVisibility" />
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api/api.ts";
import BookCardTilt from "@/components/StoryCardTilt.vue";
export default {
    name: 'StoryGallery',
    components: {
        BookCardTilt
    },
    data() {
        return {
            stories: [],
        };
    },
    mounted() {
        this.loadStories();
    },
    methods: {
        toggleVisibility() {
            //???
        },
        loadStories() {
            this.isLoading = true;
            this.stories = [];
            api.getAllStories(0, 3)
                .then((response) => {
                    this.stories = response.data.stories;
                })
                .catch((error) => {
                    console.log(error)
                });
        },
    }
}
</script>
