import api from "@/api/api";

export async function createStory(args: {
	heroes: string;
	villains: string;
	keywords: string;
	lessons: string;
	language: string;
	ageRange: string;
	type: string;
}): Promise<{
	success: boolean;
	error: {
		message: string;
		code: string;
	} | null;
	story: any;
}> {
	console.log("Creating static story with", args);
	try {
		if (args.type === 'static') {
			const { heroes, villains, keywords, lessons, language, ageRange } = args;
			console.log("Creating static story with", args);
			const result = await api.createStaticStory(heroes, villains, keywords, lessons, language, ageRange);
			const story = result.data.story;
			return {
				success: true,
				error: null,
				story
			};
		} else if (args.type === 'dynamic') {
			const { heroes, villains, keywords, lessons, language, ageRange } = args;
			console.log("Creating dynamic story with", args);
			const result = await api.createInteractivedStory(heroes, villains, keywords, lessons, language, ageRange);
			console.log("Created dynamic story with", result);
			const story = result.data.story;
			return {
				story,
				success: true,
				error: null
			};
		} else {
			return {
				success: false,
				error: {
					message: "Invalid story type: " + args.type,
					code: "invalid_story_type"
				},
				story: null
			}
		}
	} catch (error: any) {
		if (error.response) {
			const output = {
				success: false,
				error: {
					message: error.response.data.error.message,
					code: error.response.data.error.code
				},
				story: null
			};
			return output;
		}
		return {
			success: false,
			error: {
				message: "An error occurred",
				code: "unknown_error"
			},
			story: null
		};
	}

}