<template>
  <template v-if="fontLoaded">
    <ClerkLoaded>
      <nav-bar />
      <FeedbackModal :visible="showReport" @close="toggleReportVisibility" />
      <MainButton rounded class="fixed bottom-6 right-6 z-40 shadow-md" label="Feedback"
        @click="toggleReportVisibility" />
      <ToastAlert />
      <Debug v-if="showDebug" />

      <router-view />

    </ClerkLoaded>
  </template>
</template>

<script setup>
import { ClerkLoaded } from 'vue-clerk'
</script>

<script>
import api from "@/api/api.ts";
import NavBar from "@/components/NavBar";
import { connectWebsocket } from './api/websocket';
import Debug from './components/debug/Debug.vue';
import FeedbackModal from "@/components/FeedbackDialog.vue";
import FontFaceObserver from 'fontfaceobserver';

export default {
  name: 'App',
  async mounted() {
    api.setContext(this);
    await connectWebsocket();
    this.loadFont();
  },
  data() {
    return {
      showReport: false,
      showDebug: (process.env.VUE_APP_ENABLE_DEBUG === 'true'),
      fontLoaded: false
    };
  },
  components: {
    NavBar,
    Debug,
    FeedbackModal
  },
  methods: {
    loadFont() {
      const font = new FontFaceObserver('Sniglet');
      font.load().then(() => {
        this.fontLoaded = true;
      }).catch(error => {
        console.error('Font failed to load', error);
      });
    },
    toggleReportVisibility() {
      this.showReport = !this.showReport;
    },
  }
}
</script>

<style>
#app,
body,
html {
  width: 100%;
  height: 100%;
}

* {
  font-family: 'Average Sans';
}

.p-card,
.p-card-body {
  border-radius: 1rem !important
}

body {
  margin: 0 !important;
  padding: 0 !important;
  background-color: var(--ds-color-background);
}

.primary-button {
  background-color: var(--dl-color-primary1-blue80);
  border: 0;
  font-size: large;
  height: 38px;
  color: white;
  border-radius: 100vh;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
  cursor: pointer;
}

.secondary-button {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.4);
  height: 38px;
  color: rgba(0, 0, 0, 0.4);
  border-radius: 100vh;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
  cursor: pointer;
}

.header-text {
  font-size: 2rem;
  font-weight: 200;
  padding-top: 80px;
  font-size: 2vw;
  text-align: center;
}


.margin-bottom-xs {
  margin-bottom: 10px;
}

.margin-bottom-s {
  margin-bottom: 20px;
}

.margin-bottom-m {
  margin-bottom: 30px;
}

.margin-bottom-l {
  margin-bottom: 40px;
}


@media (max-width: 768px) {
  .header-text {
    font-size: 2rem;
  }
}
</style>