<template>
    <div class="container">
        <SignUp v-if="loginEnabled" :afterSignUpUrl="afterAuthUrl ?? this.$router.options.history.state.back"
            :afterSignInUrl="afterAuthUrl ?? this.$router.options.history.state.back" path="/signup"
            :signInUrl="signInUrl" routing="virtual" />
        <div v-else>
            <h1>Login and Signup are temporarily disabled</h1>
            <h2> We apologize for the inconvenience.</h2>
        </div>
    </div>
</template>

<script>
import { SignUp } from "vue-clerk";

export default {
    name: "SignUpPage",
    data() {
        return {
            loginEnabled: process.env.VUE_APP_LOGIN_ENABLED,
            afterAuthUrl: this.$route.query.afterAuthUrl,
            signInUrl: `/login?afterAuthUrl=${this.$route.query.afterAuthUrl}`,
        };
    },
    components: {
        SignUp,
    },
};
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
</style>
