<template>
	<div class="parallax-image-container">
		<img :src="src" alt="3D Image" :style="{ borderRadius: borderRadius }" />
		<div class="parallax-layer-container" ref="layerContainer" :style="{ borderRadius: borderRadius }"></div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'ParallaxImage',
	props: {
		src: {
			type: String,
			required: true,
		},
		parallaxMasks: {
			type: Array,
			required: true,
		},
		borderRadius: {
			type: String,
			default: '10px',
		},
		pixelsPerLayer: {
			type: Number,
			default: 5,
		},
	},
	mounted() {
		const layerContainer = this.$refs.layerContainer as HTMLElement;

		for (let i = 0; i < this.parallaxMasks.length; i++) {
			const newInner = document.createElement('div') as any;
			newInner.classList.add('parallax-layer');

			const maskImageUrl = this.parallaxMasks[i];

			newInner.style.webkitMaskImage = `url(${maskImageUrl})`;
			newInner.style.maskImage = `url(${maskImageUrl})`;
			newInner.style.transform = `translateZ(${i * this.pixelsPerLayer}px)`;
			newInner.style.borderRadius = this.borderRadius;
			newInner.style.backgroundImage = `url(${this.src})`;

			layerContainer.appendChild(newInner);
		}
	},
});
</script>

<style>
.parallax-image-container {
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	height: 100%;
	position: relative;
}

.parallax-image-container img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.parallax-layer-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	margin: 0;
}

.parallax-layer {
	pointer-events: none;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	-webkit-mask-size: cover;
	-webkit-mask-position: center;
	mask-size: cover;
	mask-position: center;
	mask-repeat: no-repeat;
	transform-style: preserve-3d !important;
}

.parallax-image-container {
	transform-style: preserve-3d !important;
}

.parallax-image-container * {
	transform-style: preserve-3d !important;
}
</style>
