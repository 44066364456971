<template>
	<div class="container mx-auto py-8">
		<h2 class="text-2xl font-bold mb-4">Export Stories</h2>
		<div class="mb-4">
			<label for="email" class="block mb-2 font-medium">Email:</label>
			<input type="email" id="email" v-model="email"
				class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
				placeholder="Enter user's email" />
		</div>
		<button @click="selectAll"
			class="mb-4 bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">
			Select All
		</button>
		<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
			<div v-for="story in stories" :key="story.id"
				class="bg-white shadow rounded-lg p-4 flex items-center cursor-pointer"
				@click="toggleStorySelection(story.id)">
				<div class="flex-shrink-0 mr-4">
					<img :src="story.coverUrl" :alt="story.name" class="w-24 h-24 rounded-lg">
				</div>
				<div class="flex-grow">
					<h3 class="text-lg font-semibold">{{ story.name }}</h3>
					<a :href="`/story/${story.slugPath}/${story.slugName}`" target="_blank"
						class="text-blue-500 hover:underline" @click.stop>View Story</a>
				</div>
				<div class="flex-shrink-0">
					<input type="checkbox" :id="story.id" :value="story.id" v-model="selectedStories"
						class="form-checkbox h-5 w-5 text-indigo-600">
				</div>
			</div>
		</div>
		<button @click="copyToClipboard"
			class="mt-4 bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">
			Copy to Clipboard
		</button>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import api from "@/api/api";

export default defineComponent({
	name: 'AdminExportStories',
	data() {
		return {
			email: '-',
			stories: [] as any[],
			selectedStories: [] as string[]
		};
	},
	mounted() {
		this.email = '';
	},
	watch: {
		email: function (newEmail) {
			const emails = api.adminGetUserStories(newEmail);
			emails.then((response) => {
				if (response.data.success === true) {
					this.stories = response.data.stories;
				} else {
					this.stories = [];
				}
			});
		}
	},
	methods: {
		toggleStorySelection(storyId: string) {
			const index = this.selectedStories.indexOf(storyId);
			if (index === -1) {
				this.selectedStories.push(storyId);
			} else {
				this.selectedStories.splice(index, 1);
			}
		},
		selectAll() {
			this.selectedStories = this.stories.map(story => story.id);
		},
		copyToClipboard() {
			const selectedStories = this.stories.filter(story => this.selectedStories.includes(story.id));
			const storyData = JSON.stringify(selectedStories, null, 2);
			navigator.clipboard.writeText(storyData);
			alert('Selected stories copied to clipboard!');
		}
	}
});
</script>
