<template>
    <div class="tos-container">
        <h1 class="header-text">{{ $t('tos_pp.terms_service') }}</h1>
        <iframe src="/TOS_20240214.html" class="tos-iframe"></iframe>
    </div>
</template>

<script>
import api from '@/api/api';
import { marked } from "marked";

export default {
    name: 'TermsService',
    data() {
        return {
            text: ''
        }
    },
    methods: {
        async getTermsService() {
            try {
                await api.getTermsService()
                    .then(response => {
                        this.text = response.data.text;
                    });
            } catch (error) {
                console.error(error);
            }
        },
        markdownToHtml(body) {
            return marked.parse(body);
        },
    },
    mounted() {
        this.getTermsService();
    }
}
</script>

<style scoped>
.tos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    height: 100%;
}

.tos-iframe {
    flex-grow: 1;
    width: 100%;
    max-width: 800px;
}
</style>
