<template>
    <div class="flex justify-center items-center h-screen mt-4">
        <SignIn v-if="loginEnabled" :afterSignUpUrl="afterAuthUrl ?? this.$router.options.history.state.back"
            :afterSignInUrl="afterAuthUrl ?? this.$router.options.history.state.back" path="/login"
            :signUpUrl="signUpUrl" routing="virtual" />
        <div v-else>
            <h1>Login and Signup are temporarily disabled</h1>
            <h2> We apologize for the inconvenience.</h2>
        </div>
    </div>
</template>

<script>
import { SignIn } from "vue-clerk";

export default {
    name: "SignInPage",
    data() {
        return {
            loginEnabled: process.env.VUE_APP_LOGIN_ENABLED,
            afterAuthUrl: this.$route.query.afterAuthUrl,
            signUpUrl: `/signup?afterAuthUrl=${this.$route.query.afterAuthUrl}`,
        };
    },
    components: {
        SignIn,
    },
};
</script>
