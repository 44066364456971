const LOCAL_STORAGE_KEY = 'WS_debug';

type DebugSettings = {
	imageModel?: 'midjourney' | 'dalle3',
	storyGeneratorVersion?: '1' | '2',
	showDebug: boolean,
};

export async function saveDebugSetting(key: keyof DebugSettings, value: DebugSettings[keyof DebugSettings]) {
	console.log('saveDebugSetting', key, value);
	const settings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
	settings[key] = value;
	localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(settings));
}

export function getDebugSetting(key: string, defaultValue: DebugSettings[keyof DebugSettings]): DebugSettings[keyof DebugSettings] {
	try {
		const settings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
		if (settings[key])
			return settings[key];
		return defaultValue;
	} catch (e) {
		return defaultValue;
	}
}