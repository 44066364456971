<template>
	<div>
		<h1>Products</h1>
		{{ products }}
		<div v-for="plan in plans" :key="plan.id" class="container">
			<small>{{ plan.id }}</small>
			<h2>{{ plan.name }}</h2>
			<p>{{ plan.price }}</p>
			<p> {{ plan.billingCycle }}</p>
			<p> {{ plan.maxGenerations }} stories per month</p>
			<p>lemonsqueezyVariantId: {{ plan.lemonsqueezyVariantId }}</p>
			<button @click="purchasePlan(plan.id)">Purchase</button>

			<button v-if="plan.subscribed" @click="cancelPlan(plan.id)">Cancel</button>
		</div>
	</div>
</template>

<script>
import api from "@/api/api";

export default {
	name: 'LemonsqueezyTestPurchases',
	data() {
		return {
			plans: [],
		}
	},

	mounted() {
		console.log('LemonsqueezyTestPurchases mounted');
		this.getPlans();
	},
	methods: {
		async purchasePlan(planId) {
			const plan = this.plans.find(plan => plan.id === planId);
			console.log('plan', plan);
			if (!plan) {
				console.error('plan not found');
				return;
			}
			const createCheckoutResult = await api.createCheckout(plan.id).then(response => response.data);
			console.log('createCheckoutResult', createCheckoutResult);
			//create a popup with the url
			window.open(createCheckoutResult.checkoutUrl, '_blank');
		},
		async getPlans() {
			const response = await api.getProducts().then(response => response.data);
			console.log('response', response);
			console.log('plans', response.plans)
			this.plans = response.plans;
		}
	},
}
</script>

<style scoped>
.container {
	background: lightblue;
	margin-bottom: 10px;
}
</style>
