import { clerkPlugin } from 'vue-clerk/plugin'
import * as amplitude from '@amplitude/analytics-browser';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import i18n from './translations/i18n'
import Vue3Lottie from 'vue3-lottie'
import { createHead } from '@unhead/vue'
import PrimeVue from 'primevue/config';
import './assets/theme.css'
import './assets/tailwind.css'
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Textarea from 'primevue/textarea';
import ToggleButton from 'primevue/togglebutton';
import Menu from 'primevue/menu';
import Dropdown from 'primevue/dropdown';
import ConfirmationService from 'primevue/confirmationservice';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Carousel from 'primevue/carousel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import SelectButton from 'primevue/selectbutton';
import Steps from 'primevue/steps';
import Galleria from 'primevue/galleria';

const app = createApp(App)
app.use(router);
app.use(i18n);
app.use(createHead());
app.use(PrimeVue);
app.use(Vue3Lottie);
app.use(clerkPlugin, {
	publishableKey: process.env.VUE_APP_CLERK_PUBLISHABLE_API_KEY,
});

app.use(ConfirmationService);
app.use(ToastService);

app.component('MainButton', Button);
app.component('ToggleButton', ToggleButton);
app.component('InputText', InputText);
app.component('TextArea', Textarea);
app.component('DialogModal', Dialog);
app.component('ToastAlert', Toast);
app.component('MenuList', Menu);
app.component('DropDown', Dropdown);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('CarouselWidget', Carousel);
app.component('AccordionWidget', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('SelectButton', SelectButton);
app.component('StepsWidget', Steps);
app.component('GalleriaWidget', Galleria);

amplitude.init(process.env.VUE_APP_AMPLITUDE_API_KEY, {
	serverUrl: 'https://a.wonderstories.ai/2/httpapi',
	defaultTracking: true,
});

app.mount('#app');