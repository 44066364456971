<template>
	<StoryImagePage v-if="side.pageType === bookPageSideTypes.image" :imageUrl="side.imageUrl"
		:isCurrentUserStoryOwner="isCurrentUserStoryOwner" @onImageChangeClick="_onImageChangeClick" />
	<div v-else-if="side.pageType === bookPageSideTypes.text" class="page-text-container">
		<AnimatedText :text="_paragraphedText(side.text)" />
	</div>
	<div v-else-if="side.pageType === bookPageSideTypes.cover" class="page-text-container">
		<div class="w-full h-full p-14 flex flex-col gap-8 items-center">
			<div class="text-3xl font-[Sniglet] text-center">{{ side.title }}</div>
			<div class="w-auto h-4/5 aspect-square">
				<div class=" h-full aspect-square">
					<img class="w-full h-full object-contain aspect-square rounded-xl" :src="side.imageUrl"
						alt="page image" draggable="false" />
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="side.pageType === bookPageSideTypes.details" class="page-text-container">
		<div class="w-full h-full p-20 flex flex-col justify-between items-stretch">
			<div class="text-2xl text-center font-[Sniglet] ">{{ side.summary }}</div>

			<div v-if="side.author" class="text-xl text-center font-[Sniglet]">{{ `Made with ❤️ by ${side.author}` }}
			</div>

			<div class="flex flex-row justify-center mb-4 gap-2 w-full">
				<img class="w-36" src="@/assets/logo_vertical.svg">
			</div>
		</div>
	</div>
	<ChoicesPage v-else-if="side.pageType === bookPageSideTypes.choices" :side="side" @submitChoice="_submitChoice"
		@showErrorAlert="showErrorAlert" />
	<div v-else-if="side.pageType === bookPageSideTypes.empty" class="page-empty-container" @click.stop="">
	</div>
	<StoryOutro v-else-if="side.pageType === bookPageSideTypes.outro" :storyId="storyId" :isPrivate="isPrivate"
		@onShareClick="_onShareClick" />
	<div v-else-if="side.pageType === bookPageSideTypes.end" class="h-full w-full flex items-center justify-center">
		<div class="">
			<div class="text-6xl font-bold text-center font-[Sniglet]">The End.</div>
		</div>
	</div>
	<div v-else-if="side.pageType == bookPageSideTypes.logo" class="logo">
		<img src="@/assets/logo_solo.svg" alt="logo" />
	</div>
	<div v-else-if="side.pageType === bookPageSideTypes.portrait_image" class="portrait-container">
		<div class="portrait-image">
			<div class="flex flex-col items-center" v-if="side.imageUrl">
				<img class="aspect-square h-full rounded-lg mb-4" :src="side.imageUrl" alt="page image"
					draggable="false" />
				<MainButton v-if="isCurrentUserStoryOwner" rounded outlined class="h-[52px]" icon="pi pi-refresh"
					label="Change Image" @click="_onPortraitImageChangeClick($event)" />
			</div>
		</div>
	</div>
	<div v-else-if="side.pageType === bookPageSideTypes.portrait_text" class="portrait-container">
		<div class="portrait-text">
			<AnimatedText :text="_paragraphedText(side.text)" />
		</div>
	</div>
	<div v-else>
		UNKNOWN {{ side }}
	</div>
</template>

<script lang="ts">
import { BookPageSideTypes, BookPageSide } from './StoryPage3dBook.vue';
import { defineComponent } from 'vue';
import AnimatedText from './AnimatedText.vue';
import StoryOutro from '../StoryOutro.vue';
import { useToast } from 'primevue/usetoast';
import ChoicesPage from './ChoicesPage.vue';
import StoryImagePage from '../StoryImagePage.vue';

export default defineComponent({
	name: 'StoryPage3dBookSide',
	components: {
		AnimatedText,
		StoryOutro,
		ChoicesPage,
		StoryImagePage
	},
	props: {
		storyId: {
			type: String,
			default: '',
		},
		isPrivate: {
			type: Boolean,
			default: false,
		},
		isCurrentUserStoryOwner: {
			type: Boolean,
			default: false,
		},
		side: {
			type: Object as () => BookPageSide,
			default: null,
		},
		handleLeftClick: {
			type: Function,
			default: () => { },
		},
		handleRightClick: {
			type: Function,
			default: () => { },
		},
		choiceClicked: {
			type: Function,
			default: () => { },
		},
		onShareClick: {
			type: Function,
			default: () => { },
		},
		onImageChangeClick: {
			type: Function,
			default: () => { },
		},
	},
	data() {
		return {
			bookPageSideTypes: BookPageSideTypes, // Expose the enum to the template
			customChoiceText: null,
		};
	},
	methods: {
		_onImageChangeClick() {
			this.onImageChangeClick();
		},
		_onPortraitImageChangeClick(event: MouseEvent) {
			this.onImageChangeClick();
			event.stopPropagation();
		},
		_handleLeftClick() {
			this.handleLeftClick();
		},
		_handleRightClick() {
			this.handleRightClick();
		},
		_submitChoice(choiceId: string, customChoiceText: string) {
			this.choiceClicked(choiceId, customChoiceText);
		},
		_paragraphedText(text: String) {
			// break into paragraphs, but don't break after Mr, Dr, Ms, Mrs, Prof
			return text.replace(/(?<!(Mr|Dr|Ms))(?<!(Mrs))(?<!(Prof))[.!?] /g, '. \n\n ')
		},
		_onShareClick() {
			this.onShareClick();
		},
	},
	setup() {
		const toast = useToast();

		const showErrorAlert = (message: string) => {
			toast.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
		};

		return { showErrorAlert };
	},
});

</script>

<style scoped>
.logo {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

.portrait-container {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: space-around;
}

@media (orientation: portrait) {
	.portrait-image {
		padding: 20px;
		height: 50%;
		aspect-ratio: 1 / 1;
		justify-content: center;
		display: flex;
		align-self: center;
		object-fit: cover;
	}
}

@media (orientation: landscape) {
	.portrait-image {
		padding: 20px;
		height: 100%;
		aspect-ratio: 1 / 1;
		justify-content: center;
		display: flex;
		align-self: center;
		object-fit: contain;
	}
}

.portrait-text {
	position: absolute;
	height: 100%;
	width: 100%;
	margin-top: 1rem;
}

.logo img {
	filter: grayscale(90%);
}

.page-choices-container {
	padding: min(20px, 10%);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	flex-direction: column;
	gap: 20px;
}

.choice {
	text-align: center;
	width: 80%;
	border-radius: 10px;
	transition: box-shadow 0.6s;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
	padding: 20px;
}

.choice:hover {
	cursor: pointer;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
}

.page-text-container {
	padding: 0;
	display: flex;
	flex-direction: column;
	margin-top: 0.5rem;
	height: 100%;
	width: 100%;
	cursor: pointer;
}

.page-outro-container {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}
</style>