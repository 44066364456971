<template>
  <div>
    <ConfirmDialog></ConfirmDialog>
    <story-loading v-if="isLoading" :didLoad="didLoad" />
    <div v-if="!isLoading">
      <h1 class="header-text container">{{ $t('create_new_story') }}</h1>
      <div>
        <div class="change-type-button">
          <MainButton outlined rounded @click="goBack">Go back</MainButton>
        </div>
        <div class="row">
          <Card class="card" @click="onAssistedClick">
            <template #content>
              <h1>{{ $t('assisted_mode') }}</h1>
              <img class="card-image" src="https://bedtime-story-images.trainly.ai/assisted.webp"><img>
              <p>{{ $t('assisted_mode_description') }}</p>
              <MainButton rounded @click="onAssistedClick">{{ $t('craft_story') }}</MainButton>
            </template>
          </Card>
          <div class="spacer"></div>
          <Card class="card beige">
            <template #content>
              <div class="lottie-container">

                <h1>{{ $t('magic_mode') }}</h1>
                <img class="card-image" src="https://bedtime-story-images.trainly.ai/auto.webp"><img>

                <p>{{ $t('magic_mode_description') }}</p>
                <DropDown v-model="selectedAgeRange" :options="ageRanges" optionLabel="name"
                  placeholder="Select reader age" class="margin-bottom" />
                <MainButton :disabled="!selectedAgeRange" rounded @click="onAutoClick">{{ $t('start_reading') }}
                </MainButton>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.lottie-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  padding: 0px 10px 0px 10px;
}

.card.beige {
  background-color: #FDF9ED;
}

.spacer {
  width: 40px;
}

.animation {
  height: 30vh;
}

.margin-bottom {
  margin-bottom: 20px;
}

.container {
  padding-left: 40px;
  padding-top: 80px;
}

.card-image {
  width: 20%;
  height: 20%;
}

.card {
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  background-color: white;
  /* padding: 20px; */
  margin: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-height: 520px; */
}

.row {
  align-items: center;
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.change-type-button {
  padding-left: 5%;
  padding-right: 5%;
}

@media (min-width: 768px) {

  .card-image {
    width: 12rem;
    max-height: 300px;
    aspect-ratio: 1;
    padding-bottom: 20px;
    object-fit: contain;
    visibility: visible;
  }

  .row {
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
</style>

<script>
import StoryLoading from "../StoryLoading.vue";
import Card from 'primevue/card';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';
import { useI18n } from "vue-i18n";
import { useUser } from 'vue-clerk';
import PathsJSON from '@/assets/paths.json';
import StaticJSON from '@/assets/static.json';
import { createStory } from "./StoryCreationFunctions";

export default {
  name: "StoryTypePicker",
  components: {
    StoryLoading,
    Card,
    ConfirmDialog
  },
  async mounted() {
    const { isSignedIn } = useUser()
    if (!isSignedIn.value) {
      return this.$router.push({ name: 'SignIn' })
    }
  },
  data() {
    return {
      isLoading: false,
      didLoad: false,
      ageRanges: [
        { name: '0 - 2 years', code: '0' },
        { name: '3 - 6 years', code: '3' },
        { name: '7 - 10 years', code: '7' },
        { name: '10+ years', code: '11' },
      ],
      selectedAgeRange: null,
      showTypePicker: false,
      PathsJSON,
      StaticJSON,
    };
  },
  computed: {
    selectedType() {
      return this.$route.params.type;
    }
  },
  setup() {
    const confirm = useConfirm();
    const { t } = useI18n();

    const confirmCreation = (accept) => {
      confirm.require({
        message: t('create_story_message'),
        header: t('create_new_story_title'),
        rejectClass: 'p-button-secondary p-button-outlined p-button-rounded',
        rejectLabel: t('tos_pp.cancel'),
        acceptLabel: t('ok_button_label'),
        acceptClass: 'p-button-rounded',
        accept: accept,
      });
    };

    return { confirmCreation };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onAssistedClick() {
      this.$router.push({ name: 'StoryCreation', params: { type: this.selectedType } });
    },
    async onAutoClick() {
      this.confirmCreation(async () => {
        this.isLoading = true;

        const newStory = await createStory({
          ageRange: this.selectedAgeRange.code,
          type: this.selectedType
        });

        if (newStory.success) {
          this.$router.push({
            name: 'StoryDisplay',
            params: {
              slugPath: 'id',
              slugName: newStory.story.id,
            }
          });
        } else {
          const errorCode = newStory.error.code;
          if (errorCode === 'insufficient_credits') {
            this.$router.push({ name: 'SubscriptionPlans' });
          } else {
            console.error('Error creating story', newStory.error);
          }
        }
      });
    },
  },
};
</script>