<template>
  <div v-if="!$route.meta.hideNavbar">
    <Menubar :model="items">
      <template #start>
        <div class="icon-container">
          <img class="small-icon" src="../assets/logo_solo.svg" @click="onHomeClick" />
          <img class="large-icon" src="../assets/logo_vertical.svg" @click="onHomeClick" />
        </div>
      </template>

      <template #end>
        <div class="end-container">
          <div v-if="creditsText" class="credits-text">{{ creditsText }}</div>
          <div class="button-container">
            <MainButton icon="pi pi-plus" :label="isSignedIn || creditsText ? 'New Story' : 'Sign In'" rounded
              @click="onAuthButtonClick">
            </MainButton>
            <MainButton icon="pi pi-user" class="ml-4" rounded outlined @click="onProfileClick">
            </MainButton>
          </div>
        </div>
      </template>
    </Menubar>
  </div>
</template>

<script setup lang="ts">
import { useUser } from 'vue-clerk'
import { useRouter } from 'vue-router';
import { watch, ref, onUpdated } from 'vue';
import api from '@/api/api';

const router = useRouter()
const { isSignedIn } = useUser()
const creditsText = ref(localStorage.getItem('credits') ? `${localStorage.getItem('credits')} stories remaining` : null);

async function onAuthButtonClick() {
  if (isSignedIn.value) {
    router.push({ name: 'StoryTypePicker' })
  } else {
    router.push({ name: 'SignIn' })
  }
}

watch(isSignedIn, (newValue) => {
  if (newValue) {
    reloadCredits();
  } else {
    resetCredits();
  }
});

onUpdated(() => {
  reloadCredits();
});

function reloadCredits() {
  api.getCredits().then((response) => {
    const credits = response.data.credits;
    if (credits) {
      localStorage.setItem('credits', credits);
      creditsText.value = `${credits} stories remaining`;
    } else {
      resetCredits();
    }
  }).catch((error) => {
    resetCredits();
    console.error(error);
  });
}

function resetCredits() {
  creditsText.value = null;
  localStorage.removeItem('credits');
}
</script>

<script lang="ts">
import Menubar from 'primevue/menubar';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavBar',
  data() {
    return {
      items: [
        {
          label: this.$t('nav_bar.your_stories'),
          //url: '/your-stories',
          icon: 'pi pi-book',
          command: () => {
            this.$router.push({ name: 'PastStories' });
          }
        },
        {
          label: this.$t('nav_bar.popular_stories'),
          //url: '/all-stories',
          icon: 'pi pi-globe',
          command: () => {
            this.$router.push({ name: 'AllStories' });
          }
        }
      ]
    }
  },
  methods: {
    onProfileClick() {
      this.$router.push({ name: 'UserProfile' });
    },
    onHomeClick() {
      this.$router.push({ name: 'HomePage' });
    }
  }
});
</script>

<style scoped>
.credits-text {
  padding-right: 20px;
  display: inline;
}

.end-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button-container {
  padding-right: 20px;
}

.small-icon {
  display: none;
  margin: 0 1rem;
  height: 40px;
  cursor: pointer;
}

.large-icon {
  display: inline;
  margin: 0 1rem;
  max-width: 200px;
  height: 40px;
  width: 100%;
  cursor: pointer;
}

@media (max-width: 768px) {
  .credits-text {
    display: none;
  }

  .small-icon {
    display: inline;
  }

  .large-icon {
    display: none;
  }
}

.p-menubar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
</style>