<template>
    <div class="w-full bg-[#432F88]">
        <div class="max-w-7xl mx-auto py-10">
            <div class="mb-8 text-xl font-extrabold text-white sm:text-4xl text-center">
                Parents love WonderStories
            </div>
            <CarouselWidget :value="testimonials" :responsiveOptions="responsiveOptions" :showIndicators="false"
                circular :autoplayInterval="3000">
                <template #item="slotProps">
                    <div class="bg-white overflow-hidden shadow rounded-lg h-full mx-4">
                        <div class="px-4 py-5 sm:p-6 flex flex-col h-full">
                            <div class="flex items-center justify-center">
                                <span class="text-yellow-400 text-2xl">⭐️ ⭐️ ⭐️ ⭐️ ⭐️</span>
                            </div>
                            <div class="mt-4 flex-grow">
                                <p class="mt-2 text-gray-500 text-base text-center">{{ slotProps.data.quote }}</p>
                            </div>
                            <div class="flex flex-row justify-center gap-4">
                                <img :src="slotProps.data.picture" alt="Ted Crawford" class="w-16 h-16 rounded-full">
                                <div class="flex flex-col items-start justify-center">
                                    <div class="text-xl">{{ slotProps.data.name }}</div>
                                    <div class="text-sm mb-2">{{ slotProps.data.role }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </CarouselWidget>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TestimonialsComponent',
    setup() {
        const testimonials = [
            {
                id: 1,
                role: "Parent of 4",
                quote: "This app has opened up a new tradition in our growing family. I've created a bunch of custom, illustrated books for each of my grandchildren's birthdays this year, and the excitement they show is just priceless. The process is quick and straightforward, and yet the stories are so personal and beautifully illustrated. It's a joy to see their little faces light up when they recognize themselves in the stories. Such a fantastic way to encourage reading and a love for books!",
                name: "Ted",
                picture: "https://bedtime-story-images.trainly.ai/daniel.webp"
            },
            {
                id: 2,
                role: "Parent of 2",
                quote: "I honestly never write reviews, but this app deserves all the praise. The way it crafts a unique, beautifully illustrated book with just a few inputs is astounding. This is my first foray into this whole new world of A.I., and I must say it is pretty incredible. My kids are thrilled to have books that are 'just about them,' and I'm thrilled with the quality.",
                name: "Tash",
                picture: "https://bedtime-story-images.trainly.ai/simone.webp"
            },
            {
                id: 3,
                role: "Parent of 1",
                quote: "This app blew me away! In mere seconds, it created a book that my son is now obsessed with. The illustrations look like they were done by a professional artist, and the story is both fun and educational. It's a win-win for us!",
                name: "Wesley",
                picture: "https://bedtime-story-images.trainly.ai/rafael.webp"
            },
            {
                id: 4,
                role: "Grandparent of 1",
                quote: "I must admit, new technology often baffles me, but this app was a delightful surprise. It's user-friendly and in no time at all, I was able to create beautiful, illustrated stories for my granddaughter. She gets so excited when she sees herself as the hero of her own story. It's been a lovely way to share something unique with her, and I hope to be a small part in fostering a love for reading and imagination.",
                name: "Terry",
                picture: "https://static.wonderstories.ai/walter.webp"
            }
        ];
        const responsiveOptions = [
            {
                breakpoint: '1400px',
                numVisible: 3,
                numScroll: 1
            },
            {
                breakpoint: '1199px',
                numVisible: 3,
                numScroll: 1
            },
            {
                breakpoint: '767px',
                numVisible: 1,
                numScroll: 1
            },
            {
                breakpoint: '575px',
                numVisible: 1,
                numScroll: 1
            }
        ];
        return {
            testimonials,
            responsiveOptions
        };
    }
};
</script>
