<template>
  <div class="bg-white h-full">
    <ConfirmDialog></ConfirmDialog>
    <story-loading v-if="isLoading" :didLoad="didLoad" />
    <div>
      <div class="w-full h-full bg-white flex flex-col items-center">

        <!-- <img class="left-side-image" src="https://static.wonderstories.ai/two_kids_reading.webp" /> -->
        <div class="flex flex-col w-4/5 h-full mt-20 items-center justify-center">
          <!-- <div class="w-full">
            <MainButton text rounded label="Go Back" @click="backClicked" v-if="activeStep > 0" size="small" />
            <div v-else class="h-[41.5px]" />
          </div> -->

          <StepsWidget class="w-full my-8 z-0" v-model:activeStep="activeStep"
            :model="[{ label: 'Age Range' }, { label: 'Type' }, { label: 'Characters' }, { label: 'Story' }]" />

          <div v-if="activeStep == 0" class="w-full h-full items-center flex flex-col">
            <h1 class="title-text">Select your story style</h1>
            <div v-for="ageRange in ageRanges" :key="ageRange.code" class="age-ranges-column">
              <div :class="{ 'age-range-container': true, 'selected': ageRange.selected }"
                @click="ageRangeClicked(ageRange.code)">
                <div class="age-range-title">{{ ageRange.name }}</div>
                <div class="age-range-subtitle">{{ ageRange.subtitle }}</div>
              </div>
            </div>
          </div>

          <div v-else-if="activeStep == 1" class="right-side-container">
            <h1 class="title-text">Select your story type</h1>
            <div>
              <div v-for="storyType in storyTypes" :key="storyType.code"
                class="flex flex-col items-center w-max-lg mx-4">
                <div :class="{ 'story-type-container': true, 'selected': storyType.selected }"
                  @click="storyTypeClicked(storyType.code)">
                  <div class="text-center text-xl text-[#333]">{{ storyType.name }}</div>
                  <div class="text-center text-base text-[#333]">{{ storyType.subtitle }}</div>
                </div>
              </div>
            </div>
            <div class="mt-10 flex gap-12">
              <MainButton rounded outlined label="Back" @click="backClicked" />
              <MainButton rounded label="Continue" @click="saveStoryType" />
            </div>
          </div>

          <div v-else-if="activeStep == 2" class="right-side-container">
            <h1 class="title-text">Tell us about the main character(s)</h1>
            <SelectButton v-model="heroInputType" :options="heroInputOptions" class="mb-8" />

            <div class="flex items-center wrap gap-3 flex-wrap mx-12 border border-gray-300 rounded-md justify-center"
              v-if="heroInputType == 'Guided'">
              <div class="text-base">Your story's main character is a </div>
              <DropDown v-model="selectedAge" :invalid="selectedAge == null" editable
                :options="[{ name: '0', code: '0' }, { name: '1', code: '1' }, { name: '2', code: '2' }, { name: '3', code: '3' }, { name: '4', code: '4' }, { name: '5', code: '5' }, { name: '6', code: '6' }, { name: '7', code: '7' }, { name: '8', code: '8' }, { name: '9', code: '9' }, { name: '10+', code: '10' }]"
                optionLabel="name" placeholder="Age" />
              <div class="text-base">years old</div>

              <DropDown v-model="selectedGender" :invalid="selectedGender == null" editable
                :options="[{ name: 'boy', code: 'boy' }, { name: 'girl', code: 'girl' }]" optionLabel="name"
                placeholder="Appearance" />

              <div class="text-base">named</div>
              <InputText id="hero-name" :invalid="heroNameText == null" v-model="heroNameText" placeholder="Name"
                aria-describedby="hero-name-help" />

              <div class="text-base">with</div>
              <DropDown v-model="selectedSkin" :invalid="selectedSkin == null" editable
                :options="[{ name: '🏻 light', code: 'light' }, { name: '🏼 medium', code: 'medium' }, { name: '🏽 tan', code: 'tan' }, { name: '🏾 brown', code: 'brown' }, { name: '🏿 dark', code: 'dark' }]"
                optionLabel="name" placeholder="Skin Tone" />
              <div class="text-base">skin tone,</div>
              <DropDown v-model="selectedHairLenght" :invalid="selectedHairLenght == null" editable
                :options="[{ name: 'short', code: 'short' }, { name: 'medium', code: 'medium' }, { name: 'long', code: 'long' },]"
                optionLabel="name" placeholder="Hair Lenght" />
              <DropDown v-model="selectedHairType" :invalid="selectedHairType == null" editable
                :options="[{ name: 'straight', code: 'straight' }, { name: 'wavy', code: 'wavy' }, { name: 'curly', code: 'curly' }, { name: 'coily', code: 'coily' }]"
                optionLabel="name" placeholder="Hair Type" />
              <DropDown v-model="selectedHairColor" :invalid="selectedHairColor == null" editable
                :options="[{ name: 'black', code: 'black' }, { name: 'brown', code: 'brown' }, { name: 'blonde', code: 'blonde' }, { name: 'red', code: 'red' }, { name: 'gray', code: 'gray' }, { name: 'white', code: 'white' }]"
                optionLabel="name" placeholder="Hair Color" />
              <div class="text-base">hair, and</div>
              <DropDown v-model="selectedEyeColor" :invalid="selectedEyeColor == null" editable
                :options="[{ name: 'brown', code: 'brown' }, { name: 'blue', code: 'blue' }, { name: 'green', code: 'green' }, { name: 'hazel', code: 'hazel' }, { name: 'grey', code: 'grey' }, { name: 'amber', code: 'amber' }]"
                optionLabel="name" placeholder="Eye Color" />
              <DropDown v-model="selectedEyeFormat" :invalid="selectedEyeFormat == null" editable
                :options="[{ name: 'round', code: 'round' }, { name: 'almond', code: 'almond' }]" optionLabel="name"
                placeholder="Eye Shape" />
              <div class="text-base">eyes</div>
              <!-- <div class="input-container">
                <div>Add more details (optional)</div>
                <TextArea id="heroAdditionalDescription" v-model="heroAdditionalDescription" rows="3" cols="30"
                  autoResize placeholder='Tell us more about their appearance, personality, clothes, etc'
                  aria-describedby="hero-additional-desc-help" />
              </div> -->
              <!-- <MainButton rounded outlined v-if="!showAdditonalChar" class="my-4" label="Add Another Character"
                icon='pi pi-plus' @click="toggleShowAdditonalChar" /> -->
              <div class="flex items-center wrap gap-3 flex-wrap mx-12 border border-gray-300 rounded-md justify-center"
                v-if="heroInputType == 'Guided' && showAdditonalChar">
                <div class="my-12 w-[80%] h-[1px] bg-[#d1d5db]" />

                <div class="text-base">Your story's second main character is a </div>
                <DropDown v-model="selectedAge" :invalid="selectedAge == null" editable
                  :options="[{ name: '0', code: '0' }, { name: '1', code: '1' }, { name: '2', code: '2' }, { name: '3', code: '3' }, { name: '4', code: '4' }, { name: '5', code: '5' }, { name: '6', code: '6' }, { name: '7', code: '7' }, { name: '8', code: '8' }, { name: '9', code: '9' }, { name: '10+', code: '10' }]"
                  optionLabel="name" placeholder="Age" />
                <div class="text-base">years old</div>

                <DropDown v-model="selectedGender" :invalid="selectedGender == null" editable
                  :options="[{ name: 'boy', code: 'boy' }, { name: 'girl', code: 'girl' }]" optionLabel="name"
                  placeholder="Appearance" />

                <div class="text-base">named</div>
                <InputText id="hero-name" :invalid="heroNameText == null" v-model="heroNameText" placeholder="Name"
                  aria-describedby="hero-name-help" />

                <div class="text-base">with</div>
                <DropDown v-model="selectedSkin" :invalid="selectedSkin == null" editable
                  :options="[{ name: '🏻 light', code: 'light' }, { name: '🏼 medium', code: 'medium' }, { name: '🏽 tan', code: 'tan' }, { name: '🏾 brown', code: 'brown' }, { name: '🏿 dark', code: 'dark' }]"
                  optionLabel="name" placeholder="Skin Tone" />
                <div class="text-base">skin tone,</div>
                <DropDown v-model="selectedHairLenght" :invalid="selectedHairLenght == null" editable
                  :options="[{ name: 'short', code: 'short' }, { name: 'medium', code: 'medium' }, { name: 'long', code: 'long' },]"
                  optionLabel="name" placeholder="Hair Lenght" />
                <DropDown v-model="selectedHairType" :invalid="selectedHairType == null" editable
                  :options="[{ name: 'straight', code: 'straight' }, { name: 'wavy', code: 'wavy' }, { name: 'curly', code: 'curly' }, { name: 'coily', code: 'coily' }]"
                  optionLabel="name" placeholder="Hair Type" />
                <DropDown v-model="selectedHairColor" :invalid="selectedHairColor == null" editable
                  :options="[{ name: 'black', code: 'black' }, { name: 'brown', code: 'brown' }, { name: 'blonde', code: 'blonde' }, { name: 'red', code: 'red' }, { name: 'gray', code: 'gray' }, { name: 'white', code: 'white' }]"
                  optionLabel="name" placeholder="Hair Color" />
                <div class="text-base">hair, and</div>
                <DropDown v-model="selectedEyeColor" :invalid="selectedEyeColor == null" editable
                  :options="[{ name: 'brown', code: 'brown' }, { name: 'blue', code: 'blue' }, { name: 'green', code: 'green' }, { name: 'hazel', code: 'hazel' }, { name: 'grey', code: 'grey' }, { name: 'amber', code: 'amber' }]"
                  optionLabel="name" placeholder="Eye Color" />
                <DropDown v-model="selectedEyeFormat" :invalid="selectedEyeFormat == null" editable
                  :options="[{ name: 'round', code: 'round' }, { name: 'almond', code: 'almond' }]" optionLabel="name"
                  placeholder="Eye Shape" />
                <div class="text-base">eyes</div>
                <div class="input-container">
                  <div>Add more details (optional)</div>
                  <TextArea id="summary" v-model="summaryText" rows="3" cols="30" autoResize
                    placeholder='Tell us more about their appearance, personality, clothes, etc'
                    aria-describedby="summary-help" />
                </div>
              </div>
            </div>
            <div class="input-container" v-else>
              <div>{{ $t('describe_hero') }}</div>
              <TextArea id="hero" v-model="heroText" :placeholder="$t('hero_description_example')"
                aria-describedby="hero-help" rows="3" cols="30" autoResize />
              <small id="hero-help">IMPORTANT: Please be as descriptive as possible. Include hair type, hair
                color,
                skin color, eye color, unique traits</small>

            </div>
            <div class="my-8 flex gap-12">
              <MainButton rounded outlined label="Back" @click="backClicked" />
              <MainButton rounded label="Continue" @click="onCustomClick" />
            </div>
          </div>

          <div v-else-if="activeStep == 3" class="right-side-container">
            <h1 class="title-text">Tell us about your story</h1>
            <!-- <SelectButton v-model="heroInputType" :options="heroInputOptions" class="mb-8" /> -->

            <!-- <div class="w-full flex flex-col items-center gap-4">
              <div class="w-full flex items-center justify-center gap-4">
                <div class="text-base">Where did the story take place?</div>
                <DropDown v-model="selectedAge" :invalid="selectedAge == null"
                  :options="[{ name: '0', code: '0' }, { name: '1', code: '1' }, { name: '2', code: '2' }, { name: '3', code: '3' }, { name: '4', code: '4' }, { name: '5', code: '5' }, { name: '6', code: '6' }, { name: '7', code: '7' }, { name: '8', code: '8' }, { name: '9', code: '9' }, { name: '10+', code: '10' }]"
                  optionLabel="name" placeholder="Select" />
              </div>
              <div class="w-full flex items-center justify-center gap-4">
                <div class="text-base">Key Challenge? (optional)</div>
                <DropDown v-model="selectedAge" :invalid="selectedAge == null"
                  :options="[{ name: '0', code: '0' }, { name: '1', code: '1' }, { name: '2', code: '2' }, { name: '3', code: '3' }, { name: '4', code: '4' }, { name: '5', code: '5' }, { name: '6', code: '6' }, { name: '7', code: '7' }, { name: '8', code: '8' }, { name: '9', code: '9' }, { name: '10+', code: '10' }]"
                  optionLabel="name" placeholder="Select" />
              </div>
              <div class="w-full flex items-center justify-center gap-4">
                <div class="text-base">Key Lesson? (optional)</div>
                <DropDown v-model="selectedAge" :invalid="selectedAge == null"
                  :options="[{ name: '0', code: '0' }, { name: '1', code: '1' }, { name: '2', code: '2' }, { name: '3', code: '3' }, { name: '4', code: '4' }, { name: '5', code: '5' }, { name: '6', code: '6' }, { name: '7', code: '7' }, { name: '8', code: '8' }, { name: '9', code: '9' }, { name: '10+', code: '10' }]"
                  optionLabel="name" placeholder="Select" />
              </div>
            </div> -->
            <div class="input-container">
              <div>{{ $t('describe_story_elements') }}</div>
              <TextArea rows="3" cols="30" autoResize id="elements" v-model="keywordsText"
                :placeholder="$t('story_elements_example')" aria-describedby="elements-help" />
              <div class="margin-bottom-m" />
              <div>{{ $t('describe_villain') }}</div>
              <TextArea rows="3" cols="30" autoResize id="villain" v-model="villainsText"
                :placeholder="$t('villain_description_example')" aria-describedby="villain-help" />
              <div class="margin-bottom-m" />
              <div>{{ $t('describe_lessons') }}</div>
              <TextArea rows="3" cols="30" autoResize id="lessons" v-model="lessonsText"
                :placeholder="$t('lessons_example')" aria-describedby="lessons-help" />
            </div>
            <div class="my-10 flex gap-12">
              <MainButton rounded outlined label="Back" @click="backClicked" />
              <MainButton rounded label="Create Story" @click="onStoryCreateClick" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';
import { useI18n } from "vue-i18n";
import { useUser } from 'vue-clerk';
import { createStory } from "./StoryCreationFunctions";
import { useToast } from 'primevue/usetoast';

export default {
  name: "StoryTypePicker",
  components: {
    ConfirmDialog
  },
  async mounted() {
    const { isSignedIn } = useUser()
    if (!isSignedIn.value) {
      return this.$router.push({ name: 'SignIn' })
    }
  },
  data() {
    return {
      ageRanges: [
        { name: 'Everyday Adventures', subtitle: 'Stories about everyday life and relatable characters', code: '7', selected: false },
        { name: 'Fairy Tales', subtitle: 'Classic tales with magical elements and moral lessons.', code: '0', selected: false },
        { name: 'Intriging Fables', subtitle: 'Fantastical stories that teach moral lessons, often featuring animals', code: '3', selected: false },
        { name: 'Legendary Voyages', subtitle: 'Exciting tales with heroes and quests', code: '11', selected: false },
      ],
      storyTypes: [
        { name: 'Create Personalized Story', subtitle: 'You shape the story by describing the heroes, villains and themes', code: 'dynamic', selected: false },
        { name: 'Surprise Me!', subtitle: 'Let us generate a unique story for you', code: 'static', selected: false },
      ],
      selectedAgeRange: null,
      selectedAge: null,
      selectedGender: null,
      selectedSkin: null,
      selectedHairLenght: null,
      selectedHairType: null,
      selectedHairColor: null,
      selectedEyeColor: null,
      selectedEyeFormat: null,
      heroInputType: 'Guided',
      heroInputOptions: ['Guided', 'Freeform'],
      heroNameText: null,
      heroAdditionalDescription: null,
      showCustomHero: false,
      showCustomStory: false,
      showType: false,
      heroText: null,
      summaryText: null,
      villainsText: null,
      lessonsText: null,
      keywordsText: null,
      showMore: false,
      showAdditonalChar: false,
    };
  },
  computed: {
    activeStep: {
      get() {
        if (this.selectedAgeRange == null) return 0;
        if (this.showType) return 1;
        if (this.showCustomHero) return 2;
        if (this.showCustomStory) return 3;
        return 3;
      },
    },
  },
  watch: {
    heroInputType: function (newVal) {
      if (newVal == 'Guided') {
        this.selectedAge = null;
        this.selectedGender = null;
        this.selectedSkin = null;
        this.selectedHairLenght = null;
        this.selectedHairType = null;
        this.selectedHairColor = null;
        this.selectedEyeColor = null;
        this.selectedEyeFormat = null;
        this.heroNameText = null;
        this.heroAdditionalDescription = null;
      } else {
        this.heroText = null;
      }
    },
  },
  setup() {
    const confirm = useConfirm();
    const { t } = useI18n();
    const toast = useToast();

    const confirmCreation = (accept) => {
      confirm.require({
        message: t('create_story_message'),
        header: t('create_new_story_title'),
        rejectClass: 'p-button-secondary p-button-outlined p-button-rounded',
        rejectLabel: t('tos_pp.cancel'),
        acceptLabel: t('ok_button_label'),
        acceptClass: 'p-button-rounded',
        accept: accept,
      });
    };

    const showErrorAlert = (message) => {
      toast.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
    };

    return { confirmCreation, showErrorAlert };
  },
  methods: {
    toggleShowAdditonalChar() {
      this.showAdditonalChar = !this.showAdditonalChar;
    },
    clearAgeRanges() {
      this.ageRanges.forEach((range) => {
        range.selected = false;
      });
      this.selectedAgeRange = null;
    },
    clearStoryTypes() {
      this.storyTypes.forEach((type) => {
        type.selected = false;
      });
    },
    ageRangeClicked(ageRangeCode) {
      this.ageRanges.forEach((range) => {
        range.selected = range.code == ageRangeCode;
      });
      this.selectedAgeRange = ageRangeCode;
      this.showType = true;
    },
    storyTypeClicked(storyTypeCode) {
      this.storyTypes.forEach((type) => {
        type.selected = type.code == storyTypeCode;
      });
    },
    saveStoryType() {
      const selectedType = this.storyTypes.find((type) => type.selected);
      if (!selectedType) {
        return this.showErrorAlert('Please select a story type')
      }
      if (selectedType.code == 'dynamic') {
        this.showType = false;
        this.showCustomHero = true;
      } else {
        this.onAutoClick();
      }
    },
    backToAgeClicked() {
      this.ageRanges.forEach((range) => {
        range.selected = false;
      });
      this.clearAgeRanges();
      this.toggleShowCustom();
    },
    backClicked() {
      switch (this.activeStep) {
        case 0:
          this.showType = false;
          break;
        case 1:
          this.showType = false;
          this.clearAgeRanges();
          this.clearStoryTypes();
          break;
        case 2:
          this.showType = true;
          this.showCustomHero = false;
          break;
        case 3:
          this.showCustomHero = true;
          this.showCustomStory = false;
          break;
      }
    },
    toggleShowCustom() {
      this.showCustom = !this.showCustom;
    },
    onCustomClick() {
      if (this.heroInputType == 'Guided') {
        if (this.selectedAge != null && this.selectedGender != null && this.selectedSkin != null && this.selectedHairLenght != null && this.selectedHairType != null && this.selectedHairColor != null && this.selectedEyeColor != null && this.selectedEyeFormat != null && this.heroNameText != null) {
          this.showCustomHero = false;
          this.showCustomStory = true;
        } else {
          this.showErrorAlert('Please fill in all the hero details');
        }
      } else {
        if (this.heroText != null) {
          this.showCustomHero = false;
          this.showCustomStory = true;
        } else {
          this.showErrorAlert('Please fill in the hero description');
        }
      }
    },
    onStoryCreateClick() {
      this.createStory('custom');
    },
    onAutoClick() {
      const selectedAgeRange = this.ageRanges.find((range) => range.selected);
      if (!selectedAgeRange) {
        return this.showErrorAlert('Please select an age range')
      }
      this.selectedAgeRange = selectedAgeRange.code;
      this.createStory('auto');
    },
    onSaveAgeRange() {
      const selectedAgeRange = this.ageRanges.find((range) => range.selected);
      if (!selectedAgeRange) {
        return this.showErrorAlert('Please select an age range')
      }
      this.selectedAgeRange = selectedAgeRange.code;
      this.toggleShowCustom();
    },
    heroDescription() {
      let completeHeroText = '';
      if (this.heroInputType == 'Guided') {
        if (this.selectedAge != null && this.selectedGender != null && this.selectedSkin != null && this.selectedHairLenght != null && this.selectedHairType != null && this.selectedHairColor != null && this.selectedEyeColor != null && this.selectedEyeFormat != null && this.heroNameText != null) {
          completeHeroText = `A ${this.selectedAge.code} year old ${this.selectedGender.code} named ${this.heroNameText} with ${this.selectedSkin.code} skin, ${this.selectedHairLenght.code} ${this.selectedHairType.code} ${this.selectedHairColor.code} hair, and ${this.selectedEyeColor.code} ${this.selectedEyeFormat.code}-shaped eyes. ${this.heroAdditionalDescription ?? ''}`;
        }
      } else {
        completeHeroText = this.heroText;
      }
      console.log('completeHeroText', completeHeroText);
      return completeHeroText;
    },
    async createStory(type) {
      const completeHeroText = this.heroDescription();

      this.confirmCreation(async () => {
        this.isLoading = true;

        console.log('creating story', {
          ageRange: this.selectedAgeRange,
          heroes: type == 'custom' ? completeHeroText : null,
          villains: this.villainsText,
          keywords: this.keywordsText,
          lessons: this.lessonsText,
          language: navigator.language,
          type: 'static'
        });
        const newStory = await createStory({
          ageRange: this.selectedAgeRange,
          heroes: type == 'custom' ? completeHeroText : null,
          villains: this.villainsText,
          keywords: this.keywordsText,
          lessons: this.lessonsText,
          language: navigator.language,
          type: 'static'
        });

        if (newStory.success) {
          this.$router.push({
            name: 'StoryDisplay',
            params: {
              slugPath: 'id',
              slugName: newStory.story.id,
            }
          });
        } else {
          const errorCode = newStory.error.code;
          if (errorCode === 'insufficient_credits') {
            this.$router.push({ name: 'SubscriptionPlans' });
          } else {
            console.error('Error creating story', newStory.error);
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.p-dropdown,
.p-dropdown-label {
  color: black;
  font-weight: bold;
}

.auto-mode-subtitle {
  font-size: 16px;
}

.auto-mode-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  margin-top: 20px
}

.button-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  margin-top: 40px;
}

.main-container {
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 80%;
  margin-top: 1.5rem;
}

.title-text {
  font-size: x-large;
  font-weight: bold;
  color: #333;
}

.side-by-side {
  display: flex;
  flex-direction: row;
}

.left-side-image {
  width: 0%;
  height: 100vh;
  object-fit: cover;
  visibility: hidden;
}

.right-side {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: flex-start;
  /* overflow: scroll; */
  /* padding-top: 80px; */
}

.right-side.centered {
  align-items: center;
  margin-top: 40px;
}

.right-side-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  /* overflow: scroll; */
}

.age-ranges-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.story-type-container {
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  border-radius: 10px;
  margin: 30px 10px 10px 10px;
  background-color: #FBFBFB;
  cursor: pointer;
  width: 100%;
  gap: 10px;
  padding: 10px;
}

.story-type-container.selected {
  background-color: #F1F1FF;
  border: 1px solid #5228F5;
}

.story-type-container:hover {
  background-color: #F1F1FF;
  border: 1px solid #5228F5;
}

.age-range-container {
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  background-color: #F7F7F7;
  cursor: pointer;
  width: 60%;
  gap: 10px;
}

.age-range-container.selected {
  background-color: #F1F1FF;
  border: 1px solid #5228F5;
}

.age-range-container:hover {
  background-color: #F1F1FF;
  border: 1px solid #5228F5;
}

.age-range-title {
  font-size: 20px;
  text-align: center;
  color: #333;
}

.age-range-subtitle {
  font-size: 16px;
  text-align: center;
}


@media (min-width: 768px) {

  .left-side-image {
    visibility: visible;
    width: 50%;
  }

  .right-side {
    width: 50%;
  }
}
</style>