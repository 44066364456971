<template>
    <meta name="robots" content="noindex">
    <nav-bar />
    <div class="not-found">
        <h1>404 - Page Not Found</h1>
        <p>The page you are looking for does not exist.</p>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
    name: 'NotFound',
    components: {
        NavBar,
    },
};
</script>

<style scoped>
.not-found {
    text-align: center;
    margin-top: 100px;
}
</style>
