import router from "@/router";
import axios from "axios"
import { getDebugSetting } from "@/components/debug/debug";

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
})

HTTP.defaults.headers.common['Content-Type'] = 'application/json'

HTTP.interceptors.request.use(
  async config => {
    const token = await ctx.$clerk.session?.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

HTTP.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      ctx.$clerk.signOut(() => router.push({ name: 'HomePage' }));
    }
    if (error.response.data.error.code === "tos_not_accepted" && router.currentRoute.value.name !== 'TOSAgreement' && router.currentRoute.value.name !== 'HomePage') {
      router.push({ name: 'TOSAgreement', query: { destinationUrl: router.currentRoute.value.path } });
    }
    return Promise.reject(error);
  }
)

let ctx: any = null

export default {
  setContext(context: any) {
    ctx = context;
  },
  getPastStories(page: number, limit: number) {
    return HTTP.get("/user/story/page", {
      params: {
        page: page,
        limit: limit,
      }
    });
  },
  getAllStories(page: number, limit: number) {
    return HTTP.get("/story/page", {
      params: {
        page: page,
        limit: limit,
      }
    });
  },
  getStory(slugPath: string, slugName: string) {
    return HTTP.get("/story/" + slugPath + "/" + slugName);
  },
  continueInteractiveStory(storyId: string, choice: string, choiceText: string, language: string) {
    return HTTP.post("/user/story/" + storyId + "/continue-interactive-story", {
      choiceId: choice,
      language: language,
      customChoiceText: choiceText,
    })
  },
  createInteractivedStory(heroes: string, villains: string, keywords: string, lessons: string, language: string, ageRange: string) {
    const storyGeneratorVersion = getDebugSetting('storyGeneratorVersion', '1');
    console.log('storyGeneratorVersion', storyGeneratorVersion);

    const data: any = {
      generatorVersion: storyGeneratorVersion
    };
    if (heroes) {
      data.heroes = heroes;
    }
    if (villains) {
      data.villains = villains;
    }
    if (keywords) {
      data.keywords = keywords;
    }
    if (lessons) {
      data.lessons = lessons;
    }
    if (language) {
      data.language = language;
    }
    if (ageRange) {
      data.ageRange = ageRange;
    }
    return HTTP.post("/user/create-interactive-story", data)
  },
  createStaticStory(heroes: string | string[], villains: string | string[], keywords: string | string[], lessons: string | string[], language: string | string[], ageRange: string | string[]) {
    const storyGeneratorVersion = getDebugSetting('storyGeneratorVersion', '1');
    console.log('storyGeneratorVersion', storyGeneratorVersion);

    const data: any = {};
    if (heroes) {
      data.heroes = heroes;
    }
    if (villains) {
      data.villains = villains;
    }
    if (keywords) {
      data.keywords = keywords;
    }
    if (lessons) {
      data.lessons = lessons;
    }
    if (language) {
      data.language = language;
    }
    if (ageRange) {
      data.ageRange = ageRange;
    }
    data.generatorVersion = storyGeneratorVersion;

    return HTTP.post("/user/create-static-story", data)
  },
  getProducts(category: string) {
    if (category) {
      return HTTP.get("/payments/products?category=" + category)
    } else {
      return HTTP.get("/payments/products")
    }
  },
  createCheckout(planId: string) {
    return HTTP.post("/payments/create_checkout", {
      planId: planId,
    })
  },
  checkoutStatus(cartId: string) {
    return HTTP.post("/payments/checkout_status/", {
      cartId: cartId,
    })
  },
  createCustomerPortal() {
    return HTTP.post("/payments/stripe_customer_portal");
  },
  patchStoryVisibility(id: string, publicVisibility: boolean) {
    return HTTP.patch("/story/" + id, {
      public: publicVisibility,
    })
  },
  getBlogPosts() {
    return HTTP.get("/blog/all");
  },
  getBlogPost(slug: string) {
    return HTTP.get("/blog/" + slug);
  },
  joinWaitlist(email: string) {
    return HTTP.post("/waitlist/join", {
      email: email
    });
  },
  getPrivacyPolicy() {
    return HTTP.get("/legal/privacy-policy");
  },
  getTermsService() {
    return HTTP.get("/legal/terms-of-service");
  },
  acceptTerms() {
    return HTTP.post("/legal/terms-of-service/accept");
  },
  sendReport(storyId: string, report: string) {
    return HTTP.post("/story/" + storyId + "/report", {
      report: report,
    });
  },
  sendFeedback(feedback: string) {
    return HTTP.post("/user/feedback", {
      feedback: feedback,
    });
  },
  getCredits() {
    return HTTP.get("/user/credits");
  },
  grantCredits(email: string, credits: number) {
    return HTTP.post("/admin/grant_credits", {
      email: email,
      credits: credits,
    });
  },
  getUserCredits(email: string) {
    return HTTP.post("/admin/user_credits", {
      email: email,
    });
  },
  adminGetUserStories(email: string) {
    return HTTP.post("/admin/user_stories", {
      email: email,
    });
  },
  adminImportStories(stories: any) {
    return HTTP.post("/admin/import_stories", {
      stories: stories,
    });
  },
  setChapterImage(storyId: string, chapterId: string, imageIndex: string) {
    return HTTP.patch("story/set-chapter-image/" + storyId, {
      chapterId: chapterId,
      imageIndex: imageIndex,
    });
  },
};
