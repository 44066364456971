<template>
  <div>
    <LandingPage />
  </div>
</template>

<script lang="ts">
import LandingPage from '@/components/landing-page/LandingPage.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MainHome',
  components: {
    LandingPage,
  }
});
</script>