export enum StoryPageType {
    text,
    image,
    choices,
    loading
}

export enum StoryPageChoiceType {
    standard,
    custom
}