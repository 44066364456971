<template>
    <div class="page-choices-container">
        <div>What to do?</div>
        <div v-for="( choice, index ) in  side.choices " :key="index" @click.stop=""
            @click="() => _choiceClicked(choice)"
            :class="{ 'choice-container': true, 'selected': choice.selected || choice.clicked }">

            <div :class="{ 'radio-button': true, 'selected': choice.selected || choice.clicked }"></div>

            <div class="choice" v-if="choice.type == 0">
                {{ choice.text }}
            </div>

            <div class="choice-input-text" v-else-if="choice.type == 1">
                <InputText filled id='customChoice' class="choice-input-text" v-model='customChoiceText'
                    placeholder="Write custom action" maxlength="40" />
            </div>

        </div>
        <div @click="_submitChoice" @click.stop="" class="clickable" v-if="!side.choices.find((e: any) => e.selected)">
            <MainButton rounded label="Submit" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { BookPageSide, BookPageSideTypes } from './StoryPage3dBook.vue';

export default defineComponent({
    name: 'ChoicesPage',
    props: {
        side: {
            type: Object as () => BookPageSide,
            default: null,
        },
    },
    data() {
        return {
            customChoiceText: null,
        }
    },
    methods: {
        _choiceClicked(choice: any) {
            if (this.side.pageType === BookPageSideTypes.choices) {
                if (!this.side.choices.find((c: any) => c.selected)) {
                    this.side.choices.forEach((c: any) => {
                        c.clicked = c.id === choice.id;
                    });
                } else {
                    this.$emit('showErrorAlert', 'This story cannot be changed.\nTo have control over the choices, please create a new story.');
                }
            }
        },
        _submitChoice(): void {
            let selectedChoice = null;
            if (this.side.pageType === BookPageSideTypes.choices) {
                selectedChoice = this.side.choices.find((c: any) => c.clicked);
            }
            if (selectedChoice) {
                console.log('selectedChoice', this.customChoiceText);
                if (selectedChoice.type === 1 && !this.customChoiceText) {
                    return this.$emit('showErrorAlert', 'Please type a custom action or selected a pre-made one.');
                }
                return this.$emit('submitChoice', selectedChoice.id, this.customChoiceText);
            } else {
                return this.$emit('showErrorAlert', 'Please selected an action');
            }
        },
    }
});
</script>

<style scoped>
.clickable {
    cursor: pointer;
}

.choice-input-text {
    width: 100%;
    color: black;
    display: block;
}

.choice-input-text:first-letter {
    text-transform: uppercase;
}

.radio-button {
    height: 22px;
    aspect-ratio: 1;
    border: 2px solid lightgray;
    border-radius: 100%;
}

.radio-button.selected {
    border: 4px solid green;
    background-color: white;
}

.page-choices-container {
    padding: min(20px, 10%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    gap: 20px;
}

.choice-container {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 80%;
    border: 1px solid lightgray;
    border-radius: 10px;
    transition: box-shadow 0.6s;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    background-color: white;
    align-items: center;
}

.choice-container.selected {
    border: 1px solid green;
    background-color: rgba(0, 255, 0, 0.1);
}

.choice-container-selected:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
}

.choice-container:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
}

.choice {
    text-align: center;
}
</style>
