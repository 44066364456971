<template>
    <div class="cursor-pointer w-[240px] story-card" ref="tilt" @click="onReadStoryClick">
        <div v-if="story.parallaxEnabled && PARALLAX_ENABLED" class="w-[240px] h-[240px] bg-cover rounded-md shadow-md">
            <ParallaxImage :src="story.coverUrl" :parallaxMasks="JSON.parse(story.parallaxMasks)" borderRadius="8px" />
        </div>
        <div v-else class="w-[240px] h-[240px] bg-cover rounded-md shadow-md"
            :style="{ backgroundImage: `url(${story.coverUrl})` }">
            <div>
                <div class="w-full" style="padding-top: 100%; position: relative; border-radius: 8px;">
                </div>
            </div>
        </div>
        <div class="w-full mt-2 text-lg font-bold">{{ story.name }}</div>
    </div>
</template>

<script>
import VanillaTilt from 'vanilla-tilt';
import logo from '@/assets/logo_solo.svg';
import ParallaxImage from './Parallax.vue';

const PARALLAX_ENABLED = process.env.VUE_APP_PARALLAX_ENABLED === 'true';

export default {
    name: 'BookCardTilt',
    components: {
        ParallaxImage,
    },
    props: {
        story: {
            type: Object,
            default: null,
        }
    },
    mounted() {
        this.$nextTick(() => {
            const ref = this.$refs.tilt;
            if (!ref) return;
            VanillaTilt.init(ref, {
                max: 15,
                speed: 1000,
                glare: false,
            });
        });
    },
    methods: {
        onReadStoryClick() {
            this.$router.push({
                name: 'StoryDisplay',
                params: {
                    slugPath: this.story.slugPath,
                    slugName: this.story.slugName
                },
            });
        },
        getMaskFolder(parallaxMasks) {
            if (parallaxMasks && parallaxMasks.length > 0) {
                const maskUrlParts = parallaxMasks[0].split('/');
                return maskUrlParts.slice(0, -1).join('/');
            }
            return '';
        },
    },
    data() {
        return {
            logo: logo,
            PARALLAX_ENABLED,
        }
    },
}
</script>


<style>
.story-card {
    transform-style: preserve-3d !important;
}

.story-card * {
    transform-style: preserve-3d !important;
}
</style>