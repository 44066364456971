<!-- eslint-disable no-useless-escape -->
<template>
    <div class="spacing"></div>
    <div v-if="blogPost !== null" class="blog-post">
        <div class="info">{{ blogPost.author.name + ' • ' + new Date(blogPost.publishedAt).toLocaleDateString() + ' • '
        +
        blogPost.readTime }}
        </div>
        <h1 class="blog-body">{{ blogPost.headline }}</h1>
        <div class="blog-body" v-html="markdownToHtml(blogPost.body)"></div>
    </div>
    <div class="spacing"></div>
    <MainFooter />
</template>

<script>
import { marked } from "marked";
import api from "@/api/api";
import { useSeoMeta, useHead } from '@unhead/vue'
import MainFooter from '@/components/Footer'

export default {
    name: 'BlogPost',
    components: {
        MainFooter
    },
    data() {
        return {
            blogPost: null,
        }
    },
    mounted() {
        const slug = this.$route.params.slug.toString();
        this.loadBlogPost(slug);
    },
    methods: {
        async loadBlogPost(slug) {
            this.blogPost = null;
            api.getBlogPost(slug)
                .then((response) => {
                    console.log(response.data);
                    const post = response.data.post;
                    post.body = response.data.post.body.replace(/\\n/g, '\n');
                    this.setSEO(post);
                    this.blogPost = post;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        markdownToHtml(body) {
            return marked.parse(body);
        },
        setSEO(post) {
            const jsonld = {
                "@context": "https://schema.org",
                "@type": "BlogPosting",
                "headline": post.headline,
                "image": post.imageUrl,
                "wordcount": post.body.split(" ").length,
                "url": `${process.env.VUE_APP_BASE_URL}/blog/${post.slug}`,
                "datePublished": new Date(post.publishedAt).toISOString(),
                "dateCreated": new Date(post.publishedAt).toISOString(),
                "description": post.description,
                "articleBody": post.body,
                "author": {
                    "@type": "Person",
                    "name": post.author.name
                }
            };
            useHead({
                script: [
                    {
                        hid: "breadcrumbs-json-ld",
                        type: "application/ld+json",
                        textContent: JSON.stringify(jsonld)
                    }
                ]
            });
            useSeoMeta({
                title: post.headline,
                description: post.description,
                contentType: 'article',
                publishedAt: post.publishedAt,
                author: post.author.name,
                articleAuthor: post.author.name,
                articlePublishedTime: post.publishedAt,
                slug: post.slug,
                headline: post.headline,
                canonical: `${process.env.VUE_APP_BASE_URL}/blog/${post.slug}`,
                ogTitle: post.headline,
                ogType: 'article',
                ogUrl: `${process.env.VUE_APP_BASE_URL}/blog/${post.slug}`,
                ogImage: post.imageUrl,
                ogSiteName: process.env.VUE_APP_NAME,
                twitterTitle: post.headline,
                twitterCard: 'summary_large_image',
                twitterDescription: post.description,
                twitterImage: post.imageUrl,
                twitterSite: '@trainlyai',
            })
        }
    }
}
</script>

<style scoped>
.info {
    margin: 20px 0 0 0;
    font-size: 16px;
    color: black;
    text-align: left;
    font-weight: bold;
}

.blog-title {
    font-size: 48px;
    font-weight: bold;
}

.blog-post {
    margin: 40px 80px;
    font-size: 20px;
}

.author {
    margin-top: 10px;
    font-size: 16px;
    font-style: italic;
}

.spacing {
    height: 80px;
}
</style>
