<template>
    <div class="bg-white mx-auto lg:px-20 px-8 py-16 w-full">

        <div class="flex flex-col items-center justify-center gap-8">
            <div class="text-center">
                <h2 class="font-raleway leading-relaxed">
                    Choose the Perfect Plan for Your Family
                </h2>
                <span class="text-gray-800 text-xl leading-relaxed">
                    Unlock a world of imagination and create magical bedtime stories for your kids
                </span>
            </div>
            <div class="w-full  justify-center gap-8 flex-col flex md:flex-row items-stretch">
                <div v-for=" plan in plans" :key="plan.id" :class="plan.featured ? 'plan-featured' : 'plan-regular'">
                    <div v-if="plan.featured"
                        class="bg-[#88e49b] text-black px-4 py-2 mb-2 rounded-full text-sm font-semibold">
                        POPULAR
                    </div>
                    <div v-else class="h-[52px]"></div>
                    <div class="flex flex-col gap-6">
                        <div>
                            <div class="font-bold text-2xl uppercase">{{ plan.name }}</div>
                            <div class="mt-2">{{ plan.description }}</div>
                        </div>
                        <div class="flex items-center text-lg font-bold">
                            <span class="text-lg font-light">$</span>
                            <span class="text-4xl">{{ plan.price }}</span>
                            <span class="text-lg font-light">/ month</span>
                        </div>
                        <div class="flex flex-col gap-4">
                            <div v-for="feature in plan.features" :key="feature" class="flex items-center gap-1">
                                <i class="pi pi-check-circle mr-1" style="color: slateblue"></i>

                                <span>{{ feature }}</span>
                            </div>
                        </div>
                    </div>
                    <MainButton rounded class="mt-8" @click="buyTier(plan)">
                        Subscribe
                    </MainButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import api from '../../api/api'
import { useUser } from 'vue-clerk';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'PricingOptions',
    data() {
        return {
            plans: [] as any[],
            stripeKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
        };
    },
    async mounted() {
        const products = (await api.getProducts('home')).data;
        console.log('products', products);
        this.plans = products.plans.map((plan: any) => {
            return {
                id: plan.id,
                name: plan.name,
                description: plan.description,
                price: plan.price,
                features: plan.features.split(','),
                featured: plan.featured
            }
        });
    },
    setup() {
        const { isSignedIn } = useUser()
        const router = useRouter()
        const buyTier = async (plan: any) => {
            if (!isSignedIn.value) {
                return router.push({ name: 'SignIn', query: { afterAuthUrl: `/plans?planId=${plan.id}` } })
            }
            const checkout = (await api.createCheckout(plan.id)).data;
            console.log('checkout', checkout);
            const openResult = window.open(checkout.checkoutUrl, '_blank');
            if (!openResult) {
                //instead redirect to the checkout page
                window.location.href = checkout.checkoutUrl;
            }
        };

        return { buyTier }
    }
})
</script>

<style scoped>
.plan-featured {
    @apply bg-purple-100 border-2 border-gray-500 rounded-lg p-8 flex flex-col justify-between items-center w-full min-h-[300px] mb-8 shadow-md;
    border: 1px solid rgba(41, 20, 199, 0.2);
}

.plan-regular {
    @apply bg-white border-2 border-gray-500 rounded-lg p-8 flex flex-col justify-between items-center w-full min-h-[300px] mb-8 shadow-md;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>