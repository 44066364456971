<template>
  <ConfirmDialog></ConfirmDialog>
  <div class="story-page">
    <div class="button-container">
      <MainButton outlined class="exit-button" icon="pi pi-times" rounded @click="onExitClick" />
      <div class="right-buttons" v-if="story != null">
        <MainButton rounded outlined class="exit-button" icon="pi pi-ellipsis-v" @click="toggleMenu"
          aria-haspopup="true" aria-controls="overlay_menu" />
        <MenuList ref="menu" id="overlay_menu" :model="menuItems" :popup="true" />
      </div>
    </div>

    <div v-if="showDetails && story">
      <StoryDetails :story="story" @share="onShareClick" @read="onReadClick" />
    </div>
    <div class="story-column" v-else-if="story && !isLoading">
      <StoryPage :story="story" :nextPage="pages[pageIndex + 1]" :page="pages[pageIndex]" :pages="pages"
        :pageCount="pages.length" :currentPage="pageIndex" @goBack="handleLeftClick" @goForward="handleRightClick"
        @choiceClicked="onChoiceClick" @onShareClick="onShareClick" @onImageChangeClick="onImageChangeClick" />
    </div>
    <div v-else-if="story?.status == 'inProgress' && isLoading">
      <StoryLoading />
    </div>
    <div v-else-if="isLoading">
      <div class="flex flex-col items-center justify-center mt-20">
        <Vue3Lottie :animationData="WizardJSON" :height="200" />
        <h2>{{ $t('loading') }}</h2>
      </div>
    </div>

    <GalleriaWidget v-model:activeIndex="galleriaActiveIndex" v-model:visible="showGalleria" :value="galleriaImages"
      :numVisible="4" :responsiveOptions="responsiveOptions" containerStyle="width: 100%; max-width: 540px"
      :fullScreen="true">
      <template #item="slotProps">
        <div class="flex flex-col items-center">
          <img :src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt" style="width: 100%" />
          <MainButton class="m-4" rounded label="Select Image" @click="_imageSelected" />
        </div>
      </template>
      <template #thumbnail="slotProps">
        <img :src="slotProps.item.thumbnailImageSrc" :alt="slotProps.item.alt"
          :class="['h-[80px] object-fill aspect-square mx-2', { 'border-2 border-solid border-white': slotProps.item.index == galleriaActiveIndex }]" />
      </template>
    </GalleriaWidget>

  </div>

  <ReportModal v-if="story" :visible="showReport" :storyId="story.id" @close="toggleReportVisibility" />
  <ShareModal :visible="showShare" @close="toggleShare" :urlText="url" />
</template>

<script lang="ts">
import api from "@/api/api";
import StoryPage3dBook from "@/components/book3d/StoryPage3dBook.vue";
import { StoryPageType, StoryPageChoiceType } from "@/components/StoryPageType";
import { useToast } from 'primevue/usetoast';
import { useSeoMeta, useHead } from '@unhead/vue'
import ReportModal from "@/components/ReportDialog.vue";
import { useConfirm } from 'primevue/useconfirm';
import { useI18n } from "vue-i18n";
import ConfirmDialog from 'primevue/confirmdialog';
import ShareModal from "@/components/ShareModal.vue";
import { ref } from "vue";
import { addStoryUpdateListener } from "@/api/websocket";
import axios from "axios";
import { defineComponent } from "vue";
import StoryLoading from "./StoryLoading.vue";
import WizardJSON from '@/assets/wizard.json';
import StoryDetails from './StoryDetails.vue';

export default defineComponent({
  name: "StoryDisplay",
  components: {
    StoryPage: StoryPage3dBook,
    ReportModal,
    ConfirmDialog,
    ShareModal,
    StoryLoading,
    StoryDetails,
  },
  setup() {
    const toast = useToast();
    const { t } = useI18n();
    const confirm = useConfirm();
    const menu = ref();

    const toggleMenu = (event: any) => {
      menu.value.toggle(event);
    };

    const showErrorAlert = (message: any) => {
      toast.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
    };

    const showSuccessAlert = (message: any) => {
      toast.add({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
    };

    const confirmStoryVisibilityChange = (isPrivate: any, accept: any) => {
      confirm.require({
        message: isPrivate ? 'Your story will be accessible to anyone and might appear on the Popular Stories page.\n\nYou can make it private again anytime' : 'Your story will be private and only accessible to you\n\nYou can make it public again anytime',
        header: `Make story ${isPrivate ? 'public' : 'private'}?`,
        rejectClass: 'p-button-secondary p-button-outlined p-button-rounded',
        rejectLabel: t('tos_pp.cancel'),
        acceptLabel: 'Confirm',
        acceptClass: 'p-button-rounded',
        accept: accept,
      });
    };

    return { showErrorAlert, showSuccessAlert, confirmStoryVisibilityChange, toggleMenu, menu };
  },
  data() {
    return {
      galleriaImages: [] as string[],
      responsiveOptions: [
        {
          breakpoint: '1300px',
          numVisible: 4,
          containerStyle: 'max-width: 100%'

        },
        {
          breakpoint: '575px',
          numVisible: 4
        }
      ],
      slugPath: this.$route.params.slugPath,
      slugName: this.$route.params.slugName,
      pageIndex: 0,
      pages: [] as any[],
      story: null as any,
      isStreamingText: false,
      showReport: false,
      private: null,
      showShare: false,
      showMenu: false,
      showDetails: false,
      showGalleria: false,
      galleriaActiveIndex: 0,
      isLoading: false,
      url: window.location.href,
      stats: [
        { name: 'Reads', value: 234 },
        { name: 'Likes', value: 234 },
        { name: 'Age Range', value: '10+' },
      ],
      cancelToken: axios.CancelToken.source(),
      listener: null as ({ unregister: () => void } | null),
      previousOrientation: null as any,
      menuItems: [
        {
          label: 'Options',
          items: [
            {
              label: 'Share',
              icon: 'pi pi-share-alt',
              command: this.onShareClick
            },
            {
              label: 'Report',
              icon: 'pi pi-flag',
              command: this.toggleReportVisibility
            },
          ]
        }
      ],
      WizardJSON,
    }
  },
  beforeMount() {
    window.addEventListener('keydown', this.handleKeydown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
    this.cancelToken.cancel();
  },
  mounted() {
    this.loadStory();
    this.previousOrientation = screen.orientation.type;
    window.addEventListener('resize', this.handleOrientationChange);
  },
  watch: {
    story(newVal) {
      const isPrivate = newVal.public == false;
      if (newVal.isCurrentUserStoryOwner) {
        this.menuItems = [
          {
            label: 'Options',
            items: [
              {
                label: 'Share',
                icon: 'pi pi-share-alt',
                command: this.onShareClick
              },
              {
                label: isPrivate ? 'Make public' : 'Make private',
                icon: isPrivate ? 'pi pi-globe' : 'pi pi-lock',
                command: this.togglePublic
              },
              {
                label: 'Report',
                icon: 'pi pi-flag',
                command: this.toggleReportVisibility
              },
            ]
          }
        ];
      }
    }
  },
  unmounted() {
    if (this.listener) {
      console.log('unregistering listener');
      this.listener.unregister();
    }
    window.removeEventListener('resize', this.handleOrientationChange);
  },
  methods: {
    onImageChangeClick() {
      const currentPage = this.pages[this.pageIndex];
      const imageUrls = currentPage.imageUrls;
      this.galleriaImages = imageUrls.map((e: string, index: number) => ({ itemImageSrc: e, thumbnailImageSrc: e, index }));
      this.galleriaActiveIndex = currentPage.currentImageUrlIndex;

      this.showGalleria = true;
    },
    async _imageSelected() {
      const index = this.pageIndex;
      const currentPage = this.pages[index];
      this.pages.forEach((page: any) => {
        if (page.id === currentPage.id) {
          page.imageUrl = currentPage.imageUrls[this.galleriaActiveIndex];
          page.currentImageUrlIndex = this.galleriaActiveIndex;
        }
      });

      if (index === 1) {
        this.story.coverUrl = currentPage.imageUrl;
        this.pages[0].imageUrl = currentPage.imageUrl;
      }

      this.showGalleria = false;

      await api.setChapterImage(this.story.id, currentPage.id, currentPage.currentImageUrlIndex)
        .catch((error) => {
          this.showErrorAlert(error.response.data.error.message);
          console.error(error);
        });
    },
    handleOrientationChange() {
      const orientation = screen.orientation.type;
      if ((orientation === 'portrait-primary' || orientation === 'landscape-primary' || orientation === 'landscape-secondary') && orientation !== this.previousOrientation) {
        window.location.reload();
        this.previousOrientation = orientation;
      }
    },
    onReadClick() {
      this.showDetails = false;
    },
    onShareClick() {
      if (!this.story.public) {
        this.togglePublic();
      } else {
        this.toggleShare();
      }
    },
    toggleShare() {
      this.showShare = !this.showShare;
    },
    togglePublic() {
      const acceptChange = async () => {
        await api.patchStoryVisibility(this.story.id, !this.story.public).then(() => {
          this.showSuccessAlert(`Story is now ${!this.story.public ? 'public' : 'private'}`);
          const isPrivate = this.story.public == true;
          this.story.public = !isPrivate;
          this.menuItems = [{
            label: 'Options',
            items: [
              {
                label: 'Share',
                icon: 'pi pi-share-alt',
                command: this.onShareClick
              },
              {
                label: isPrivate ? 'Make public' : 'Make private',
                icon: isPrivate ? 'pi pi-globe' : 'pi pi-lock',
                command: this.togglePublic
              },
              {
                label: 'Report',
                icon: 'pi pi-flag',
                command: this.toggleReportVisibility
              },
            ]
          }];
        }).catch((error) => {
          this.showErrorAlert(error.response.data.error.message);
          console.error(error);
        });
      };
      this.confirmStoryVisibilityChange(!this.story.public, acceptChange);
    },
    toggleReportVisibility() {
      this.showReport = !this.showReport;
    },
    onExitClick() {
      if (this.story.public && !this.story.isCurrentUserStoryOwner) {
        return this.$router.push({ name: 'AllStories' });
      }
      this.$router.push({ name: 'PastStories' });
    },
    handleLeftClick() {
      if (this.pageIndex == 0) {
        return;
      }
      this.pageIndex--;
    },
    handleRightClick() {
      const currentPage = this.pages[this.pageIndex];
      if (currentPage == null) {
        console.log('story NULL');
        return;
      }
      const lastPage = this.pages[this.pages.length - 1];
      if (currentPage.type == StoryPageType.loading) {
        return;
      }
      if (currentPage.type === StoryPageType.choices && lastPage.type !== StoryPageType.loading) {
        if (!currentPage.choices)
          return;
        const choiceSelected = currentPage.choices.some((choice: any) => choice.selected);
        if (!choiceSelected) {
          return;
        }
      } else if (this.story.status == "finished" && this.pageIndex === this.pages.length) {
        console.log('story finished');
        return;
      }

      this.pageIndex = this.pageIndex + 1;
      console.log('pageIndex', this.pageIndex);

      return;
    },
    async onChoiceClick(choiceId: string, choiceText: string) {
      const currentPage = this.pages[this.pageIndex];

      // If already selected, do nothing
      if (!currentPage.choices)
        return;
      const choicesSelected = currentPage.choices.some((choice: any) => choice.selected);
      if (choicesSelected) {
        return;
      }
      const choiceToBeSelected = currentPage.choices.find((choice: any) => choice.id === choiceId);
      if (choiceToBeSelected.type == 1 && !choiceText) {
        return this.showErrorAlert('Please type a custom action or selected a pre-made one.');
      }

      // Add loading page
      this.pages.push({ text: currentPage.text, imageUrl: currentPage.imageUrl, type: StoryPageType.loading });
      this.pageIndex++;
      this.isStreamingText = true;

      const response = await api.continueInteractiveStory(this.story.id, choiceId, choiceText, navigator.language);
      console.log('response', response);
    },
    async refreshStory() {
      const response = await api.getStory(this.slugPath as string, this.slugName as string);
      const story = response.data.story;
      this.pages = this.convertStoryToPages(story);
      this.story = story;
      this.isLoading = false;
    },
    async loadStory() {
      console.log('slugPath', this.slugPath, 'slugName', this.slugName);
      this.isLoading = true;

      try {
        const response = await api.getStory(this.slugPath as string, this.slugName as string);
        console.log('response', response);
        this.pageIndex = 0;

        const story = response.data.story;
        console.log('story!', story);
        if (story.status == 'finished') {
          this.setSEO(story);
          this.pages = this.convertStoryToPages(story);
          this.story = story;
          this.isLoading = false;
          return;
        }

        if (this.listener) {
          this.listener.unregister();
        }
        this.listener = addStoryUpdateListener(story.id, (story: any) => {
          this.pages = this.convertStoryToPages(story);
          if (story.slugName && story.slugName !== '' && story.slugPath && story.slugPath !== '' && story.slugName !== this.slugName && story.slugPath !== this.slugPath) {
            //replace the slugName and slugPath in the url without reloading the page
            this.$router.replace({ name: 'StoryDisplay', params: { slugPath: story.slugPath, slugName: story.slugName } });
            this.setSEO(story);
          }
          if (story.status == 'finished') {
            this.refreshStory();
          }
          this.story = story;
          console.log('story update from websocket', story);
        });

        if (story.public && !story.isCurrentUserStoryOwner && story.status == 'inProgress') {
          this.$router.push({ name: 'PastStories' });
          return;
        }
        this.setSEO(story);
        this.pages = this.convertStoryToPages(story);
        this.story = story;
      } catch (error: any) {
        console.error(error);
        this.$router.push({ name: 'PastStories' });
        this.showErrorAlert('Something went wrong. Please try again later.');
      }
    },
    isPortrait() {
      return window.innerWidth < window.innerHeight || window.innerWidth < 850;
    },
    convertStoryToPages(story: any) {
      let pages: any[] = [];


      pages.push({
        text: story.name,
        imageUrl: story.coverUrl,
        type: StoryPageType.image,
        id: Math.random().toString(36).substring(7)
      });


      story.chapters.forEach((chapter: any, index: any) => {

        if (this.isPortrait()) {
          pages.push({
            text: chapter.text,
            imageUrl: chapter.imageUrls[chapter.currentImageUrlIndex],
            imageUrls: chapter.imageUrls,
            currentImageUrlIndex: chapter.currentImageUrlIndex,
            type: StoryPageType.image,
            id: chapter.id
          });
        }

        pages.push({
          text: chapter.text,
          imageUrl: chapter.imageUrls[chapter.currentImageUrlIndex],
          imageUrls: chapter.imageUrls,
          currentImageUrlIndex: chapter.currentImageUrlIndex,
          type: StoryPageType.text,
          id: chapter.id
        });

        const hideChoices = story.status == "finished" && index === story.chapters.length - 1 || chapter.choices == null || chapter.choices.length === 0;
        if (!hideChoices) {
          const choices = chapter.choices.map((choice: any) => {
            if (!chapter.choices) {
              return null;
            }
            if (choice.text == "custom_choice" && chapter.choices.some((c: any) => c.selected)) {
              return null;
            }
            return {
              id: choice.id,
              text: choice.text,
              selected: choice.selected,
              type: choice.text == "custom_choice" ? StoryPageChoiceType.custom : StoryPageChoiceType.standard,
              showOr: chapter.choices[chapter.choices.length - 1].text !== choice.text
            }
          }).filter((choice: any) => choice !== null);
          pages.push({
            text: chapter.text,
            imageUrl: chapter.imageUrls[chapter.currentImageUrlIndex],
            type: StoryPageType.choices,
            choices: choices,
            id: Math.random().toString(36).substring(7)
          });
        }

        // pre load images
        chapter.imageUrls.forEach((imageUrl: string) => {
          const img = new Image();
          img.src = imageUrl;
        });
      });

      return pages;
    },
    handleKeydown(e: any) {
      switch (e.keyCode) {
        case 37:
          this.handleLeftClick();
          break;
        case 39:
          this.handleRightClick();
          break;
      }
    },
    setSEO(story: any) {
      const jsonld = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "headline": story.name,
        "title": story.name,
        "image": story.chapters[0] ? story.chapters[0].imageUrl : null,
        "url": `${process.env.VUE_APP_BASE_URL}/${story.slugPath}/${story.slugName}`,
        "datePublished": new Date(story.createdAt ?? 0).toISOString(),
        "dateCreated": new Date(story.createdAt ?? 0).toISOString(),
        "description": story.description,
        "articleBody": story.chapters.map((chapter: any) => chapter.text).join(". "),
      };
      useHead({
        title: `${process.env.VUE_APP_NAME} ${story.name}`,
        script: [
          {
            hid: "breadcrumbs-json-ld",
            type: "application/ld+json",
            textContent: JSON.stringify(jsonld)
          }
        ]
      });
      useSeoMeta({
        title: story.name,
        description: story.description,
        contentType: 'article',
        publishedAt: story.createdAt,
        articlePublishedTime: story.createdAt,
        slug: story.slugName,
        headline: story.name,
        canonical: `${process.env.VUE_APP_BASE_URL}/${story.slugPath}/${story.slugName}`,
        ogTitle: story.name,
        ogType: 'article',
        ogUrl: `${process.env.VUE_APP_BASE_URL}/${story.slugPath}/${story.slugName}`,
        ogImage: story.chapters[0] ? story.chapters[0].imageUrl : null,
        ogSiteName: process.env.VUE_APP_NAME,
        twitterTitle: story.name,
        twitterCard: 'summary_large_image',
        twitterDescription: story.description,
        twitterImage: story.chapters[0] ? story.chapters[0].imageUrl : null,
        twitterSite: '@wonderstories.ai',
      } as any)
    }

  },
});
</script>


<style scoped>
.right-buttons {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
}

@media(orientation: landscape) {
  .button-container {
    padding: 20px;
  }
}

.toggle-visibility-button {
  height: 40px;
}

.story-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.exit-button {
  margin: 5px 5px 0px 5px;
  background: #fef6fa;
  cursor: pointer;
}

.story-column {
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  height: 100%;
}

.button-container {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1000;
}

.p-galleria-close {
  z-index: 100 !important;
}
</style>
