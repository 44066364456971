import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminGrantCredits = _resolveComponent("AdminGrantCredits")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_AdminImportStories = _resolveComponent("AdminImportStories")!
  const _component_AdminExportStories = _resolveComponent("AdminExportStories")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TabView, { class: "w-4/5 rounded-lg" }, {
      default: _withCtx(() => [
        _createVNode(_component_TabPanel, { header: "Give Credits" }, {
          default: _withCtx(() => [
            _createVNode(_component_AdminGrantCredits)
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, { header: "Import Stories" }, {
          default: _withCtx(() => [
            _createVNode(_component_AdminImportStories)
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, { header: "Export Stories" }, {
          default: _withCtx(() => [
            _createVNode(_component_AdminExportStories)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}