const VUE_APP_WS_BASE_URL = process.env.VUE_APP_WS_BASE_URL;

let ws: WebSocket;

const storyUpdateListeners: Record<string, Function[]> = {};

const MESSAGE_TYPE_CLIENT = {
	'SUBSCRIBE_TO_STORY': 'SUBSCRIBE_TO_STORY',
};

const MESSAGE_TYPE_SERVER = {
	'STORY_UPDATE': 'STORY_UPDATE',
};

export async function connectWebsocket() {
	console.log('Connecting to websocket');
	console.log('VUE_APP_WS_BASE_URL', VUE_APP_WS_BASE_URL);
	ws = new WebSocket(VUE_APP_WS_BASE_URL);
	ws.onopen = () => {
		console.log('Connected to websocket');
		// subscribe to all stories that we are listening to
		Object.keys(storyUpdateListeners).forEach(storyId => {
			console.log('Subscribing to story', storyId);
			ws.send(JSON.stringify({
				type: MESSAGE_TYPE_CLIENT.SUBSCRIBE_TO_STORY,
				storyId,
			}));
		});
	};
	ws.onmessage = (event) => {
		//console.log('Message from server ', event.data);
		const parsed = JSON.parse(event.data);
		try {
			switch (parsed.type) {
				case MESSAGE_TYPE_SERVER.STORY_UPDATE:
					try {
						//console.log('Story update', parsed);
						const listeners = storyUpdateListeners[parsed.story.id];
						//console.log('num listeners', listeners.length);
						//console.log('all ids listening', Object.keys(storyUpdateListeners), 'current id', parsed.story.id);
						if (listeners) {
							listeners.forEach(listener => listener(parsed.story));
						}
					} catch (e) {
						console.error('Error handling story update', e);
					}
					break;
				default:
					console.log('Unknown message type', parsed.type);
			}
		} catch (e) {
			console.error('Error parsing message', e);
		}
	};
	ws.onclose = () => {
		console.log('Disconnected from websocket');
		setTimeout(() => {
			console.log('Reconnecting to websocket');
			connectWebsocket();
		}, 200);
	};
}

export function removeStoryUpdateListener(storyId: string, cb: Function) {
	const listeners = storyUpdateListeners[storyId];
	if (listeners) {
		storyUpdateListeners[storyId] = listeners.filter(listener => listener !== cb);
	}
}

export function addStoryUpdateListener(storyId: string, cb: Function): { unregister: () => void } {
	console.log('addStoryUpdateListener', storyId);
	if (!storyUpdateListeners[storyId]) {
		storyUpdateListeners[storyId] = [];

		if (!ws || ws.readyState !== ws.OPEN) {
			console.log('Websocket not open, not sending subscribe message');
		} else {
			ws.send(JSON.stringify({
				type: MESSAGE_TYPE_CLIENT.SUBSCRIBE_TO_STORY,
				storyId,
			}));
		}
	}
	storyUpdateListeners[storyId].push(cb);

	return {
		unregister: () => {
			removeStoryUpdateListener(storyId, cb);
		}
	};
}

setInterval(() => {
	if (ws && ws.readyState === ws.OPEN) {
		ws.send(JSON.stringify({
			type: 'PING',
		}));
	}
}, 5000);